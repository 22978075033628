export const IMAGE_URLS = {
  start: './images/start/start.webp',
  manMin: './images/technology/man-min.png',
  ai: './images/technology/ai.webp',
  step2: './images/technology/step2.webp',
  step3: './images/technology/step3.webp',
  phone_1: './images/developments/phone_1.png',
  img_1: './images/developments/mask_1.png',
  phone_2: './images/developments/phone_2.png',
  img_2: './images/developments/mask_2.png',
  phone_3: './images/developments/phone_3.png',
  img_3: './images/developments/mask_3.png',
  dec_1: './images/decor3d/1.webp',
  dec_2: './images/decor3d/2.webp',
  dec_3: './images/decor3d/3.webp',
  dec_4: './images/decor3d/4.webp',
  dec_5: './images/decor3d/5.webp',
  dec_6: './images/decor3d/6.webp',
  dec_7: './images/decor3d/7.webp',
  dec_8: './images/decor3d/8.webp',
  videoPoster: './images/videoBlock/videoPoster.webp',
  poster_2: './images/videoBlock/poster_2.webp',
  man_2: './images/steps/man_2.png',
  poster_back: './images/videoBlock/poster_back.png',
};
