import styled from 'styled-components';

export const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 40px;
  height: 1em;
  resize: none;
  overflow: hidden;
  outline: none;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  margin-left: -20px;
  border: 0 white;
  font-family: Gilroy, serif;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 20px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: Gilroy, serif;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.lightGray_100};
  }
`;
