import React, { useState } from 'react';
import { theme } from '../../../../../themes/theme';
import { Box } from '../../../../../components/Box';
import { Room } from '../../../canvas/classes/Room';
import { LOAD_CLASS_OBJECT } from '../../../../../helpers/constans';
import { Text } from '../../../../../components/Texts/Text';
import { Wall } from '../../../canvas/classes/Wall';

export const PanelTotal = () => {
  const [room] = useState<Room>(() => {
    return LOAD_CLASS_OBJECT.scene.getObjectByName('room') as Room;
  });
  const getTotal = () => {
    let panels = 0;
    const nodes = room.children
      .filter(item => item.name === 'wall' || item.name === 'ceiling')
      .map(wall => {
        const _w = wall as Wall;
        const wallPanels = _w?.panels?.countPanels || 0;
        panels = panels + wallPanels;
        return (
          <Text
            key={wall.uuid}
            fontFamily="Neue Machina"
            size="15px"
            weight={500}
            marginBottom="14px"
          >
            {_w.name === 'wall' ? 'Стена: ' : 'Потолок: '}
            {`${(_w.width * _w.height).toFixed(1)} m2, `}
            {'панелей: '}
            {`${wallPanels} шт,`}
          </Text>
        );
      });
    nodes.push(
      <Text key={'144er45er45er'} fontFamily="Neue Machina" size="18px" weight={700}>
        {`Итого панелей: ${panels} шт`}
      </Text>
    );

    return nodes;
  };
  return (
    <Box
      width="100%"
      height="100%"
      direction="column"
      padding="40px"
      background={theme.colors.white}
      borderRadius="20px"
      align="flex-start"
    >
      {getTotal()}
    </Box>
  );
};
