import React, { CSSProperties, FC, ReactNode } from 'react';
import { PropsTextStyle, Text } from './Texts/Text';
import { SpanColor } from './SpanColor';
import styled from 'styled-components';

interface PropsStyle {
  size?: CSSProperties['fontSize'];
  weight?: CSSProperties['fontWeight'];
  lineHeight?: CSSProperties['lineHeight'];
  textAlign?: CSSProperties['textAlign'];
}

const TextTitleStyle = styled(Text).withConfig({
  shouldForwardProp: prop => !['size', 'weight', 'lineHeight', 'textAlign'].includes(prop),
})<PropsStyle>`
  font-weight: ${({ weight }) => weight || 700};
  font-size: ${({ size }) => size || '50px'};
  line-height: ${({ lineHeight }) => lineHeight || '48px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  text-transform: uppercase;
  @media (max-width: 1420px) {
    font-size: 36px;
    line-height: 38px;
  }
  @media (max-width: 1190px) {
    text-align: center;
    font-size: 34px;
    line-height: 38px;
  }
  @media (max-height: 760px) {
    font-size: 34px;
    line-height: 38px;
  }
  @media (max-width: 640px) {
    font-size: 30px;
    line-height: 29px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 19px;
  }
  @media (max-width: 340px) {
    font-size: 14px;
    line-height: 13px;
  }
`;

interface Props extends PropsStyle {
  text: string[];
  textProps?: Omit<PropsTextStyle, 'fontFamily'>;
}
export const TextTitle: FC<Props> = ({ text, textProps, ...props }) => {
  const getText = () => {
    const arrText: Array<string | ReactNode> = [];
    for (let i = 0; i < text.length; i++) {
      if (text[i].includes('%')) {
        const spl = text[i].split('%');
        if (spl.length === 3) {
          arrText.push(spl[0]);
          arrText.push(<SpanColor key={i.toString()}>{spl[1]}</SpanColor>);
          arrText.push(spl[2]);
          arrText.push(<br key={i.toString() + 'a'} />);
        }
      } else {
        arrText.push(text[i], <br key={i.toString()} />);
      }
    }
    return arrText;
  };
  return (
    <TextTitleStyle marginBottom="90px" fontFamily="Neue Machina" {...props} {...textProps}>
      {getText()}
    </TextTitleStyle>
  );
};
