import React, { CSSProperties, DOMAttributes, forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export interface PropsStyleBox {
  id?: string;
  flex?: CSSProperties['flex'];
  align?: CSSProperties['alignItems'];
  direction?: CSSProperties['flexDirection'];
  justify?: CSSProperties['justifyContent'];
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  padding?: CSSProperties['padding'];
  background?: string;
  height?: CSSProperties['height'];
  overflow?: CSSProperties['overflow'];
  borderRadius?: CSSProperties['borderRadius'];
  position?: CSSProperties['position'];
}

const BoxStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['align', 'borderRadius', 'flex', 'position'].includes(prop),
})<PropsStyleBox>`
  position: ${({ position }) => position || 'static'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${props => props?.align || 'center'};
  justify-content: ${props => props?.justify || 'center'};
  margin: ${props => props?.margin || 0};
  padding: ${({ padding }) => padding || 0};
  background-color: ${({ background }) => background || 'transparent'};
  box-sizing: border-box;
  overflow: ${({ overflow }) => overflow || 'visible'};
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  ${prop =>
    prop?.flex &&
    css<PropsStyleBox>`
      flex: ${props => props.flex};
    `}
`;

export const Box = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PropsStyleBox & DOMAttributes<HTMLDivElement>>
>(({ children, ...props }, ref) => {
  return (
    <BoxStyle ref={ref} {...props}>
      {children}
    </BoxStyle>
  );
});
Box.displayName = 'Box';
