import React, {CSSProperties, FC} from 'react';
import { Text } from '../Texts/Text';
import styled from 'styled-components';

type PropsStyle = {
  left?: CSSProperties['left'];
  bottom?: CSSProperties['bottom'];
};
const ErrorStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['top', 'bottom'].includes(prop),
})<PropsStyle>`
  position: absolute;
  left: ${({ left }) => left || '20px'};
  bottom: ${({ bottom }) => bottom || '-18px'};
  width: calc(100% - 20px);
`;

interface Props extends PropsStyle{
  error?: string;
}
export const InputError: FC<Props> = ({ error, ...props }) => {
  return (
    <ErrorStyle {...props}>
      <Text fontFamily="Gilroy" size="13px" color="red">
        {error}
      </Text>
    </ErrorStyle>
  );
};
