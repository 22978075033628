import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { ScrollBarTrack } from '../../components/ScrollBar';
import { FormPartner } from '../FormPartner';
import { BlockPartnerProgram } from '../../components/Blocks/BlockPartnerProgram';
import { BlockB2B, BlockB2C, BlockB2G } from '../../components/Blocks/BlocksB2';
import { BlockFooterEnd } from '../../components/Blocks/BlockFooterEnd';
import { BlockFooterStart } from '../../components/Blocks/BlockFooterStart';
import { BlockBecomePartner } from '../../components/Blocks/BlockPartnerProgram/BlockBecomePartner';
import { BlockExamples } from '../../components/Blocks/BlockExemples';
import { WrapperBlockStart } from './components/WrapperBlockStart';

export const Partner: FC = () => {
  return (
    <WrapperPage overflow="auto" height="auto" overflowX="hidden">
      <WrapperBlockStart />
      <BlockPartnerProgram />
      <BlockB2C />
      <BlockB2B />
      <BlockB2G />
      <BlockExamples padding="160px 0px 0 0" />
      <BlockBecomePartner />
      <FormPartner />
      <BlockFooterStart />
      <BlockFooterEnd />
      <ScrollBarTrack />
    </WrapperPage>
  );
};
