import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type EmailVerificationCodeCheckQueryVariables = Types.Exact<{
  input: Types.EmailVerificationInput;
}>;


export type EmailVerificationCodeCheckQuery = { __typename?: 'Query', emailVerificationCodeCheck: { __typename?: 'EmailVerificationResponse', success: boolean } };


export const EmailVerificationCodeCheckDocument = gql`
    query EmailVerificationCodeCheck($input: EmailVerificationInput!) {
  emailVerificationCodeCheck(input: $input) {
    success
  }
}
    `;

/**
 * __useEmailVerificationCodeCheckQuery__
 *
 * To run a query within a React component, call `useEmailVerificationCodeCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerificationCodeCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerificationCodeCheckQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailVerificationCodeCheckQuery(baseOptions: Apollo.QueryHookOptions<EmailVerificationCodeCheckQuery, EmailVerificationCodeCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailVerificationCodeCheckQuery, EmailVerificationCodeCheckQueryVariables>(EmailVerificationCodeCheckDocument, options);
      }
export function useEmailVerificationCodeCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerificationCodeCheckQuery, EmailVerificationCodeCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailVerificationCodeCheckQuery, EmailVerificationCodeCheckQueryVariables>(EmailVerificationCodeCheckDocument, options);
        }
export type EmailVerificationCodeCheckQueryHookResult = ReturnType<typeof useEmailVerificationCodeCheckQuery>;
export type EmailVerificationCodeCheckLazyQueryHookResult = ReturnType<typeof useEmailVerificationCodeCheckLazyQuery>;
export type EmailVerificationCodeCheckQueryResult = Apollo.QueryResult<EmailVerificationCodeCheckQuery, EmailVerificationCodeCheckQueryVariables>;