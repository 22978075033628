import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../components/Box';
import { theme } from '../../themes/theme';
import { WrapperPage } from '../../components/WrapperPage';
import { Text } from '../../components/Texts/Text';
import { ButtonStore } from '../../components/Blocks/BlockDownloadApp/components/ButtonStore';
import { ClosePageType } from '../../helpers/types';
import { setSelectPage } from '../../api/store/functionsWitingCache';
import { PatchesName } from '../../__generated__/types';

const WrapperText = styled.div`
  width: 500px;
  height: 180px;
`;
const TextWrapper = styled(Text)`
  margin-bottom: -120px;
  z-index: 1;
`;

export const SomethingWrong: FC<ClosePageType> = () => {
  const handleClick = () => {
    setSelectPage({
      dataWrite: {
        selectPage: PatchesName.Home,
      },
    });
  };
  return (
    <WrapperPage backgroundColor={theme.colors.white} isHeader={false}>
      <Box width="100%" height="100%" direction="column">
        <TextWrapper fontFamily="Neue Machina" size="40px" weight={700} color="black">
          {'Ой, что-то пошло не так...'.toUpperCase()}
        </TextWrapper>
        <WrapperText>
          <Text
            fontFamily="Neue Machina"
            size="240px"
            weight={700}
            lineHeight="160px"
            color="softBlueViolet_200"
          >
            404
          </Text>
        </WrapperText>
        <ButtonStore delay={5} title="На главную" onPress={handleClick} margin="30px 0 0 0" />
      </Box>
    </WrapperPage>
  );
};
