import React, { FC } from 'react';
import styled from 'styled-components';

const WrapperBox = styled.div`
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
`;
const BoxOut = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.softBlueViolet};
`;
const BoxIn = styled.div`
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.softBlueViolet};
`;
interface Props {
  isActive: boolean;
  handleClick: () => void;
}

export const StepRoute: FC<Props> = ({ isActive, handleClick }) => {
  return (
    <WrapperBox onClick={handleClick}>
      {isActive && <BoxOut />}
      <BoxIn />
    </WrapperBox>
  );
};
