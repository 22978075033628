import React, { FC, CSSProperties, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Box, PropsStyleBox } from '../../../Box';

interface PropsAbsolute extends PropsStyleBox {
  img?: string;
  top?: CSSProperties['top'];
  left?: CSSProperties['left'];
  bottom?: CSSProperties['bottom'];
  right?: CSSProperties['right'];
  border?: CSSProperties['border'];
}
const ContainerAbsoluteStyle = styled(Box).withConfig({
  shouldForwardProp: prop => !['top', 'borderRadius', 'img'].includes(prop),
})<PropsAbsolute>`
  position: absolute;
  background-image: url(${({ img }) => img || null});
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  border: ${({ border }) => border || 'none'};
  ${props =>
    !!props?.right &&
    css<PropsAbsolute>`
      right: ${props => props.right};
    `};
  ${props =>
    !!props?.top &&
    css<PropsAbsolute>`
      top: ${props => props.top};
    `};
  ${props =>
    !!props?.bottom &&
    css<PropsAbsolute>`
      bottom: ${props => props.bottom};
    `};
  ${props =>
    !!props?.left &&
    css<PropsAbsolute>`
      left: ${props => props.left};
    `};
`;
export const ContainerAbsolute: FC<PropsWithChildren<PropsAbsolute>> = ({ children, ...props }) => {
  return <ContainerAbsoluteStyle {...props}>{children}</ContainerAbsoluteStyle>;
};
