import React, { FC, useState } from 'react';
import { ItemMeasure } from '../components/ItemMeasure';
import { Box } from '../../../../../components/Box';
import { Text } from '../../../../../components/Texts/Text';
import { SpanColor } from '../../../../../components/SpanColor';
import { DOOR_HEIGHT, LOAD_CLASS_OBJECT } from '../../../../../helpers/constans';
import { Room } from '../../../canvas/classes/Room';
import { theme } from '../../../../../themes/theme';
import styled from 'styled-components';
export const WrapperButton = styled(Box)`
  cursor: pointer;
`;
export const PanelMeasure: FC = () => {
  const [room] = useState<Room>(() => {
    return LOAD_CLASS_OBJECT.scene.getObjectByName('room') as Room;
  });
  const [isPanels, setIsPanels] = useState(room.children?.length > 6);
  const [widthRoom, setWidthRoom] = useState(room.widthRoom);
  const [heightRoom, setHeightRoom] = useState(room.heightRoom);
  const [depthRoom, setDepthRoom] = useState(room.depthRoom);

  const handleChangeHeight = (val: number) => {
    if (room && val > DOOR_HEIGHT + 0.1) {
      setHeightRoom(val);
      room.changeHeightRoom(val);
    }
  };
  const handleChangeWidth = (val: number) => {
    if (room) {
      setWidthRoom(val);
      room.changeWidthRoom(val);
    }
  };
  const handleChangeDepth = (val: number) => {
    if (room) {
      setDepthRoom(val);
      room.changeDepthRoom(val);
    }
  };
  const removeAllPanels = () => {
    room.removeAllPanels();
    setIsPanels(false);
  };

  return (
    <Box
      width="100%"
      height="100%"
      direction="column"
      padding="20px"
      background={theme.colors.white}
      borderRadius="20px"
    >
      {isPanels ? (
        <>
          <Text
            fontFamily="Neue Machina"
            size="20px"
            weight={700}
            marginBottom="20px"
            textAlign="center"
          >
            {'Нельзя изменить размеры комнаты, если на стенах установлены панели.'}
            <br />
            {'Удалить панели?'}
          </Text>
          <WrapperButton
            width="250px"
            height="50px"
            borderRadius="25px"
            background={theme.colors.softBlueViolet}
            onClick={removeAllPanels}
          >
            <Text fontFamily="Neue Machina" size="18px" weight={700} color="white">
              Удалить
            </Text>
          </WrapperButton>
        </>
      ) : (
        <>
          <Text fontFamily="Neue Machina" size="20px" weight={700} marginBottom="30px">
            {`Размеры комнаты (`}
            <SpanColor>мм</SpanColor>
            {')'}
          </Text>
          <ItemMeasure
            title="Высота"
            currentValue={heightRoom}
            changeSizeRoom={handleChangeHeight}
          />
          <ItemMeasure
            title="Ширина"
            currentValue={widthRoom}
            changeSizeRoom={handleChangeWidth}
            margin="20px 0"
          />
          <ItemMeasure title="Длина" currentValue={depthRoom} changeSizeRoom={handleChangeDepth} />
        </>
      )}
    </Box>
  );
};
