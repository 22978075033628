import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';
import { Icon } from '../../../Icons/Icon';
import { Text } from '../../../Texts/Text';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 630px;
  background-color: #f8f8f8e5;
  z-index: 1;
  transition: opacity 300ms linear;
  opacity: 0;
  overflow: hidden;
`;
const Wrapper = styled.div`
  width: 1180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 50px;
  transition: transform 300ms linear;
  transform: translateY(560px);
`;
const BlockTexts = styled(Box)`
  flex-wrap: wrap;
  column-gap: 68px;
`;
const WrapArrow = styled(Box)`
  cursor: pointer;
`;
interface Props {
  changeState(): void;
  isShow: boolean;
  texts: string[];
  height?: number;
}

export const InformComponent: FC<Props> = ({ changeState, isShow, texts, height = 460 }) => {
  const container = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (container.current && wrapper.current) {
      if (isShow) {
        container.current.style.opacity = '1';
        container.current.style.zIndex = '3';
        wrapper.current.style.transform = 'translateY(0px)';
      } else {
        container.current.style.opacity = '0';
        container.current.style.zIndex = '1';
        wrapper.current.style.transform = 'translateY(560px)';
      }
    }
  }, [isShow]);
  return (
    <Container ref={container}>
      <Wrapper ref={wrapper}>
        <WrapArrow
          width="40px"
          height="40px"
          borderRadius="50%"
          background={theme.colors.softBlueViolet}
          onClick={changeState}
        >
          <Icon name="arrowLeft" fill={theme.colors.white} />
        </WrapArrow>
        <BlockTexts
          width="fit-content"
          height={`${height}px`}
          direction="column"
          align="flex-start"
          justify="flex-start"
        >
          {texts.map((text, index) => (
            <Text
              key={index.toString()}
              fontFamily="Gilroy"
              weight="500"
              size="20px"
              width="460px"
              marginBottom="20px"
            >
              {text}
            </Text>
          ))}
        </BlockTexts>
      </Wrapper>
    </Container>
  );
};
