import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { Box } from '../../components/Box';
import { LogoBox } from './components/LogoBox';
import { AddressBox } from './components/AddressBox';
import { BoxCompany } from './components/BoxCompany';

export const Contacts: FC = () => {
  return (
    <WrapperPage>
      <Box width="100%" height="100%">
        <div>
          <AddressBox />
          <LogoBox />
          <BoxCompany />
        </div>
      </Box>
    </WrapperPage>
  );
};
