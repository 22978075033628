/* eslint-disable no-console */
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Load } from './constructor/Load';

import imag11 from '../../../../assets/img/wallTexture/t_1.ktx2';
import imag12 from '../../../../assets/img/wallTexture/t_2.ktx2';
import imag13 from '../../../../assets/img/wallTexture/t_3.ktx2';
import imag14 from '../../../../assets/img/wallTexture/t_4.ktx2';
import imag15 from '../../../../assets/img/wallTexture/t_5.ktx2';
import imag16 from '../../../../assets/img/wallTexture/t_6.ktx2';
import { IMAGE_URLS } from '../../../PreloadImages/helpers/constans';
import { LoadTexturesKTX, TEXTURES_KTX2 } from './helper/textures';

const Images = [imag11, imag12, imag13, imag14, imag15, imag16];
interface Props {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}
const Container = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  background-color: black;
  overflow: hidden;
`;
const Canvas = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
`;
type ItemFrame = {
  start: number;
  end: number;
  duration: number;
};
const LEN = (window.innerHeight * 3 - window.innerWidth) / 2;
const Frames: Array<ItemFrame> = [
  { start: 0, end: -LEN, duration: 9000 },
  { start: -LEN, end: LEN, duration: 18000 },
  { start: LEN, end: -LEN, duration: 18000 },
];

interface PropsCanvas {
  endLoadPage(): void;
}
export const ContainerCanvas: FC<PropsCanvas> = ({ endLoadPage }) => {
  const [isTextures, setIsTextures] = useState(() => {
    return TEXTURES_KTX2.length > 0;
  });
  const [isImgVisible, setIsImgVisible] = useState(true);
  const currentImage = useRef(0);
  const currFrame = useRef(0);
  const container = useRef<HTMLDivElement | null>(null);
  const canvas = useRef<HTMLDivElement | null>(null);
  const animateTranslate = useRef<Animation>();
  const isAnimation = useRef(true);
  const timer = useRef<NodeJS.Timer>();

  const startAnimationChangeTexture = () => {
    if (TEXTURES_KTX2.length > 0 && isAnimation.current) {
      let _currImg = currentImage.current + 1;
      let next = currentImage.current;
      if (_currImg === Images.length) {
        _currImg = 0;
      }
      currentImage.current = _currImg;

      if (next === Images.length) {
        next = 0;
      }
      Load.startAnimateChangeTexture(
        TEXTURES_KTX2[_currImg],
        TEXTURES_KTX2[next],
        startAnimContainer
      );
    }
  };
  const handleLoadFrontImage = () => {
    endLoadPage();
  };
  function startAnimContainer() {
    if (container.current) {
      const { start, end, duration } = Frames[currFrame.current];
      currFrame.current++;
      if (currFrame.current >= Frames.length) {
        currFrame.current = 1;
      }
      animateTranslate.current = container.current?.animate(
        [{ transform: `translateX(${start}px)` }, { transform: `translateX(${end}px)` }],
        {
          duration: duration,
          easing: 'linear',
        }
      );
      animateTranslate.current.onfinish = () => {
        if (container.current) {
          setIsImgVisible(false);
          container.current.style.transform = `translateX(${end}px)`;
          startAnimationChangeTexture();
        }
      };
    }
  }
  function onWindowResize() {
    if (animateTranslate.current) {
      animateTranslate.current?.cancel();
      animateTranslate.current = undefined;
    }
    if (container.current) {
      container.current.style.transform = `translateX(0px)`;
      const box = container.current?.getBoundingClientRect();
      Load.camera.aspect = box.width / box.height;
      Load.camera.updateProjectionMatrix();
      Load.renderer.setSize(box.width, box.height);
      Load.renderer.domElement.style.width = `${box.width}px`;
      Load.renderer.domElement.style.height = `${box.height}px`;
      Load.renderer.render(Load.scene, Load.camera);
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      const _len = (window.innerHeight * 3 - window.innerWidth) / 2;
      Frames[0].start = 0;
      Frames[0].end = -_len;
      Frames[1].start = -_len;
      Frames[1].end = _len;
      Frames[2].start = _len;
      Frames[2].end = -_len;
      startAnimContainer();
    }, 3000);
  }

  useEffect(() => {
    if (canvas.current && isTextures) {
      Load.initScene(
        canvas.current,
        TEXTURES_KTX2[currentImage.current],
        TEXTURES_KTX2[currentImage.current + 1]
      );
      startAnimContainer();
    }
  }, [isTextures]);
  useEffect(() => {
    if (TEXTURES_KTX2.length === 0) {
      LoadTexturesKTX().then(() => {
        setIsTextures(true);
      });
    }

    window.addEventListener('resize', onWindowResize, false);
    return () => {
      window.removeEventListener('resize', onWindowResize, false);
    };
  }, []);

  return (
    <Container ref={container}>
      <Canvas ref={canvas} />
      {isImgVisible && (
        <img
          onLoad={handleLoadFrontImage}
          decoding="async"
          src={IMAGE_URLS.start}
          width="100%"
          height="100%"
          alt="image"
          style={{ position: 'absolute', left: 0, top: 0 }}
        />
      )}
    </Container>
  );
};
