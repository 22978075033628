/* eslint-disable no-console */
/* eslint-disable no-var */
import * as THREE from 'three';
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
import doorModel from '../../models/door.3DS';
import panelModel from '../../models/panel.3DS';
import { SelectedType } from '../types';
import {
  setIsOpenPanelAddTextureAndDoor,
  setPanelsDoor,
} from '../../../../api/store/functionsWitingCache';
import { DoorObject } from '../classes/DoorObject';

export interface EditConfigType {
  addObjectsIntersected(obj: SelectedType): void;
  activate(contCanvas: HTMLDivElement): void;
  removeObjectsIntersected(uuidObj: string): void;
  deactivateListeners(): void;
  cleanSelectObject(): void;
  getSelectedObject(): SelectedType | null;
  getDoorGeometry(): THREE.BufferGeometry | null;
  getPanelGeometry(): THREE.BufferGeometry;
}

interface Props {
  camera: THREE.PerspectiveCamera;
}
export default ({ camera }: Props) => {
  var objectsIntersects: Array<SelectedType> = [];
  var mouse: THREE.Vector2 = new THREE.Vector2();
  var rayCast: THREE.Raycaster = new THREE.Raycaster();
  var loadCamera: THREE.PerspectiveCamera = camera;
  var widthContainer = 0;
  var heightContainer = 0;
  var containerCanvas = document.createElement('div');
  var selectedObject: SelectedType | null = null;
  var doorFrame: THREE.BufferGeometry | null = null;
  var panelGeometry: THREE.BufferGeometry;

  var EDIT: EditConfigType = {
    activate(contCanvas) {
      containerCanvas = contCanvas;
      var rect = contCanvas.getBoundingClientRect();
      widthContainer = rect.width;
      heightContainer = rect.height;
      if (window.PointerEvent) {
        containerCanvas.addEventListener('pointerdown', constructorMouseDown);
        containerCanvas.addEventListener('pointermove', constructorMouseMove);
        containerCanvas.addEventListener('pointerup', constructorMouseUp);
      } //
      else {
        // this.containerCanvas.addEventListener('mousedown', constructorMouseDown);
        // this.containerCanvas.addEventListener('mousemove', constructorMouseMove);
        // this.containerCanvas.addEventListener('mouseup', constructorMouseUp);
        containerCanvas.addEventListener('touchstart', constructorTouchStart);
        containerCanvas.addEventListener('touchmove', constructorTouchMove);
        containerCanvas.addEventListener('touchend', constructorTouchEnd);
      }
      load3DSGeometry(doorModel, geom => {
        doorFrame = geom;
      });
      load3DSGeometry(panelModel, geom => {
        panelGeometry = geom;
      });
    },
    deactivateListeners() {
      if (window.PointerEvent) {
        containerCanvas.removeEventListener('pointerdown', constructorMouseDown);
        containerCanvas.removeEventListener('pointermove', constructorMouseMove);
        containerCanvas.removeEventListener('pointerup', constructorMouseUp);
      } //
      else {
        // this.domElement.removeEventListener('mousedown', ListenersCanvas.constructorMouseDown);
        // this.domElement.removeEventListener('mousemove', ListenersCanvas.constructorMouseMove);
        // this.domElement.removeEventListener('mouseup', ListenersCanvas.constructorMouseUp);
        containerCanvas.removeEventListener('touchstart', constructorTouchStart);
        containerCanvas.removeEventListener('touchmove', constructorTouchMove);
        containerCanvas.removeEventListener('touchend', constructorTouchEnd);
      }
    },
    addObjectsIntersected(obj) {
      objectsIntersects.push(obj);
    },
    cleanSelectObject() {
      if (selectedObject) {
        selectedObject.selectUnselectSelfObj();
        selectedObject = null;
        clearEvent();
      }
    },
    removeObjectsIntersected(uuidObj) {
      const index = objectsIntersects.findIndex(item => item.uuid === uuidObj);
      if (index !== -1) {
        objectsIntersects.splice(index, 1);
        if (selectedObject && selectedObject.uuid === uuidObj) {
          this.cleanSelectObject();
        }
      }
    },
    getSelectedObject() {
      return selectedObject;
    },
    getDoorGeometry() {
      return doorFrame;
    },
    getPanelGeometry() {
      return panelGeometry;
    },
  };
  return EDIT;
  function constructorMouseDown(evt: PointerEvent) {
    evt.preventDefault();
    convertCoordinateDomInThree(evt);
    rayCast.setFromCamera(mouse, loadCamera);
    var inters = rayCast.intersectObjects<SelectedType>(objectsIntersects, false);
    // console.log(inters, objectsIntersects);

    switch (evt.buttons) {
      case 1:
        selectedWallObjectWeb(inters[0]?.object);
        break;
      case 2:
        selectedObject?.selectUnselectSelfObj();
        selectedObject = null;
        clearEvent();
        break;
    }
  }
  function constructorMouseMove(evt: PointerEvent) {
    /**/
  }
  function constructorMouseUp(evt: PointerEvent) {
    /**/
  }
  function constructorTouchStart(evt: TouchEvent) {
    /**/
  }
  function constructorTouchMove(evt: TouchEvent) {
    /**/
  }
  function constructorTouchEnd(evt: TouchEvent) {
    /**/
  }
  function convertCoordinateDomInThree(evt: PointerEvent | TouchEvent) {
    if (evt instanceof TouchEvent) {
      var rect = containerCanvas.getBoundingClientRect();
      mouse.x = ((evt.changedTouches[0].clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((evt.changedTouches[0].clientY - rect.top) / rect.height) * 2 + 1;
    } else {
      mouse.x = (evt.offsetX / widthContainer) * 2 - 1;
      mouse.y = -(evt.offsetY / heightContainer) * 2 + 1;
    }

    // const swipe = new THREE.Vector3();
    // swipe.set(LOAD.mouse.x, LOAD.mouse.y, 0);
    // swipe.unproject(LOAD.camera).setY(0);
    // return swipe;
  }
  function selectedWallObjectWeb(obj: SelectedType) {
    if (obj) {
      if (selectedObject && selectedObject.uuid === obj.uuid) {
        selectedObject.selectUnselectSelfObj();
        selectedObject = null;
        clearEvent();
      } //
      else if (selectedObject && selectedObject.uuid !== obj.uuid) {
        clearEvent();
        selectedObject.selectUnselectSelfObj();
        selectedObject = obj;
        selectedObject.selectUnselectSelfObj();
        setTimeout(() => {
          obj instanceof DoorObject ? eventDoor(true) : eventWall(true);
        }, 600);
      } //
      else {
        selectedObject = obj;
        selectedObject.selectUnselectSelfObj();
        obj instanceof DoorObject ? eventDoor(true) : eventWall(true);
      }
    }
  }
  function eventDoor(v: boolean) {
    setPanelsDoor({
      dataWrite: {
        isOpenPanelSettingsDoor: v,
      },
    });
  }
  function eventWall(v: boolean) {
    setIsOpenPanelAddTextureAndDoor({
      dataWrite: {
        isOpenPanelAddImageAndDoor: v,
      },
    });
  }
  function clearEvent() {
    eventDoor(false);
    eventWall(false);
  }
  function load3DSGeometry(model: string, callback: (variableModel: THREE.BufferGeometry) => void) {
    const dsLoader = new TDSLoader();
    dsLoader.load(model, group => {
      group.children.forEach(child => {
        if (child instanceof THREE.Mesh<THREE.BufferGeometry>) {
          if (child.geometry.isBufferGeometry) {
            callback(child.geometry);
          } else {
            callback(child.geometry);
          }
        }
      });
    });
  }
};
