import { DocumentNode } from 'graphql/language';
import { apolloCache } from '../apolloCacheConfiguration';

interface CustomWriteQueryProps<T extends object, U extends object> {
  node: DocumentNode;
  dataWrite: U;
  _variables?: T;
}
export function customWriteQuery({
  node,
  dataWrite,
  _variables = {},
}: CustomWriteQueryProps<object, object>) {
  apolloCache.writeQuery({
    query: node,
    variables: {
      ..._variables,
    },
    data: {
      ...dataWrite,
    },
  });
}
