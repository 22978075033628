import React, { FC } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';

const scale = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  83% {
    transform: scale(1);
    opacity: 0.8;
  }
  84% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(3.6);
    opacity: 0;
  }
`;

interface Props {
  daley: number;
  size?: number;
}
const animation = (props: Props) =>
  css<Props>`
    ${scale} 10s ${props.daley}ms infinite;
  `;
const AnimCircle = styled(Box).withConfig({
  shouldForwardProp: prop => !['daley'].includes(prop),
})<Props>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.softBlueVioletTransparent};
  animation: ${animation};
  @media (max-height: 890px) {
    width: 18px;
    height: 18px;
  }
`;
const CheckStyle = styled(Box)`
  position: absolute;
  border: ${({ theme }) => `3px solid ${theme.colors.softBlueViolet}`};
  margin-left: -82px;
  @media (max-height: 890px) {
    width: 40px;
    height: 40px;
    margin-left: -74px;
  }
`;
const WrapperBox = styled(Box)`
  @media (max-height: 890px) {
    width: 18px;
    height: 18px;
  }
`;

export const CheckComponent: FC<Props> = ({ daley, size = 50 }) => {
  const small = size - 30;
  return (
    <CheckStyle width={`${size}px`} height={`${size}px`} borderRadius="50%">
      <WrapperBox
        width={`${small}px`}
        height={`${small}px`}
        background={theme.colors.softBlueViolet}
        borderRadius="50%"
      />
      <AnimCircle width={`${small}px`} height={`${small}px`} borderRadius="50%" daley={daley} />
    </CheckStyle>
  );
};
