import React from 'react';
import { theme } from '../../../../themes/theme';

export const Insulation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 1707 1707"
      fillRule="evenodd"
    >
      <g>
        <path
          d="M1534 1104h-295v98h295zm142 284H89c-38-1-88 9-88-30 0-35 38-30 58-30v-96c0-34 35-30 54-30V121c0-16 13-30 30-30h1421c17 0 30 14 30 30v1081c18 0 54-4 54 30v96c20 0 58-5 58 30 0 16-13 30-30 30zm-1557-60h1469v-66H119zm1364 174H224c-40 0-40-60 0-60h1259c39 0 39 60 0 60zm-148 113H372c-40 0-40-60 0-60h963c39 0 39 60 0 60zm199-987H173v257h1361zM173 568h1361V151H173zm132 276c-35 0-82 8-82-30s47-30 82-30c39 0 39 60 0 60zm0-116c-35 0-82 9-82-30 0-38 47-29 82-29 39 0 39 59 0 59zm139 58c-34 0-82 9-82-30 0-38 48-30 82-30 40 0 40 60 0 60zm148 58c-35 0-82 8-82-30s47-30 82-30c40 0 40 60 0 60zm0-116c-35 0-82 9-82-30 0-38 47-29 82-29 40 0 40 59 0 59zm140 58c-35 0-82 9-82-30 0-38 47-30 82-30 39 0 39 60 0 60zm147 58c-34 0-82 8-82-30s48-30 82-30c40 0 40 60 0 60zm0-116c-34 0-82 9-82-30 0-38 48-29 82-29 40 0 40 59 0 59zm140 58c-35 0-82 9-82-30 0-38 47-30 82-30 39 0 39 60 0 60zm148 58c-35 0-82 8-82-30s47-30 82-30c39 0 39 60 0 60zm0-116c-35 0-82 9-82-30 0-38 47-29 82-29 39 0 39 59 0 59zm139 58c-34 0-82 9-82-30 0-38 48-30 82-30 40 0 40 60 0 60zm148 58c-35 0-82 8-82-30s47-30 82-30c39 0 39 60 0 60zm0-116c-35 0-82 9-82-30 0-38 47-29 82-29 39 0 39 59 0 59zM173 1202h295v-98H173zm355 0h295v-98H528zm355 0h296v-98H883zm-710-158h117v-99H173zm473 0v-99H350v99zm355 0v-99H706v99zm355 0v-99h-295v99zm60 0h118v-99h-118z"
          fill={theme.colors.white}
        />
      </g>
    </svg>
  );
};
