/* eslint-disable */
import * as THREE from 'three';
import textureWater from '../../../../../assets/img/waterdudv.jpg';

interface RefractorType extends THREE.Mesh {
  material: THREE.ShaderMaterial;
}

const vertex =
  'varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}';
const fragment = `
				varying vec2 vUv;

				uniform sampler2D currentImage;
				uniform sampler2D nextImage;
				uniform sampler2D disp;
				uniform float dispFactor;
				uniform float effectFactor;
				uniform vec4 resolution;

				void main() {

					// vec2 uv = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
					vec2 uv = vUv;

					vec4 disp = texture2D(disp, uv);
          vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*effectFactor), uv.y);
          vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*effectFactor), uv.y);
          vec4 _currentImage = texture2D(currentImage, distortedPosition);
          vec4 _nextImage = texture2D(nextImage, distortedPosition2);
          vec4 finalTexture = mix(_currentImage, _nextImage, dispFactor);

          gl_FragColor = finalTexture; }
				`;

class CreateScene {
  static currentInstance: CreateScene;

  callbackEndAnimate?: () => void;
  isAnimation = true;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  widthCanvas = 600;
  heightCanvas = 600;
  refractor: RefractorType | undefined;
  isInitialized = false;

  constructor() {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer();
    this.camera = new THREE.PerspectiveCamera();
    CreateScene.currentInstance = this;
  }

  initScene(
    contCanvas: HTMLDivElement,
    textureForward: THREE.Texture,
    textureBack: THREE.Texture
  ): void {
    const box = contCanvas.getBoundingClientRect();
    this.widthCanvas = box.width;
    this.heightCanvas = box.height;

    this.scene.add(this.camera);
    this.camera.fov = 75;
    this.camera.aspect = box.width / box.height;
    this.camera.near = 0.1;
    this.camera.far = 200;
    this.camera.position.copy(new THREE.Vector3(0, 10, 14.03));
    this.camera.lookAt(new THREE.Vector3(0, 10, 0));
    this.camera.updateProjectionMatrix();
    this.renderer.setClearColor('#000000');
    // this.renderer.outputEncoding = THREE.LinearEncoding;

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.widthCanvas, this.heightCanvas);
    // this.renderer.outputEncoding = THREE.LinearEncoding,
    this.renderer.domElement.setAttribute('id', 'canvas_scheme_constructor');

    contCanvas.innerHTML = '';
    contCanvas.appendChild(this.renderer.domElement);
    this.renderer.domElement.style.position = 'absolute';

    this.createWaterObject(textureForward, textureBack);
    this.isInitialized = true;
  }
  createWaterObject(textureForward: THREE.Texture, textureBack: THREE.Texture) {
    const textureWave = new THREE.TextureLoader().load(textureWater);
    textureWave.wrapS = textureWave.wrapT = THREE.RepeatWrapping;
    const geometry = new THREE.PlaneGeometry(60, 20, 10, 10);
    const shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        effectFactor: {
          value: 0.08,
        },
        dispFactor: {
          value: 0,
        },
        currentImage: {
          value: textureForward,
        },
        nextImage: {
          value: textureBack,
        },
        disp: {
          value: textureWave,
        },
        resolution: {
          value: new THREE.Vector4(),
        },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
      opacity: 0.5,
    });
    this.refractor = new THREE.Mesh(geometry, shaderMaterial);
    this.refractor.position.set(0, 10, 1);
    this.scene.add(this.refractor);
  }
  startAnimateChangeTexture(
    nextTexture: THREE.Texture,
    prevTexture: THREE.Texture,
    callbackEndAnimate?: () => void
  ) {
    this.callbackEndAnimate = callbackEndAnimate;
    if (this.refractor) {
      this.refractor.material.uniforms.nextImage.value = nextTexture;
      // @ts-ignore
      this.refractor.material.uniforms.nextImage.needsUpdate = true;

      this.refractor.material.uniforms.currentImage.value = prevTexture;
      // @ts-ignore
      this.refractor.material.uniforms.currentImage.needsUpdate = true;

      this.refractor.material.uniforms.dispFactor.value = 0;
      this.isAnimation = true;
      this.animate(this.camera, this.scene, this.renderer, true);
    }
  }
  animate(
    camera: THREE.PerspectiveCamera,
    scene: THREE.Scene,
    renderer: THREE.WebGLRenderer,
    isChange?: boolean
  ) {
    if (this.isAnimation) {
      requestAnimationFrame(() => this.animate(camera, scene, renderer, isChange));

      if (isChange && this.refractor) {
        if (this.refractor.material.uniforms.dispFactor.value < 1) {
          this.refractor.material.uniforms.dispFactor.value += 0.006;
        } else {
          this.isAnimation = false;
          if (this?.callbackEndAnimate) {
            this.callbackEndAnimate();
          }
        }
      }

      renderer.render(scene, camera);
    }
  }
}
export const Load = new CreateScene();
