import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GALLERY } from '../../assets/gallery/constans';

interface PropsContainer {
  url: string;
  width?: number;
}
const WrapperBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: fit-content;
  height: 100%;
  display: flex;
  overflow: hidden;
  gap: 20px;
  transition: transform 400ms ease-in-out;
`;
const ContainerImg = styled.div.withConfig({
  shouldForwardProp: prop => !['url'].includes(prop),
})<PropsContainer>`
  width: ${({ width }) => width || '1180'}px;
  height: 100%;
  background-image: url('${({ url }) => url}');
  background-size: cover;
  background-position: 50% 50%;
`;
interface Props {
  offset: number;
}
export const Gallery: FC<Props> = ({ offset }) => {
  const container = useRef<HTMLDivElement>(null);
  const getWidthLeftEmptyBlock = () => {
    return (window.innerWidth - 1220) / 2;
  };

  useEffect(() => {
    if (container.current) {
      container.current.style.transform = `translateX(${offset}px)`;
    }
  }, [offset]);

  return (
    <WrapperBox ref={container}>
      <ContainerImg url="" width={getWidthLeftEmptyBlock()} />
      {GALLERY.map(url => (
        <ContainerImg key={url} url={url} />
      ))}
    </WrapperBox>
  );
};
