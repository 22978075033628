import { RefObject, useEffect, useRef } from 'react';
import { MenuItemsHeaderAnchor } from '../helpers/constans';

interface Props {
  orderNumber: number;
  childRef?: RefObject<HTMLDivElement> | null;
}
export const useDefineScrollForAnchor = ({ orderNumber, childRef }: Props) => {
  const isStartDefineParams = useRef(false);
  const defineParamChildren = () => {
    if (childRef?.current) {
      const box = childRef.current.getBoundingClientRect();
      MenuItemsHeaderAnchor[orderNumber].scrollTo = box.y - 70;
    }
  };

  useEffect(() => {
    if (!isStartDefineParams.current) {
      isStartDefineParams.current = true;
      setTimeout(() => {
        defineParamChildren();
      }, 500);
    }
  }, []);
};
