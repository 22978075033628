import React, { CSSProperties, useRef, useState } from 'react';
import styled from 'styled-components';
import { ScrollBarThumb } from './ScrollBarThumb';

const WIDTH_TRACK = 10;
interface PropsStyle {
  opacity: CSSProperties['opacity'];
}
const ScrollBarTrackStyled = styled('div').withConfig({
  shouldForwardProp: prop => !['opacity'].includes(prop),
})<PropsStyle>`
  position: fixed;
  top: 0;
  right: 0;
  width: ${WIDTH_TRACK}px;
  height: 100vh;
  z-index: 10;
  opacity: ${props => props.opacity};
  transition: opacity 700ms ease;
  &:hover {
    opacity: 1;
  }
`;
export const ScrollBarTrack = () => {
  const [opacity, setOpacity] = useState<CSSProperties['opacity']>(0);
  const timer = useRef<NodeJS.Timer>();
  const changeOpacity = () => {
    setOpacity(1);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setOpacity(0);
    }, 3000);
  };
  return (
    <ScrollBarTrackStyled opacity={opacity}>
      <ScrollBarThumb widthBar={WIDTH_TRACK} changeOpacity={changeOpacity} />
    </ScrollBarTrackStyled>
  );
};
