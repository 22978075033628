import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type IsLoadingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IsLoadingQuery = { __typename?: 'Query', isLoading: boolean };


export const IsLoadingDocument = gql`
    query IsLoading {
  isLoading @client
}
    `;

/**
 * __useIsLoadingQuery__
 *
 * To run a query within a React component, call `useIsLoadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoadingQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoadingQuery(baseOptions?: Apollo.QueryHookOptions<IsLoadingQuery, IsLoadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsLoadingQuery, IsLoadingQueryVariables>(IsLoadingDocument, options);
      }
export function useIsLoadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsLoadingQuery, IsLoadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsLoadingQuery, IsLoadingQueryVariables>(IsLoadingDocument, options);
        }
export type IsLoadingQueryHookResult = ReturnType<typeof useIsLoadingQuery>;
export type IsLoadingLazyQueryHookResult = ReturnType<typeof useIsLoadingLazyQuery>;
export type IsLoadingQueryResult = Apollo.QueryResult<IsLoadingQuery, IsLoadingQueryVariables>;