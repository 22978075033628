import React, { CSSProperties, FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Header } from '../Header';
import { HeaderLogo } from '../HeaderLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectPageQuery } from '../../api/store/query/__generated__/selectPage';
import { PatchesName } from '../../__generated__/types';
import { setSelectPage } from '../../api/store/functionsWitingCache';
import { HeaderStepper } from '../HeaderStepper';

interface Props {
  backgroundColor?: string;
  isHeader?: boolean;
  overflow?: CSSProperties['overflow'];
  height?: CSSProperties['height'];
  overflowX?: CSSProperties['overflowX'];
}
export const WrapperPageStyle = styled.div.withConfig({
  shouldForwardProp: prop => !['backgroundColor', 'overflow', 'height', 'overflowX'].includes(prop),
})<Props>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height || '100vh'};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  ${prop =>
    prop?.overflowX &&
    css<Props>`
      overflow-x: ${props => props.overflowX};
    `}
  user-select: none;
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
`;

export const WrapperPage: FC<PropsWithChildren<Props>> = ({
  children,
  isHeader = true,
  ...props
}) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { data: dataSElectPage } = useSelectPageQuery();
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);

  const handleEnterAnimate = (val: '0' | '1', callback?: () => void) => {
    if (wrapper.current) {
      wrapper.current.animate([{ opacity: val }], {
        duration: val === '0' ? 200 : 100,
        easing: 'linear',
      }).onfinish = () => {
        setSelectPage({
          dataWrite: {
            selectPage: PatchesName.None,
          },
        });
        if (wrapper.current) {
          wrapper.current.style.opacity = val;
        }
        if (callback) {
          callback();
        }
      };
    }
  };
  const handleClosePage = (patchesName: PatchesName): void => {
    if (!pathname.includes(patchesName)) {
      handleEnterAnimate('0', () => {
        navigate(`/${patchesName}`);
      });
    }
  };

  useEffect(() => {
    if (dataSElectPage?.selectPage && dataSElectPage.selectPage !== PatchesName.None) {
      handleClosePage(dataSElectPage.selectPage);
    }
  }, [dataSElectPage]);
  useEffect(() => {
    handleEnterAnimate('1');
  }, []);

  return (
    <WrapperPageStyle ref={wrapper} {...props}>
      {isHeader && <HeaderLogo openHeaderMenu={() => setIsHeaderVisible(true)} />}
      <Header isHeaderVisible={isHeaderVisible} closeMenu={() => setIsHeaderVisible(false)} />
      {children}
      <HeaderStepper />
    </WrapperPageStyle>
  );
};
