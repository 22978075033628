/* eslint-disable no-var */
import * as THREE from 'three';
import { ParamsConstructorWall, Wall } from './Wall';
import { Load } from '../work/load';
import { Floor } from './Floor';
import { EditConfigType } from '../work/edit';
import { DoorObject } from './DoorObject';
import { PanelsGroup } from './PanelsGroup';
import { Ceiling } from './Ceiling';

type ParamsWall = Omit<ParamsConstructorWall, 'material'>;
interface PropsConstructorRoom {
  widthRoom: number;
  heightRoom: number;
  depthRoom: number;
  LoadComponent: Load;
  edit: EditConfigType;
  baseWallTexture: THREE.Texture;
  baseFloorTexture: THREE.Texture;
}

export class Room extends THREE.Group {
  name: string;
  widthRoom: number;
  heightRoom: number;
  depthRoom: number;
  load: Load;
  edit: EditConfigType;
  baseWallTexture: THREE.Texture;
  baseFloorTexture: THREE.Texture;
  leftWall: Wall;
  rightWall: Wall;
  backWall: Wall;
  frontWall: Wall;
  floor: Floor;
  ceiling: Ceiling;
  constructor({
    widthRoom,
    heightRoom,
    depthRoom,
    LoadComponent,
    edit,
    baseWallTexture,
    baseFloorTexture,
  }: PropsConstructorRoom) {
    super();
    this.name = 'room';
    this.widthRoom = widthRoom;
    this.heightRoom = heightRoom;
    this.depthRoom = depthRoom;
    this.load = LoadComponent;
    this.baseWallTexture = baseWallTexture;
    this.baseFloorTexture = baseFloorTexture;
    this.edit = edit;

    this.rightWall = this.addingRightWall();
    this.leftWall = this.addingLeftWall();
    this.backWall = this.addingBackWall();
    this.frontWall = this.addingFrontWall();
    this.floor = this.addingFloor();
    this.ceiling = this.addingCeiling();
    this.buildRoom();
  }

  buildRoom() {
    this.position.y += this.heightRoom / 2 - 0.1;
    // this.addingCeiling();
  }
  addingRightWall(): Wall {
    var params: ParamsWall = {
      width: this.depthRoom,
      height: this.heightRoom,
      position: new THREE.Vector3(-this.widthRoom / 2, 0, 0),
      rotate: Math.PI / 2,
    };
    return this.addingObject(params);
  }
  addingLeftWall(): Wall {
    var params: ParamsWall = {
      width: this.depthRoom,
      height: this.heightRoom,
      position: new THREE.Vector3(this.widthRoom / 2, 0, 0),
      rotate: -Math.PI / 2,
    };
    return this.addingObject(params);
  }
  addingFrontWall(): Wall {
    var params: ParamsWall = {
      width: this.widthRoom,
      height: this.heightRoom,
      position: new THREE.Vector3(0, 0, this.depthRoom / 2),
      rotate: Math.PI,
    };
    return this.addingObject(params);
  }
  addingBackWall(): Wall {
    var params: ParamsWall = {
      width: this.widthRoom,
      height: this.heightRoom,
      position: new THREE.Vector3(0, 0, -this.depthRoom / 2),
      rotate: 0,
    };
    return this.addingObject(params);
  }
  addingFloor() {
    var aspect = this.widthRoom / this.depthRoom;
    this.baseFloorTexture.wrapS = this.baseFloorTexture.wrapT = THREE.RepeatWrapping;
    this.baseFloorTexture.repeat.set(3 * aspect, 3);
    var params: ParamsConstructorWall = {
      width: this.widthRoom,
      height: this.depthRoom,
      position: new THREE.Vector3(0, -this.heightRoom / 2, 0),
      material: new THREE.MeshBasicMaterial({
        map: this.baseFloorTexture,
      }),
    };
    var floor = new Floor(params);
    floor.rotation.x += -Math.PI / 2;
    this.add(floor);
    return floor;
  }
  addingCeiling() {
    var params: ParamsConstructorWall = {
      width: this.widthRoom,
      height: this.depthRoom,
      position: new THREE.Vector3(0, this.heightRoom / 2, 0),
      rotate: 0,
      material: new THREE.MeshBasicMaterial({
        map: this.baseWallTexture,
      }),
    };
    var ceiling = new Ceiling(params);
    ceiling.rotation.x += Math.PI / 2;
    this.add(ceiling);
    this.addingInters(ceiling);
    return ceiling;
  }

  changeHeightRoom(newHeight: number) {
    var offset = newHeight - this.heightRoom;
    this.heightRoom = newHeight;
    var walls = this.children.filter(wall => wall.name === 'wall');
    walls.forEach(wall => {
      (wall as Wall).changeHeightWall(newHeight);
    });
    this.ceiling.position.y += offset;
  }
  changeWidthRoom(newWidth: number) {
    var offset = newWidth - this.widthRoom;
    this.backWall.changeWidthWall(newWidth);
    this.frontWall.changeWidthWall(newWidth);
    this.leftWall.position.x += offset / 2;
    this.rightWall.position.x -= offset / 2;
    this.floor.changeWidthWall(newWidth);
    this.ceiling.changeWidthWall(newWidth);
    var percent = newWidth / this.widthRoom;
    this.baseFloorTexture.repeat.x *= percent;
    this.widthRoom = newWidth;
  }
  changeDepthRoom(newDepth: number) {
    var offset = newDepth - this.depthRoom;
    this.leftWall.changeWidthWall(newDepth);
    this.rightWall.changeWidthWall(newDepth);
    this.backWall.position.z -= offset / 2;
    this.frontWall.position.z += offset / 2;
    this.floor.changeDepthFloor(newDepth);
    this.ceiling.changeDepthFloor(newDepth);
    var percent = newDepth / this.depthRoom;
    this.baseFloorTexture.repeat.y *= percent;
    this.depthRoom = newDepth;
  }
  addingInters(obj: Wall | DoorObject) {
    this.edit.addObjectsIntersected(obj);
  }
  addingPanelsObject(texture: string, textureWidth: number, textureHeight: number) {
    var wall = this.edit.getSelectedObject();
    if (wall && wall instanceof Wall) {
      if (wall.panels === null) {
        var panelsGroup = new PanelsGroup({
          wall,
          textureUrl: texture,
          textureWidth,
          textureHeight,
        });
        this.add(panelsGroup);
        panelsGroup.position.copy(wall.position);
        panelsGroup.rotation.copy(wall.rotation);
        wall.geometry.computeBoundingSphere();
        if (wall.geometry.boundingSphere?.center) {
          var posWallWorld = wall.localToWorld(wall.geometry.boundingSphere.center.clone());
          var direction = new THREE.Vector3(0, posWallWorld.y, 0).sub(posWallWorld).normalize();
          panelsGroup.position.add(direction.multiplyScalar(0.02));
        }
      } //
      else {
        wall.panels.changeTexturePanels(texture, textureWidth, textureHeight);
      }
    }
  }
  removeAllPanels() {
    this.leftWall.removePanels();
    this.rightWall.removePanels();
    this.backWall.removePanels();
    this.frontWall.removePanels();
    this.ceiling.removePanels();
  }
  private addingObject(params: ParamsWall): Wall {
    var material = new THREE.MeshBasicMaterial({
      map: this.baseWallTexture,
      // wireframe: true,
    });
    var wall = new Wall({ ...params, material });
    this.add(wall);
    this.addingInters(wall);
    return wall;
  }
}
