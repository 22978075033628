/* eslint-disable @typescript-eslint/ban-ts-comment,no-console */
import React, { useEffect, useRef, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Box } from '../../components/Box';
import { TextTitle } from '../../components/TextTitle';
import { ButtonStore } from '../../components/Blocks/BlockDownloadApp/components/ButtonStore';
import { CustomInput } from '../../components/CustomInput';
import { FIELDS, FormType, validationSchema } from './help/js';
import { PhoneInput } from '../../components/PhoneInput';
import { loadAsyncScript } from '../../components/CustomInput/utils/js';
import { CheckPrivatePolicy } from './components/CheckPrivatePolicy';
import { useOrderFormSubmitMutation } from '../../graphql/mutations/__generated__/orderFormSubmit';
import { LoadCurtain } from '../../components/LoadCurtain';
import { CodeDialog } from './components/CodeDialog';
import { useEmailVerificationCodeGetLazyQuery } from '../../graphql/queries/__generated__/emailVerificationCodeGet';
import { useEmailVerificationCodeCheckLazyQuery } from '../../graphql/queries/__generated__/emailVerificationCodeCheck';
import { apolloClient } from '../../api';
import { SuccessConfirmForm } from './components/SuccessConfirmForm';
import { AddImages } from './components/AddImages';
import { useDefineScrollForAnchor } from '../../hooks/useDefineScrollForAnchor';

const apiKey = process.env.REACT_APP_GMAP_API_KEY;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const TextTitleMedia = styled(TextTitle)`
  @media (max-height: 720px) {
    margin-bottom: 20px;
  }
`;
const WrapperBox = styled(Box)`
  @media (max-height: 720px) {
    padding: 0;
    height: 830px;
  }
`;
const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 418px 418px;
  grid-column-gap: 80px;
  grid-row-gap: 20px;
`;
export const FormPartner = () => {
  const [handleOrderSubmit, { loading }] = useOrderFormSubmitMutation();
  const [checkCodeEmail, { loading: loadCheckCode }] = useEmailVerificationCodeCheckLazyQuery();
  const [getEmailCode, { loading: loadEmailCode, data: dataEmailCode }] =
    useEmailVerificationCodeGetLazyQuery();
  const [loadScript, setLoadScript] = useState(false);
  const [isSuccessConfirmModal, setIsSuccessConfirmModal] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [filesUpload, setFilesUpload] = useState<Array<File>>([]);
  const isLoadMap = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  useDefineScrollForAnchor({
    childRef: ref,
    orderNumber: 6,
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormType>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      comment: '',
      companySite: '',
      address: '',
      companyAddress: '',
      phoneNumber: '',
      companyTaxNumber: '',
      companyName: '',
      fullName: '',
      isCheckPrivatePolicy: false,
    },
  });
  const submit = async () => {
    const values = getValues();
    (Object.keys(values) as [keyof FormType]).map(item => {
      if (values[item] === '' || item === 'isCheckPrivatePolicy') {
        delete values[item];
      }
    });
    if (filesUpload.length) {
      values.attachment = filesUpload[0];
    }
    try {
      const resp = await handleOrderSubmit({
        variables: {
          input: {
            ...values,
          },
        },
      });
      if (resp.data?.orderFormSubmit) {
        setIsSuccessConfirmModal(true);
      }
    } catch (err) {
      console.log('[ERROR submit]', err);
    }
  };
  const handlerVerificationEmailCode = async () => {
    try {
      const response = await getEmailCode({
        variables: {
          input: {
            email: getValues('email').trim(),
          },
        },
        fetchPolicy: 'network-only',
      });
      if (response.data?.emailVerificationCodeGet.success) {
        // console.log('response data', response.data);
        setIsSend(true);
      }
    } catch (err) {
      console.log('[ERROR handlerVerificationEmailCode]', err);
    }
  };
  const initMapScript = () => {
    // @ts-ignore
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };
  const getFields = () => {
    return FIELDS.map(item => {
      if (!item.component) {
        return (
          <Controller
            key={item.title}
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <CustomInput
                title={item.title + item.suffix}
                placeHolder={item.title}
                isRequired={item.isRequired}
                isGoogleSearch={item.isGoogleSearch}
                isLoadScript={loadScript}
                isTextArea={item?.isTextArea}
                inputProps={{
                  onChange,
                  onBlur,
                  value,
                }}
                textAreaProps={{
                  onChange,
                  onBlur,
                  value,
                }}
                error={errors[item.fieldFormName]?.message as string}
              />
            )}
            name={item.fieldFormName}
          />
        );
      } else if (item.component === 'phone') {
        return (
          <Controller
            key={item.title}
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <PhoneInput
                key={item.title}
                title="Номер телефона"
                isRequired
                value={value}
                setValue={onChange}
                inputProps={{ onBlur }}
                error={
                  (errors[item.fieldFormName]?.message as string) ||
                  (value
                    ? isValidPhoneNumber(value)
                      ? undefined
                      : 'Неверный формат телефона'
                    : undefined)
                }
              />
            )}
            name={item.fieldFormName}
          />
        );
      }
      return null;
    });
  };
  const handleResetForm = () => {
    apolloClient.clearStore().then(() => {
      setIsSend(false);
      setFilesUpload([]);
      reset();
    });
  };
  const onClickSuccessForm = () => {
    handleResetForm();
    setIsSuccessConfirmModal(false);
  };

  useEffect(() => {
    if (!isLoadMap.current) {
      isLoadMap.current = true;
      initMapScript().then(() => {
        setLoadScript(true);
      });
    }
  }, []);

  return (
    <>
      <WrapperBox ref={ref} width="100%" direction="column" padding="160px 0 60px 0">
        <TextTitleMedia text={['%заполните% форму']} />
        <Box margin="0 0 20px 0">
          <WrapperGrid>
            {getFields()}
            <AddImages filesUpload={filesUpload} setFilesUpload={setFilesUpload} />
          </WrapperGrid>
        </Box>
        {dataEmailCode?.emailVerificationCodeGet.success && isSend && (
          <CodeDialog
            email={getValues('email')}
            onResend={handlerVerificationEmailCode}
            checkCodeEmail={checkCodeEmail}
            submit={submit}
          />
        )}
        <ButtonStore
          delay={5}
          title={isSend ? 'Очистить' : 'Отправить'}
          onPress={
            isSend ? handleSubmit(handleResetForm) : handleSubmit(handlerVerificationEmailCode)
          }
          margin="20px 0 0 0"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CheckPrivatePolicy
              isCheck={value}
              handlerCheck={onChange}
              error={errors.isCheckPrivatePolicy?.message as string}
            />
          )}
          name="isCheckPrivatePolicy"
        />
        {isSuccessConfirmModal && <SuccessConfirmForm handlerPressText={onClickSuccessForm} />}
      </WrapperBox>
      {(loading || loadEmailCode || loadCheckCode) && <LoadCurtain />}
    </>
  );
};
