import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockApp } from '../../components/Blocks/BlockApp';

export const Application: FC = () => {
  return (
    <WrapperPage>
      <BlockApp />
    </WrapperPage>
  );
};
