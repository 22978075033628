import { IMAGE_URLS } from '../../../components/PreloadImages/helpers/constans';

export const ImagesPanel1 = [
  IMAGE_URLS.dec_1,
  IMAGE_URLS.dec_2,
  IMAGE_URLS.dec_3,
  IMAGE_URLS.dec_4,
];
export const ImagesPanel2 = [
  IMAGE_URLS.dec_5,
  IMAGE_URLS.dec_6,
  IMAGE_URLS.dec_7,
  IMAGE_URLS.dec_8,
];
