import React, { FC, PropsWithChildren } from 'react';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';
import { Text } from '../../../Texts/Text';
import styled from 'styled-components';

interface Props {
  text: string;
}
const TextMedia = styled(Text)`
  @media (max-height: 890px) or (max-width: 1320px) {
    font-size: 20px;
  }
`;
const BoxMedia = styled(Box)`
  @media (max-height: 890px) or (max-width: 1320px) {
    width: 70px;
    height: 70px;
  }
`;
const Wrapper = styled(Box)`
  @media (max-height: 890px) or (max-width: 1320px) {
    height: 86px;
  }
`;
export const ItemBlockIcons: FC<PropsWithChildren<Props>> = ({ text, children }) => {
  return (
    <Wrapper width="490px" height="100px" justify="flex-start">
      <BoxMedia
        width="70px"
        height="70px"
        background={theme.colors.softBlueViolet}
        borderRadius="50%"
        padding="16px"
        margin="0 30px 0 0"
      >
        {children}
      </BoxMedia>
      <TextMedia fontFamily="Gilroy" weight={500} size="20px">
        {text}
      </TextMedia>
    </Wrapper>
  );
};
