import React, { FC } from 'react';
import { theme } from '../../themes/theme';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockExamples } from '../../components/Blocks/BlockExemples';

export const Products: FC = () => {
  return (
    <WrapperPage backgroundColor={theme.colors.lightGray}>
      <BlockExamples padding="0" />
    </WrapperPage>
  );
};
