import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box } from '../../../Box';
import { Text } from '../../../Texts/Text';
import {Details} from "../../../../pages/Home/components/Details";

const slide = keyframes`
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const BlockTextStyle = styled(Box)`
  position: absolute;
  animation: ${slide} 2s linear;
  top: 32%;
  z-index: 2;
`;
const TextShadow = styled(Text)`
  transition: all 3s ease;
`;

export const BlocKTexts = () => {
  return (
    <BlockTextStyle width="792px" direction="column">
      <TextShadow
        fontFamily="Neue Machina"
        size="30px"
        weight={300}
        color="white"
        lineHeight="32px"
      >
        {'ремонт по технологии'.toUpperCase()}
      </TextShadow>
      <TextShadow
        fontFamily="Neue Machina"
        size="110px"
        weight={500}
        color="white"
        lineHeight="115px"
      >
        {'smart 3d'.toUpperCase()}
      </TextShadow>
    </BlockTextStyle>
  );
};
