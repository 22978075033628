import React, { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/theme';
import { Box } from '../../../components/Box';
import { LogoIcon } from '../../../components/Header/components/LogoIcon';

const WrapperImage = styled.div`
  width: 1181px;
  height: 262px;
`;
const WrapperBox = styled(Box).withConfig({
  shouldForwardProp: prop => !['scale'].includes(prop),
})`
  transform: scale(1, 1);
`;

export const LogoBox: FC = () => {
  return (
    <WrapperBox>
      <WrapperImage>
        <LogoIcon color={theme.colors.black} />
      </WrapperImage>
    </WrapperBox>
  );
};
