import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../../Box';
import { Icon, IconNamesType } from '../../../Icons/Icon';
import { theme } from '../../../../themes/theme';
import { TextMultiColor } from '../../../TextMultiColor';
import { ButtonSimple } from '../../../../pages/Partner/components/ButtonSimple';
import { Text } from '../../../Texts/Text';

const BoxText = styled(Box)`
  transition: all 300ms ease-in-out;
  z-index: 2;
`;
const WrapperText = styled.div`
  margin-bottom: 60px;
`;
const TextCustom = styled(Text)`
  z-index: 2;
`;
const WrapperIcon = styled.div`
  margin-left: -40px;
`;

interface Props {
  icon: IconNamesType;
  text: string[];
  title: string;
  changeState(): void;
  isShow: boolean;
  isRight?: boolean;
}
export const StartTextComponent: FC<Props> = ({
  icon,
  text,
  title,
  isShow,
  changeState,
  isRight,
}) => {
  const boxText = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (boxText.current) {
      if (isShow) {
        boxText.current.style.transform = 'translateX(590px)';
        boxText.current.style.opacity = '0';
      } else {
        boxText.current.style.transform = 'translateX(0px)';
        boxText.current.style.opacity = '1';
      }
    }
  }, [isShow]);

  return (
    <BoxText
      ref={boxText}
      height="630px"
      width="1180px"
      direction="column"
      align="flex-start"
      padding={isRight ? '0 0 0 680px' : '0'}
    >
      <Box align="flex-end" margin="0 0 25px 0">
        <TextCustom
          fontFamily="Neue Machina"
          weight="700"
          size="50px"
          lineHeight="32px"
          marginBottom="20px"
        >
          {title.toUpperCase()}
        </TextCustom>
        <WrapperIcon>
          <Icon name={icon} fill={theme.colors.softBlueViolet_50} />
        </WrapperIcon>
      </Box>
      <WrapperText>
        <TextMultiColor
          text={text}
          fontFamily="Gilroy"
          fontSize="20px"
          fontWeight="500"
          lineHeight="23px"
        />
      </WrapperText>
      <ButtonSimple clickButton={changeState} title="подробнее" />
    </BoxText>
  );
};
