import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Box, PropsStyleBox } from '../../../Box';

const BoxImageStyle = styled(Box)`
  min-width: calc(100vh * 3);
  height: 100vh;
  position: relative;
`;

export const BoxImage: FC<PropsWithChildren<PropsStyleBox>> = props => {
  return <BoxImageStyle {...props} />;
};
