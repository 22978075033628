/* eslint-disable no-var */
import React, { useEffect, useRef } from 'react';
import { Box } from '../../Box';
import { CreateScene } from '../../../helpers/classes';
import { addRotateObject, createLightScene, getClipObject } from './js';
import styled from 'styled-components';
import { TextTitle } from '../../TextTitle';
import { theme } from '../../../themes/theme';

type CoordinateType = { x: number; y: number; z: number };
const Scene = new CreateScene();
const ContCanvas = styled(Box)`
  pointer-events: none;
`;
const BoxCanvas = styled(Box)`
  @media (max-height: 880px) {
    height: 420px;
  }
  @media (max-width: 1440px) {
    height: 420px;
  }
`;
const BoxWrapper = styled(Box)`
  @media (max-height: 880px) {
    justify-content: flex-start;
    padding-top: 100px;
  }
`;
export const BlockShow3D = () => {
  const container = useRef<HTMLDivElement>(null);
  const subContainer = useRef<HTMLDivElement>(null);
  const canvasContainer = useRef<HTMLCanvasElement>();
  const coordinate = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const curAngle = useRef<CoordinateType>({ x: 0, y: 0, z: 0 });
  const timer = useRef<NodeJS.Timer | null>(null);
  const isDown = useRef(false);

  const createAndStartScene = () => {
    if (subContainer.current && !Scene.isInit) {
      canvasContainer.current = Scene.initScene({
        container: subContainer.current,
        cameraPosition: { x: 0, y: 1.4, z: 3.4 },
        cameraLookAt: { x: 0, y: -0.38, z: 0 },
        clearSceneColor: '#ffffff',
      });
      createLightScene(Scene.scene);
      getClipObject(Scene.scene).then(mesh => {
        Scene.objectAnimate = mesh;
        Scene.functionAnimate = () => addRotateObject(mesh);
        Scene.startAnimation();
      });
    } else if (subContainer.current && Scene.isInit) {
      canvasContainer.current = Scene.initScene({
        container: subContainer.current,
        cameraPosition: { x: 0, y: 1.4, z: 3.4 },
        cameraLookAt: { x: 0, y: -0.38, z: 0 },
        clearSceneColor: '#ffffff',
      });
      Scene.startAnimation();
    }
  };

  function handleMouseOver() {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }
  function handleMouseOut() {
    timer.current = setTimeout(() => {
      Scene.functionAnimate = () => addRotateObject(Scene.objectAnimate);
    }, 5000);
  }
  function handleMouseDown(evt: MouseEvent) {
    isDown.current = true;
    coordinate.current = { x: evt.clientX, y: evt.clientY };
    attenuationEnd();
  }
  function handleMouseUp() {
    isDown.current = false;
    curAngle.current = {
      x: Scene.objectAnimate?.rotation.x || 0,
      y: Scene.objectAnimate?.rotation.y || 0,
      z: Scene.objectAnimate?.rotation.z || 0,
    };
  }
  function handleMouseMove(evt: MouseEvent) {
    if (isDown.current) {
      if (Scene.objectAnimate?.rotation && !Scene.functionAnimate) {
        Scene.objectAnimate.rotation.y =
          curAngle.current.y + (evt.clientX - coordinate.current.x) / 600;
        Scene.objectAnimate.rotation.x =
          curAngle.current.x - (evt.clientY - coordinate.current.y) / -600;
      }
    }
  }
  function attenuationEnd() {
    Scene.functionAnimate = undefined;
    curAngle.current = {
      x: Scene.objectAnimate?.rotation.x || 0,
      y: Scene.objectAnimate?.rotation.y || 0,
      z: Scene.objectAnimate?.rotation.z || 0,
    };
  }

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('mouseover', handleMouseOver);
      container.current.addEventListener('mousedown', handleMouseDown);
      container.current.addEventListener('mouseout', handleMouseOut);
      container.current.addEventListener('mouseup', handleMouseUp);
      container.current.addEventListener('mousemove', handleMouseMove);
    }
    createAndStartScene();
    return () => {
      Scene.stopAnimation();
      if (container.current) {
        container.current.removeEventListener('mouseover', handleMouseOver);
        container.current.removeEventListener('mousedown', handleMouseDown);
        container.current.removeEventListener('mouseout', handleMouseOut);
        container.current.removeEventListener('mouseup', handleMouseUp);
        container.current.removeEventListener('mousemove', handleMouseMove);
      }
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, []);

  return (
    <BoxWrapper ref={container} direction="column" width="100%" height="100%">
      <TextTitle
        text={['Клипсы для монтажа %3d decor%']}
        textAlign="center"
        textProps={{ marginBottom: '20px' }}
      />
      <BoxCanvas width="100%" height="700px" background={theme.colors.white} margin="20px 0 0 0">
        <ContCanvas ref={subContainer} width="700px" height="100%" />
      </BoxCanvas>
    </BoxWrapper>
  );
};
