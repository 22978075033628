import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/theme';

const BoxHeaderStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 11;
  padding-bottom: 30px;
  transform: translateY(-100%);
  user-select: none;
  background-color: ${theme.colors.white};
`;
const BoxDummy = styled.div`
  width: 100%;
  height: 100px;
  background-color: inherit;
  position: absolute;
  left: 0;
  top: -100px;
`;

interface Props {
  isHeaderVisible: boolean;
  closeMenu: () => void;
}

export const BoxHeader: FC<PropsWithChildren<Props>> = ({
  children,
  isHeaderVisible,
  closeMenu,
}) => {
  const element = useRef<HTMLDivElement>(null);
  const isOpenMenu = useRef(false);

  const handleOpenAnimate = (val: '0' | '1') => {
    const trans = val === '1' ? '0' : '-103%';
    if (element.current) {
      element.current.animate([{ transform: `translateY(${trans})` }], {
        duration: 500,
        easing: 'cubic-bezier(.47,.31,.23,1.38)',
      }).onfinish = () => {
        isOpenMenu.current = val === '1';
        if (element.current) {
          element.current.style.transform = `translateY(${trans})`;
          if (val === '0') {
            element.current.style.boxShadow = 'none';
          } else {
            element.current.style.boxShadow = '0 0 20px 0 rgba(0, 0, 0, 0.65)';
          }
        }
      };
    }
  };

  function handleClickEvent(e: MouseEvent) {
    if (isOpenMenu.current) {
      if (element.current) {
        const elements = e.composedPath();
        if (!elements.includes(element.current)) {
          closeMenu();
        }
      }
    }
  }

  useEffect(() => {
    if (isHeaderVisible) {
      handleOpenAnimate('1');
    } else {
      handleOpenAnimate('0');
    }
  }, [isHeaderVisible]);
  useEffect(() => {
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  return (
    <BoxHeaderStyle ref={element} id="wrapperHeader">
      <BoxDummy />
      {children}
    </BoxHeaderStyle>
  );
};
