import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { theme } from '../../../themes/theme';
import { Text } from '../../../components/Texts/Text';
import { SpanColor } from '../../../components/SpanColor';
import { InputError } from '../../../components/InputError';

const Wrapper = styled(Box)`
  position: relative;
`;
const CheckerStyle = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.colors.softBlueViolet};
  margin-right: 24px;
`;

interface Props {
  isCheck?: boolean;
  error?: string;
  handlerCheck: (val: boolean) => void;
}
export const CheckPrivatePolicy: FC<Props> = ({ error, isCheck, handlerCheck }) => {
  return (
    <Wrapper width="fit-content" margin="20px" onClick={() => handlerCheck(!isCheck)}>
      <CheckerStyle>
        {isCheck && (
          <Box
            width="10px"
            height="10px"
            borderRadius="3px"
            background={theme.colors.softBlueViolet}
          />
        )}
      </CheckerStyle>
      <Text fontFamily="Gilroy" weight={400} size="16px">
        {'Я согласен с '}
        <SpanColor>Политикой конфиденциальности</SpanColor>
      </Text>
      <InputError error={error} left="0" bottom="-24px" />
    </Wrapper>
  );
};
