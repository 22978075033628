import { Load } from '../pages/Constructor/canvas/work/load';
import createEdit from '../pages/Constructor/canvas/work/edit';
import { KTX2Loader } from '../components/Blocks/BlockPresent/SliderAnimate/constructor/KTX2Loader';
import { PatchesName } from '../__generated__/types';

export type BlockType =
  | 'home'
  | 'technology'
  | 'developments'
  | 'decor3d'
  | 'products'
  | 'installation'
  | 'photo'
  | 'videoFuture'
  | 'clip'
  | 'videoInstall'
  | 'examples'
  | 'mobileApp'
  | 'constructor'
  | 'downloadApp'
  | 'steps'
  | 'advantages'
  | 'linkToForm'
  | 'questions'
  | 'blockFooterStart'
  | 'blockFooterEnd'
  | 'news';
export type MenuItemType = {
  text: string;
  scrollTo: number;
  path?: PatchesName;
};
type MenuItemsType = {
  [key in BlockType]: MenuItemType;
};
export type QuestionDataType = {
  question: string;
  answer: string;
};
export const MenuItemsHeader: MenuItemsType = {
  home: { text: 'Главная', scrollTo: 0 },
  technology: { text: 'О технологии', scrollTo: 0 },
  developments: { text: 'Разработки', scrollTo: 0 },
  decor3d: { text: '3D Decor', scrollTo: 0 },
  clip: { text: '3D Клипсы', scrollTo: 0 },
  videoFuture: { text: 'Видео презентация', scrollTo: 0 },
  examples: { text: '3D Ремонт в интерьере', scrollTo: 0 },
  mobileApp: { text: 'Приложение', scrollTo: 0 },
  steps: { text: 'Шаги', scrollTo: 0 },
  advantages: { text: 'Преимущества', scrollTo: 0 },
  products: { text: '', scrollTo: 0 },
  installation: { text: '', scrollTo: 0 },
  photo: { text: '', scrollTo: 0 },
  videoInstall: { text: '', scrollTo: 0 },
  constructor: { text: '', scrollTo: 0 },
  downloadApp: { text: '', scrollTo: 0 },
  linkToForm: { text: '', scrollTo: 0 },
  questions: { text: '', scrollTo: 0 },
  news: { text: '', scrollTo: 0 },
  blockFooterStart: { text: '', scrollTo: 0 },
  blockFooterEnd: { text: '', scrollTo: 0 },
};
export const MenuItemsHeaderDefault: MenuItemType[] = [
  { text: 'Главная', scrollTo: 0, path: PatchesName.Home },
  { text: '3D Decor', scrollTo: 4, path: PatchesName.Decor3d },
  { text: 'Платформа', scrollTo: 8, path: PatchesName.App },
  { text: 'О технологии', scrollTo: 1, path: PatchesName.Technology },
  { text: '3D Клипсы', scrollTo: 5, path: PatchesName.Clip3d },
  { text: 'Шаги', scrollTo: 9, path: PatchesName.Steps },
  { text: 'Видео 3D ремонт', scrollTo: 2, path: PatchesName.VideoExample },
  { text: 'Видео презентация', scrollTo: 6, path: PatchesName.VideoPresent },
  { text: 'Преимущества', scrollTo: 10, path: PatchesName.Advantages },
  { text: 'Разработки', scrollTo: 3, path: PatchesName.Developments },
  { text: '3D Ремонт в интерьере', scrollTo: 7, path: PatchesName.Products },
  { text: 'Контакты', scrollTo: 12, path: PatchesName.Contacts },
];
export const MenuItemsHeaderDop: MenuItemType[] = [
  { text: '3D Конструктор', scrollTo: 0, path: PatchesName.Constructor },
  { text: 'Сотрудничество и услуги', scrollTo: 0, path: PatchesName.FormPartner },
  { text: 'Вопросы', scrollTo: 11, path: PatchesName.Questions },
];
export const MenuItemsHeaderAnchor: MenuItemType[] = [
  { text: 'О партнёрстве', scrollTo: 0 },
  { text: 'B2C', scrollTo: 0 },
  { text: 'B2B', scrollTo: 0 },
  { text: 'B2G', scrollTo: 0 },
  { text: 'фото', scrollTo: 0 },
  { text: 'шаги', scrollTo: 0 },
  { text: 'заявка', scrollTo: 0 },
];
export function setParamElement(element: HTMLDivElement, block: BlockType) {
  if (element) {
    window.scrollTo({
      top: 0,
    });
    const box = element.getBoundingClientRect();
    const offset = box.y - window.innerHeight;
    // if (block === 'mobileApp') {
    //   console.log(box.height + offset);
    // }
    MenuItemsHeader[block].scrollTo = box.height + offset;
  }
}
/*const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1190px',
  laptopL: '1440px',
  desktop: '2560px',
};
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
}*/
export const HOST = 'https://smart3ddecor.com';
export const GRAPHQL_API = `${HOST}/graphql`;
export const LOAD_CLASS_OBJECT = new Load();
export const EDIT_CLASS_OBJECT = createEdit({
  camera: LOAD_CLASS_OBJECT.camera,
});
export const KTX2_LOADER = new KTX2Loader();
export const DOOR_HEIGHT = 2;
export const DOOR_WIDTH = 0.8;

// 1368 x 768
