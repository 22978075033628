import { FC, useEffect } from 'react';
import { IMAGE_URLS } from './helpers/constans';
export const PreloadImages: FC = () => {
  useEffect(() => {
    for (const imageURLSKey in IMAGE_URLS) {
      const img = new Image();
      const key = imageURLSKey as keyof typeof IMAGE_URLS;
      img.src = IMAGE_URLS[key];
    }
  }, [IMAGE_URLS]);

  return null;
};
