import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from '../pages/Home';
import { PatchesName } from '../__generated__/types';
import { Constructor } from '../pages/Constructor';
import { Technology } from '../pages/Technology';
import { DevelopmentsCompany } from '../pages/DevelopmentsCompany';
import { Decor3d } from '../pages/Decor3d';
import { Clip3d } from '../pages/Clip3d';
import { SomethingWrong } from '../pages/SomethingWrong';
import { VideoExample, VideoPresent } from '../pages/VideoPresent';
import { Products } from '../pages/Products';
import { Application } from '../pages/Application';
import { Steps } from '../pages/Steps';
import { Advantages } from '../pages/Advantages';
import { Contacts } from '../pages/Contacts';
import { Questions } from '../pages/Questions';
import { PreloadImages } from '../components/PreloadImages';
import { Partner } from '../pages/Partner';

export const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={PatchesName.Home} />} />
        <Route path={PatchesName.Home} element={<Home />} />
        <Route path={PatchesName.Technology} element={<Technology />} />
        <Route path={PatchesName.Developments} element={<DevelopmentsCompany />} />
        <Route path={PatchesName.Decor3d} element={<Decor3d />} />
        <Route path={PatchesName.Clip3d} element={<Clip3d />} />
        <Route path={PatchesName.VideoPresent} element={<VideoPresent />} />
        <Route path={PatchesName.VideoExample} element={<VideoExample />} />
        <Route path={PatchesName.Products} element={<Products />} />
        <Route path={PatchesName.App} element={<Application />} />
        <Route path={PatchesName.Steps} element={<Steps />} />
        <Route path={PatchesName.Advantages} element={<Advantages />} />
        <Route path={PatchesName.Contacts} element={<Contacts />} />
        <Route path={PatchesName.FormPartner} element={<Partner />} />
        <Route path={PatchesName.Constructor} element={<Constructor />} />
        <Route path={PatchesName.Questions} element={<Questions />} />
        <Route path="*" element={<SomethingWrong />} />
      </Routes>
      <PreloadImages />
    </>
  );
};
