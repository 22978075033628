import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { LoadIndicator } from '../LoadIndicator';

const WrapperBox = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
`;

export const LoadCurtain = () => {
  return (
    <WrapperBox width="100vw" height="100vh" background={'rgba(0,0,0,0.76)'}>
      <LoadIndicator size={100} />
    </WrapperBox>
  );
};
