import * as THREE from 'three';

type Position = {
  x: number;
  y: number;
  z: number;
};
interface PropsInit {
  container: HTMLDivElement;
  cameraPosition: Position;
  cameraLookAt: Position;
  cameraFar?: number;
  clearSceneColor?: string;
}
export class CreateScene {
  isAnimation = false;
  functionAnimate?: () => void;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  widthCanvas = 600;
  heightCanvas = 600;
  objectAnimate: THREE.Mesh | null = null;
  isInit = false;
  constructor() {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.camera = new THREE.PerspectiveCamera();
    this.scene.add(this.camera);
  }

  initScene(props: PropsInit): HTMLCanvasElement {
    const { container, clearSceneColor, cameraPosition, cameraLookAt, cameraFar } = props;
    this.isInit = true;
    const box = container.getBoundingClientRect();
    this.widthCanvas = box.width;
    this.heightCanvas = box.height;

    this.camera.aspect = box.width / box.height;
    this.camera.fov = 45;
    this.camera.near = 0.1;
    this.camera.far = cameraFar || 2000;
    this.camera.position.copy(
      new THREE.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z)
    );
    this.camera.lookAt(new THREE.Vector3(cameraLookAt.x, cameraLookAt.y, cameraLookAt.z));
    this.camera.updateProjectionMatrix();

    this.renderer.setClearColor(clearSceneColor || '#2a2a2a');
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // this.renderer.outputEncoding = THREE.LinearEncoding;

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.widthCanvas, this.heightCanvas);
    this.renderer.domElement.setAttribute('id', 'canvas_scheme_constructor');
    this.renderer.domElement.style.pointerEvents = 'none';

    container.innerHTML = '';
    container.appendChild(this.renderer.domElement);
    return this.renderer.domElement;
  }
  startAnimation() {
    if (!this.isAnimation) {
      this.isAnimation = true;
      // const axesHelper = new THREE.AxesHelper(5);
      // this.scene.add(axesHelper);

      // const geometry = new THREE.BoxGeometry(100, 100, 100);
      // const material = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
      // const cube = new THREE.Mesh(geometry, material);
      // this.scene.add(cube);
      this.animate(this.camera, this.scene, this.renderer);
    }
  }
  stopAnimation() {
    if (this.isAnimation) {
      this.isAnimation = false;
    }
  }
  animate(camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer) {
    if (this.isAnimation) {
      requestAnimationFrame(() => this.animate(camera, scene, renderer));
      renderer.render(scene, camera);
      if (this.functionAnimate) {
        this.functionAnimate();
      }
    }
  }
}
