import React, { FC } from 'react';
import { Box } from '../../components/Box';
import { WrapperPage } from '../../components/WrapperPage';
import styled from 'styled-components';
import { ContainerItem } from './components/ContainerItem';
import { TextTitle } from '../../components/TextTitle';
import { IMAGE_URLS } from '../../components/PreloadImages/helpers/constans';

export const WrapperBox = styled(Box)`
  max-width: 1180px;
  @media (max-height: 720px) {
    max-width: 1100px;
  }
`;
export const WrapperGap = styled(Box)`
  gap: 150px;
  @media (max-height: 800px) {
    padding-top: 80px;
    gap: 110px;
    justify-content: flex-start;
  }
  @media (max-height: 720px) {
    padding-top: 60px;
  }
`;
export const DevelopmentsCompany: FC = () => {
  return (
    <WrapperPage>
      <WrapperGap width="100%" height="100%" direction="column">
        <TextTitle
          text={['%разработки% компании']}
          textAlign="left"
          textProps={{ marginBottom: '0' }}
        />
        <WrapperBox width="82%" justify="space-between">
          <ContainerItem
            srcPhone={IMAGE_URLS.phone_1}
            offsetImagePhone="-72px"
            title="Цифровая платформа"
            srcImage={IMAGE_URLS.img_1}
            text={[
              '%Заменяет% множество',
              'специалистов, объединяя их',
              'функции в одном интерфейсе.',
              '%Воплощает% Ваши фантазии о',
              'дизайне. Напрямую',
              '%соединяет% заводом.',
            ]}
          />
          <ContainerItem
            srcPhone={IMAGE_URLS.phone_2}
            offsetImagePhone="-120px"
            title="Отделочный материал"
            srcImage={IMAGE_URLS.img_2}
            text={[
              'Инновационное ',
              'изобретение %3D Decor%,',
              'первое в своём роде!',
              'Идеально сочетает',
              'практичность, красоту и',
              'надёжность.',
            ]}
          />
          <ContainerItem
            srcPhone={IMAGE_URLS.phone_3}
            offsetImagePhone="-40px"
            title="Магазин 3D&nbsp;декора"
            srcImage={IMAGE_URLS.img_3}
            text={[
              'Открывает возможность',
              'проверить %миллионы%',
              '%вариантов дизайна% от',
              'компании или %создать свой%',
              '%собственный%, уникальный',
              'вариант.',
            ]}
          />
        </WrapperBox>
      </WrapperGap>
    </WrapperPage>
  );
};
