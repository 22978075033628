import React, { FC } from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.div`
  width: 90px;
  height: 90px;
  margin-right: -45px;
  cursor: pointer;
  z-index: 2;
`;
const Path = styled.path`
  transition: fill 700ms ease;
  fill: ${({ theme }) => theme.colors.white};
  &:hover {
    fill: ${({ theme }) => theme.colors.softBlueViolet};
  }
`;
interface Props {
  handleClick(): void;
}
export const ButtonPlay: FC<Props> = ({ handleClick }) => {
  return (
    <ButtonStyle onClick={handleClick}>
      <svg width="63" height="72" viewBox="0 0 63 72" xmlns="http://www.w3.org/2000/svg">
        <Path d="M60.5 31.6699C63.8333 33.5944 63.8333 38.4056 60.5 40.3301L8 70.641C4.66666 72.5655 0.500004 70.1599 0.500004 66.3109L0.500007 5.6891C0.500007 1.8401 4.66668 -0.565517 8.00001 1.35898L60.5 31.6699Z" />
      </svg>
    </ButtonStyle>
  );
};
