import React, { CSSProperties, FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Logo } from '../Header';
import { useLocation } from 'react-router-dom';
import { Icon } from '../Icons/Icon';
import { theme } from '../../themes/theme';
import { PatchesName } from '../../__generated__/types';
import { MenuItemsHeaderAnchor } from '../../helpers/constans';
import { Text } from '../Texts/Text';

const HeaderLogoStyle = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  z-index: 10;
  transition: all 400ms ease-out;
  //background-color: rgba(0, 0, 0, 0.16);
  @media (max-height: 890px) {
    padding: 10px 60px;
  }
`;
interface HeaderLogoProps {
  borderColor: CSSProperties['borderColor'];
}
const ContainerIcon = styled.div`
  width: 70px;
  height: 70px;
  padding: 24px;
  cursor: pointer;
  position: relative;
`;
const ContainerAnchor = styled.div`
  height: 70px;
  align-items: center;
  display: flex;
  gap: 60px;
`;
const ContainerItemAnchor = styled.div`
  cursor: pointer;
`;
const CircleOpacity = styled.div.withConfig({
  shouldForwardProp: prop => !['borderColor'].includes(prop),
})<HeaderLogoProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: opacity 300ms linear;
  border: solid 1px ${props => props.borderColor};
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

interface Props {
  openHeaderMenu(): void;
}

export const HeaderLogo: FC<Props> = ({ openHeaderMenu }) => {
  const pathname = useLocation().pathname;
  const isPartner = pathname.includes(PatchesName.FormPartner);
  const container = useRef<HTMLDivElement>(null);
  const scroll_to = window.innerHeight - 130;

  const getColorLogo = () => {
    if (pathname.includes(PatchesName.Home) || pathname.includes(PatchesName.FormPartner)) {
      return theme.colors.white;
    }
    return theme.colors.black;
  };
  const handleScroll = () => {
    if (isPartner && container.current) {
      const { scrollTop } = document.documentElement;
      if (scrollTop > scroll_to) {
        container.current.style.padding = '0 60px';
        container.current.style.backgroundColor = 'rgba(0,0,0,0.44)';
      } else {
        container.current.style.padding = '30px 60px';
        container.current.style.backgroundColor = 'transparent';
      }
    }
  };
  const handleClickAnchor = (_scroll: number) => {
    // console.log(MenuItemsHeaderAnchor)
    window.scrollTo({
      top: _scroll,
      behavior: 'smooth',
    });
  };
  const getAnchorMenuItems = () => {
    if (isPartner) {
      return (
        <ContainerAnchor>
          {MenuItemsHeaderAnchor.map(anchor => (
            <ContainerItemAnchor
              key={anchor.text}
              onClick={() => handleClickAnchor(anchor.scrollTo)}
            >
              <Text size="16px" weight="400" fontFamily="Gilroy" color="white">
                {anchor.text.toUpperCase()}
              </Text>
            </ContainerItemAnchor>
          ))}
        </ContainerAnchor>
      );
    }
    return null;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderLogoStyle ref={container}>
      <Logo color={getColorLogo()} />
      {getAnchorMenuItems()}
      <ContainerIcon onClick={openHeaderMenu}>
        <CircleOpacity borderColor={getColorLogo()} />
        <Icon name="menuIcon" width="100%" height="100%" fill={getColorLogo()} />
      </ContainerIcon>
    </HeaderLogoStyle>
  );
};
