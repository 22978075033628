import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';
import styled from 'styled-components';
import { Text } from '../../../Texts/Text';
import { MODE } from '../BlockQuestions';

const TabItemStyle = styled(Box)`
  cursor: pointer;
  z-index: 1;
`;
const BoxAnim = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 218px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.softBlueViolet};
  transition: all 300ms ease-in-out;
`;
const TABS = ['О продукции', 'Юридические гарантии', 'Оплата', 'Доставка'];
interface Props {
  currentMode: number;
  changeMode: Dispatch<SetStateAction<number>>;
}
export const TabComponent: FC<Props> = ({ currentMode, changeMode }) => {
  const boxAnim = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const rects = useRef<Array<number>>([]);
  const handleClickTab = (mode: number) => {
    changeMode(mode);
  };

  useEffect(() => {
    if (boxAnim.current && rects.current.length) {
      let transX;
      let width;
      switch (currentMode) {
        case MODE.Products:
          transX = 0;
          width = rects.current[0];
          break;
        case MODE.Low:
          transX = rects.current[0];
          width = rects.current[1];
          break;
        case MODE.Pay:
          transX = rects.current[0] + rects.current[1];
          width = rects.current[2];
          break;
        case MODE.Delivery:
          transX = rects.current[0] + rects.current[1] + rects.current[2];
          width = rects.current[3];
          break;
        default:
          transX = 0;
          width = rects.current[0];
      }
      boxAnim.current.style.transform = `translateX(${transX}px)`;
      boxAnim.current.style.width = `${width}px`;
    }
  }, [currentMode]);
  useEffect(() => {
    setTimeout(() => {
      if (rects.current.length >= 4) return;
      if (container.current) {
        for (let i = 1; i < container.current.children.length; i++) {
          const w = container.current.children[i].getBoundingClientRect().width;
          rects.current.push(w);
        }
      }
    }, 1000);
  }, []);

  return (
    <Box
      ref={container}
      position="relative"
      height="40px"
      borderRadius="20px"
      background={theme.colors.lightGray}
    >
      <BoxAnim ref={boxAnim} />
      {TABS.map((item, index) => (
        <TabItemStyle key={item} onClick={() => handleClickTab(index)} padding="0 50px">
          <Text
            fontFamily="Gilroy"
            weight={500}
            size="20px"
            color={currentMode === index ? 'white' : 'black'}
          >
            {item}
          </Text>
        </TabItemStyle>
      ))}
    </Box>
  );
};
