import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, PropsStyleBox } from '../../../../../components/Box';
import { Text } from '../../../../../components/Texts/Text';
import { theme } from '../../../../../themes/theme';
import { Icon } from '../../../../../components/Icons/Icon';

const WrapperButton = styled(Box)`
  cursor: pointer;
`;

interface Props extends PropsStyleBox {
  title: string;
  currentValue: number;
  changeSizeRoom(val: number): void;
}

export const ItemMeasure: FC<Props> = ({ title, currentValue, changeSizeRoom, ...props }) => {
  // const [size, setSize] = useState(currentValue);
  const changeSize = (divider: number) => {
    const _size: number = currentValue + 0.1 * divider;
    changeSizeRoom(_size);
  };
  const getPrice = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumIntegerDigits: 3,
    minimumFractionDigits: 3,
  });
  return (
    <Box width="100%" justify="space-between" {...props}>
      <Text fontFamily="Neue Machina" size="18px" weight={700}>
        {title}
      </Text>
      <Box>
        <WrapperButton
          width="30px"
          height="30px"
          background={theme.colors.softBlueViolet_100}
          borderRadius="15px"
          onClick={() => changeSize(-1)}
        >
          <Icon name="minus" width="100%" height="100%" />
        </WrapperButton>
        <WrapperButton
          width="140px"
          height="50px"
          borderRadius="25px"
          background={theme.colors.lightGray}
          margin="0 10px"
        >
          {getPrice.format(currentValue).replace('.', ' ')}
        </WrapperButton>
        <Box
          width="30px"
          height="30px"
          background={theme.colors.softBlueViolet_100}
          borderRadius="15px"
          padding="9px"
          onClick={() => changeSize(1)}
        >
          <Icon name="plus" width="100%" height="100%" />
        </Box>
      </Box>
    </Box>
  );
};
