export const TEXTS_INFO_BLOCKS = {
  b2b: [
    'Создание уникальной атмосферы для вашего бизнеса',
    'Компания Smart 3D Corporation предлагает решения для облицовки стен в различных бизнес-сферах. Привлеките новых клиентов и приятно удивите постоянных.',
    'Миллионы готовых 3D изображений: На нашей платформе вы найдете множество вариантов, которые создадут подходящую атмосферу для вашего бизнеса.',
    'Искусственный Интеллект: Создавайте уникальные изображения, выделяющие вас среди конкурентов.',
    'Персонализированный дизайн: Наши дизайнеры помогут разместить ваш логотип или название бренда на стенах с подходящим фоновым изображением.',
    '"3D Decor" – придайте вашему бизнесу атмосферу, которая привлекает и удерживает клиентов. Наши 3D технологии создадут эффект глубины и объемности, делая ваш интерьер не только красивым, но и запоминающимся для ваших посетителей.',
  ],
  b2c: [
    'Превратите свой дом в произведение искусства',
    'Smart 3D Corporation предлагает инновационные решения для облицовки стен в жилых домах и квартирах. Создайте уникальный интерьер, который отражает вашу личность.',
    'Миллионы 3D изображений:\nНа нашей платформе вас ждет огромный выбор изображений на любой вкус.',
    'Искусственный Интеллект:\nГенерируйте уникальные дизайны, которые будут единственными в мире.',
    'Личные изображения: \nЗагрузите свои фотографии или изображения для создания индивидуального декора.',
    '"3D Decor" – идеальное дизайнерское решение для вашего жилья. Наши 3D технологии добавят вашему дому уюта и стиля, создадут уютную атмосферу и помогут воплотить в жизнь ваши самые смелые дизайнерские идеи.',
  ],
  b2g: [
    'Современные решения для общественных зданий',
    'Компания Smart 3D Corporation предлагает услуги для облицовки стен в общественных зданиях. Театры, музеи, школы, спортивные комплексы, детсады и другие государственные учреждения найдут у нас современные решения для декора своих помещений.',
    'Портреты известных личностей:\nЗагрузите портреты исторических и современных деятелей, которые подчеркнут стиль и тематику ваших помещений.',
    'Исторические события и достижения:\nНа нашей платформе множество изображений с важными моментами, мотивирующими молодежь и напоминающими о выдающихся людях.',
    'Искусственный Интеллект:\nСоздавайте уникальные изображения, точно вписывающиеся в интерьер ваших помещений.',
    '"3D Decor" – подчеркните важность и ценность вашего учреждения для подрастающих поколений. Наши 3D технологии помогут создать образовательную и вдохновляющую среду, которая будет мотивировать молодежь достигать высот и стремиться к новым свершениям.',
  ],
};
export const TEXTS_TITLE_BLOCKS = {
  b2c: [
    'Smart 3D Corporation предлагает инновационные ',
    'решения для облицовки стен в жилых домах',
    'и квартирах. Создайте уникальный интерьер, ',
    'который отражает вашу личность.',
  ],
  b2b: [
    'Компания Smart 3D Corporation предлагает ',
    'решения для облицовки стен в различных ',
    'бизнес-сферах. Привлеките новых клиентов и ',
    'приятно удивите постоянных.',
  ],
  b2g: [
    'Облицовка стен декоративным стройматериалом',
    '“3D Decor” даст сохранение исторических,',
    'народных, выдающихся личностей для молодого',
    'подрастающего поколения.',
  ],
};
