import React from 'react';
import { theme } from '../../../../themes/theme';

export const TriangleSvg = () => {
  return (
    <svg
      width="19"
      height="22"
      style={{ marginLeft: '4px', pointerEvents: 'none' }}
      viewBox="0 0 19 22"
    >
      <path
        d="M18 9.26795C19.3333 10.0378 19.3333 11.9623 18 12.7321L3 21.3923C1.66667 22.1621 8.49539e-07 21.1999 9.16837e-07 19.6603L1.67394e-06 2.33975C1.74124e-06 0.800144 1.66667 -0.162106 3 0.607695L18 9.26795Z"
        fill={theme.colors.white}
      />
    </svg>
  );
};
