export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
  Upload: any;
};

export type EmailVerificationCodeGetInput = {
  email: Scalars['String'];
};

export type EmailVerificationInput = {
  code: Scalars['Int'];
  email: Scalars['String'];
};

export type EmailVerificationResponse = {
  __typename?: 'EmailVerificationResponse';
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  categoryAdd: TextureCategoryResponse;
  orderFormSubmit: OrderFormSubmitResponse;
  textureUpload: TextureFileResponse;
};


export type MutationCategoryAddArgs = {
  input: TextureCategoryAddInput;
};


export type MutationOrderFormSubmitArgs = {
  input: OrderFormInput;
};


export type MutationTextureUploadArgs = {
  input: TextureUploadInput;
};

export type OrderFormInput = {
  address: Scalars['String'];
  attachment?: InputMaybe<Scalars['Upload']>;
  comment?: InputMaybe<Scalars['String']>;
  companyAddress?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companySite?: InputMaybe<Scalars['String']>;
  companyTaxNumber?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type OrderFormSubmitResponse = {
  __typename?: 'OrderFormSubmitResponse';
  address: Scalars['String'];
  attachmentExtension?: Maybe<Scalars['String']>;
  attachmentFile?: Maybe<Scalars['Upload']>;
  attachmentPath?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySite?: Maybe<Scalars['String']>;
  companyTaxNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum PatchesName {
  Advantages = 'Advantages',
  App = 'App',
  Clip3d = 'Clip3d',
  Constructor = 'Constructor',
  Contacts = 'Contacts',
  Decor3d = 'Decor3d',
  Developments = 'Developments',
  FormPartner = 'FormPartner',
  Home = 'Home',
  None = 'None',
  Products = 'Products',
  Questions = 'Questions',
  Steps = 'Steps',
  Technology = 'Technology',
  VideoExample = 'VideoExample',
  VideoPresent = 'VideoPresent'
}

export type Query = {
  __typename?: 'Query';
  categoriesGetAll: TextureCategoriesResponse;
  changeSelectDoor: Scalars['Int'];
  emailVerificationCodeCheck: EmailVerificationResponse;
  emailVerificationCodeGet: EmailVerificationResponse;
  isLoading: Scalars['Boolean'];
  isOpenPanelAddImageAndDoor: Scalars['Boolean'];
  isOpenPanelSettingsDoor: Scalars['Boolean'];
  selectPage: PatchesName;
  textureGetById: TextureFileResponse;
  texturesGetByCategoryId: Array<TextureFileResponse>;
};


export type QueryEmailVerificationCodeCheckArgs = {
  input: EmailVerificationInput;
};


export type QueryEmailVerificationCodeGetArgs = {
  input: EmailVerificationCodeGetInput;
};


export type QueryTextureGetByIdArgs = {
  input: TextureFileFilterInput;
};


export type QueryTexturesGetByCategoryIdArgs = {
  input: TextureCategoryFilterInput;
};

export type Size = {
  __typename?: 'Size';
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type SizeEnum = {
  __typename?: 'SizeEnum';
  ktx2: Size;
  m: Size;
  origin: Size;
  s: Size;
};

export type TextureCategoriesResponse = {
  __typename?: 'TextureCategoriesResponse';
  categories?: Maybe<Array<Maybe<TextureCategory>>>;
};

export type TextureCategory = {
  __typename?: 'TextureCategory';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  subcategory?: Maybe<Array<Maybe<TextureSubCategory>>>;
};

export type TextureCategoryAddInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCategory?: InputMaybe<Scalars['Int']>;
};

export type TextureCategoryFilterInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TextureCategoryResponse = {
  __typename?: 'TextureCategoryResponse';
  success: Scalars['Boolean'];
};

export type TextureFileFilterInput = {
  fileName?: InputMaybe<Scalars['String']>;
  textureId?: InputMaybe<Scalars['UUID']>;
};

export type TextureFileResponse = {
  __typename?: 'TextureFileResponse';
  categoryId: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  size: SizeEnum;
};

export type TextureSubCategory = {
  __typename?: 'TextureSubCategory';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TextureUploadInput = {
  categoryId: Scalars['Int'];
  file: Scalars['Upload'];
  name: Scalars['String'];
};
