/* eslint-disable no-console */
import { ApolloLink } from '@apollo/client';
import { GRAPHQL_API } from '../helpers/constans';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({ uri: GRAPHQL_API });
const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors?.length) {
    graphQLErrors.map(itemErr => {
      const { message, extensions } = itemErr;
      console.log(`%c[ERROR_GRAPHQL]: ${message}`, 'color: #e37b03');
      console.log(`%c[ERROR_GRAPHQL_CODE]: `, 'color: #e37b03', extensions?.code);
      console.log(
        `%c[ERROR_GRAPHQL_OPERATION]: `,
        'color: #e37b03',
        operation.operationName,
        operation.variables
      );

      /*setMessageError({
        dataWrite: {
          messageError: {
            message: `Operation: ${operation.operationName}. ${message}.`,
            code: (extensions?.code as string) || '',
            time: new Date().getTime().toString(),
          },
        },
      });
      setTimeout(() => {
        setMessageError({
          dataWrite: {
            messageError: null,
          },
        });
      }, 1000);*/
    });
  } //
  /*else {
    if (networkError && networkError?.message && networkError.message.includes('401')) {
      console.log(networkError?.message);
      window.localStorage.removeItem(LocalStorageKey.JWT);
      window.location.assign('/');
      apolloClient.clearStore().then();
    }
  }
  return new Observable((): void => {
    console.log('Errors Auth');
    // window.localStorage.removeItem(LocalStorageKey.JWT);
    // window.location.assign('/');
    // apolloClient.clearStore().then();
  });*/
});
export const apolloLink = ApolloLink.from([errorLink, httpLink as unknown as ApolloLink]);
