import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { Icon } from '../Icons/Icon';
import { MenuItemsHeaderDefault, MenuItemsHeaderDop, MenuItemType } from '../../helpers/constans';
import { StepRoute } from './components/StepRoute';
import { PatchesName } from '../../__generated__/types';
import { useLocation } from 'react-router-dom';
import { setSelectPage } from '../../api/store/functionsWitingCache';

const WrapperBox = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
`;
const WrapperSteps = styled(Box)`
  max-width: 1200px;
  gap: 30px;
`;
const ContainerIcon = styled.div`
  cursor: pointer;
`;

export const HeaderStepper: FC = () => {
  const pathname = useLocation().pathname;
  const selectMenu = useRef<MenuItemType>(MenuItemsHeaderDefault[0]);
  const MenuItems = useRef([...MenuItemsHeaderDefault, MenuItemsHeaderDop[2]]);

  const handleClickStep = (patch?: PatchesName) => {
    if (patch) {
      setSelectPage({
        dataWrite: {
          selectPage: patch,
        },
      });
    }
  };
  const handleClickLeftArrow = () => {
    if (selectMenu.current.scrollTo > 0) {
      setSelectPage({
        dataWrite: {
          selectPage: MenuItems.current[selectMenu.current.scrollTo - 1].path || PatchesName.Home,
        },
      });
    }
  };
  const handleClickRightArrow = () => {
    if (selectMenu.current.scrollTo < MenuItems.current.length - 1) {
      setSelectPage({
        dataWrite: {
          selectPage: MenuItems.current[selectMenu.current.scrollTo + 1].path || PatchesName.Home,
        },
      });
    }
  };
  const getItemsStep = () => {
    MenuItems.current.sort((a, b) => a.scrollTo - b.scrollTo);
    return MenuItems.current.map(item => {
      const isActive = pathname.includes(item.path || '');
      if (isActive) {
        selectMenu.current = item;
      }
      return (
        <StepRoute
          handleClick={() => handleClickStep(item.path)}
          key={item.text}
          isActive={isActive}
        />
      );
    });
  };

  if (
    pathname.includes(PatchesName.Home) ||
    pathname.includes(PatchesName.Constructor) ||
    pathname.includes(PatchesName.FormPartner)
  ) {
    return null;
  }

  return (
    <WrapperBox
      width="100%"
      height="60px"
      justify="space-between"
      align="flex-start"
      padding="0 80px 0 80px"
    >
      <ContainerIcon onClick={handleClickLeftArrow}>
        <Icon name="arrowPadLeft" />
      </ContainerIcon>
      <WrapperSteps>{getItemsStep()}</WrapperSteps>
      <ContainerIcon onClick={handleClickRightArrow}>
        <Icon name="arrowPadRight" />
      </ContainerIcon>
    </WrapperBox>
  );
};
