import { gql } from '@apollo/client';

export const LOCALE_SCHEME = gql`
  directive @client on FIELD_DEFINITION | FIELD

  enum PatchesName {
    Home
    FormPartner
    Constructor
    Technology
    Developments
    Decor3d
    Steps
    VideoPresent
    VideoExample
    Advantages
    Products
    Contacts
    App
    Clip3d
    Questions
    None
  }

  extend type Query {
    isOpenPanelAddImageAndDoor: Boolean!
    isOpenPanelSettingsDoor: Boolean!
    changeSelectDoor: Int!
    isLoading: Boolean!
    selectPage: PatchesName!
  }
`;
