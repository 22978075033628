/* eslint-disable no-var */
import * as THREE from 'three';
import { ParamsConstructorWall, Wall } from './Wall';

export class Floor extends Wall {
  constructor(params: ParamsConstructorWall) {
    super(params);
    this.name = 'floor';
  }
  changeDepthFloor(newDepth: number) {
    var offset = newDepth - this.height;
    var positions = this.geometry.attributes['position'].array;
    var ptCout = positions.length / 3;
    for (var i = 0; i < ptCout; i++) {
      var x = positions[i * 3];
      var y = positions[i * 3 + 1];
      var z = positions[i * 3 + 2];
      if (i === 0 || i === 3) {
        y -= offset / 2;
      } //
      else if (i === 2 || i === 5) {
        y += offset / 2;
      }
      this.geometry.attributes.position.setXYZ(i, x, y, z);
    }
    this.geometry.attributes.position.needsUpdate = true;

    positions = this.geometry.attributes['position'].array;
    var start = new THREE.Vector3(positions[0], positions[1], positions[2]);
    ptCout = positions.length / 3;

    for (let j = 0; j < ptCout; j++) {
      var _x = positions[j * 3];
      var _y = positions[j * 3 + 1];
      this.updateUV(start, _x, _y, newDepth, this.width, j);
    }
    this.geometry.attributes.uv.needsUpdate = true;
    this.height = newDepth;
  }
}
