import React, { FC, useEffect, useRef, useState } from 'react';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Texts/Text';

interface Props {
  handlerResend(): void;
}

export const TimerActive: FC<Props> = ({ handlerResend }) => {
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(60);
  const timerInterval = useRef<NodeJS.Timer>();
  const startTimer = () => {
    timerInterval.current = setTimeout(() => {
      setCounter(prev => {
        return prev - 1;
      });
    }, 1000);
  };
  const onResend = () => {
    setCounter(60);
    setIsActive(false);
    handlerResend();
  };

  useEffect(() => {
    if (counter > 0) {
      startTimer();
    } else {
      setIsActive(true);
    }
  }, [counter]);
  useEffect(() => {
    return () => {
      if (timerInterval.current) {
        clearTimeout(timerInterval.current);
      }
    };
  }, []);

  return (
    <Box direction="column" align="flex-end">
      <Text fontFamily="Gilroy" size="14px" weight={400}>
        <Text
          fontFamily="Gilroy"
          size="14px"
          weight={400}
          lineHeight="18px"
          color={!isActive ? 'softBlueViolet' : 'softBlueVioletDisable'}
        >{`(0:${counter < 10 ? '0' : ''}${counter}) `}</Text>
        Не пришел код?
      </Text>
      <div
        onClick={isActive ? onResend : undefined}
        style={{ cursor: isActive ? 'pointer' : 'auto', userSelect: 'none' }}
      >
        <Text
          fontFamily="Gilroy"
          size="14px"
          weight={400}
          color={isActive ? 'softBlueViolet' : 'softBlueVioletDisable'}
        >
          Отправить код повторно
        </Text>
      </div>
    </Box>
  );
};
