import React from 'react';
import './App.css';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from './themes/theme';
import { AppContext } from './Context/AppContext';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './api';
import { AppRouter } from './navigation';
import { BrowserRouter } from 'react-router-dom';

const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box
  }
`;

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AppContext>
        <ThemeProvider theme={theme}>
          <Global />
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ThemeProvider>
      </AppContext>
    </ApolloProvider>
  );
}

export default App;
