import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Box } from '../../components/Box';
import { theme } from '../../themes/theme';

const morphine = (props: PropsPath) => keyframes`
  0% {
    d: path('${props.dStart}');
  }
  40% {
    d: path('${props.dEnd}');  
  }
  50% {
    d: path('${props.dStart}');
  }
  100% {
    d: path('${props.dStart}');
  }
  
`;
const rotate = keyframes`
  0% {
    rotate: 0deg;
  }
  48% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
`;
interface PropsPath {
  dStart: string;
  dEnd: string;
}
const animation = (props: PropsPath) =>
  css<PropsPath>`
    ${morphine({ ...props })} 2s infinite;
  `;
const Path = styled('path').withConfig({
  shouldForwardProp: prop => !['dStart', 'dEnd'].includes(prop),
})<PropsPath>`
  animation: ${animation};
  d: path('${({ dStart }) => dStart}');
`;
const Wrapper = styled(Box)`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
`;
const SpanRotate = styled.span`
  animation: ${rotate} 2s infinite;
`;

export const LoadPage: FC = () => {
  return (
    <Wrapper width="100%" height="100%" background={theme.colors.white}>
      <SpanRotate>
        <svg width="188" height="170" viewBox="0 0 188 170" xmlns="http://www.w3.org/2000/svg">
          <path d="M74,170H0V0h75.9v168.1C76,169.1,75.1,170,74,170z" fill="#5F4FCA" />
          <path
            d="M187.7,170h-75.9V2.7c0-1.5,1.2-2.7,2.7-2.7h73.2L187.7,170L187.7,170z"
            fill="#72EB9F"
          />
          <circle cx="94.1" cy="86.5" r="46.6" />
          <Path
            dStart="M111.2,114.8c1.1,0.7,2.2,0.1,2.6-1c1-2.4,2.9-3.3,5.1-4.5c1.1-0.7,1.9-1.7,2-3.3c0-1.7-0.7-3.2-2.5-3.9
		c-2.1-0.8-3.7,0.1-4.7,1.9c-1.5,2.7-1,5.8-3.1,8.4C110.1,113,110.4,114.3,111.2,114.8z"
            dEnd="M112.2,107.2c1,0.4,2.8-0.4,3.3-2c1.1-3.5,2.6-3.7,4.4-5.7c1.1-1.2,1.6-3.5,1.2-5c-0.5-1.6-2-2.4-4-2.4
	c-2.2-0.1-4.1,2.4-3.6,4.4c0.8,3.3,1.2,5.4-1.3,7.9C111.4,105,110.9,106.7,112.2,107.2z"
            fill="#826EEE"
          />
          <Path
            dStart="M143.1,105c-3-1.3-4-3.3-5.3-6.4c-1-2.2-3.7-2.6-5.7-1.7c-2.2,0.9-2.3,4.1-1.5,5.6c1.1,1.9,3.2,2,5,2.8   c2.4,1.1,4.4,2.8,4.3,5.9c-0.2,3-2.6,3.7-4.9,4c-3,0.4-5.2,1.4-7.1,3.4c-3.4,3.6-7,6.2-11.4,8.7c-2.3,1.3-4.7,2.3-7,1.2   c-2.3-1.1-0.5-3.9,0.7-5.8c0.8-1.4,1.1-3-0.6-4.1c-1.4-0.9-3-0.4-4.1,0.6c-1.8,1.5-1.6,3.9-2.8,5.7c-4.7,6.9-14.8,8-17.5,4.6   c-2.6-3.3-2.7-5.4-8.7-4.4c-11.3,1.9-18.7-11.8-19.7-14.2c-1.3-3.2-2.3-5.9-5-7.8C39.2,94.2,33.4,79.6,33.6,65   c1-22.3,16.3-42.7,35.6-53.3c2.4-1.7,3.9-3,4.9-4.2c1.3-1.5,2-3.5,2-5.4V0H0.2v170h74.7c1.7,0,2.4-0.6,2.9-2.3   c1.8-5.3,6.6-7.7,12-5.8c4.1,1.4,6.9,0.6,10.1-2.2c4-3.4,7.9-9,14.7-7.9c4.4,0.8,6.2-1.9,6.2-3.5c0.1-1.5-1-2.4-4.7-2.8   c-0.7-0.1-1.6-0.7-1.7-1.5c-0.2-1.6,2.2-2.4,2.8-2.6c3.2-1.1,5.1-2.2,5.8-5.9c2.1-11.4,4.6-12,13.1-13c3.2-0.4,5.1-2.1,5.4-5.1   c0.3-2.8,1.2-5.3,2.9-7.6C145.4,108.2,144.7,105.7,143.1,105z"
            dEnd="M138.4,102.4c-4.7-6-4.8-10.3-5.7-12.4c-0.7-1.6-1.9-2.4-3-2.7c-3.3-0.6-5.3,1.3-5.6,3.4
	c-0.4,2.2,0.9,5.3,4.9,5.9c8.1,1.2,7.5,8.9,6.8,12.5c-0.4,2-1.9,6.2-4.9,7.6c-5.1,2.4-6-2.9-8.8-0.8c-4.1,3.1-2.3,6.9-5.8,10.7
	c-1.3,1.4-4.3,2.7-6.7,1.8c-2.4-0.8-3.1-7.5,0.2-10.3c4.7-4,3.3-6.5,1.4-6.9c-1.4-0.3-3.4,0.5-4.4,2.2c-1.4,2.3-2.8,9.3-4.1,11.6
	c-4,7.3-14.8,8-17.5,4.6c-2.6-3.3,2.7-7.8-3.6-7.3c-11.4,1.1-9.6-6.6-15.3-6.8c-7.7-0.3-11.8-10.4-14.5-12.3
	C39.1,94.2,33.3,79.6,33.5,65c1-22.3,16.3-42.7,35.6-53.3c2.4-1.7,3.9-3,4.9-4.2C75.3,6,76,4,76,2.1V0H0.1v170h74.7
	c1.7,0,2.8-0.5,2.9-2.3c0.3-7.3,5.4-10.1,11.5-9.8c5.3,0.2,7.6-1.8,7.9-6c0.3-4.8,4.3-6.8,15.8-4.7c4.4,0.8,9.9-0.6,10.7-3.1
	c0.5-1.6-0.1-3.8-6-2.2c-2.5,0.7-3.5,0.1-3.6-0.7c-0.2-1.6,1.2-2.4,2.1-2.8c3.5-1.5,4.6-3.5,5.4-5.8c2.8-7.3,4.4-6.5,8.6-11.2
	c3.4-3.9,3-5.3,5.4-8.1c1.8-2.1,2.8-3.1,3.8-3.9C140.9,108.2,141.3,106.1,138.4,102.4z"
            fill="#826EEE"
          />
          <Path
            dStart="M114.3,0c-2.3,0-3.2,0.7-3.8,2.9c-1.3,5-4.4,6.8-9.3,5.4c-1.5-0.4-3-1-4.5-1.6c-2.3-1-4.7-1.1-6.5,0.7
		c-4.9,4.7-11,5.9-17.3,6.6c-4.6,0.5-9,4.7-9.8,8.7c-0.5,2.3,0,4.8,2.6,5.3c2,0.3,3-2.7,3.9-4.1c1.4-2.2,3.7-1.1,4.6,0.6
		c1.4,2.7,2.5,8.9-3.4,11.5c-4.2,1.8-5.6,0.5-7.4,8.1c-0.6,2.5-3.1,3.7-5.2,5c-4.3,2.9-5.8,6.8-4,11.5c1.5,3.9,2.2,7.7,1.4,11.8
		c-0.4,2.1,0.1,4.1,2.2,5.1c2.3,1.2,3.8-0.4,5.3-1.8c0.5-0.5,1-1,1.4-1.6c2.2-3.3,5.4-3.4,7.3-0.1c0.7,1.2,1.2,2.7,1.4,4
		c0.7,4.9,0.5,9.7-1.2,14.4c-1.9,5.5-1,10.5,3.3,14.7c2,1.9,4.1,2.5,6.7,1.1c2.6-1.4,1.8-3.7,1.5-5.7c-0.3-2-1.7-3.6-1.8-5.7
		c0-1.8,0.2-3.5,1.9-4.5c1.9-1.1,3.3,0.2,4.5,1.4c1.7,1.7,3.1,3.8,5.3,5.1c3.6,2.2,7.2,1.8,8.8-0.9c1.6-2.9,0.2-6-3.6-8
		c-6.2-3.2-8.5-8.2-6.9-15.1c1.8-7.7,5.4-14.6,10.4-20.7c4.4-5.4,9.9-6.1,15.7-2.1c3.1,2.1,3.7,4.5,1.4,7.6
		c-2.3,3.1-5.3,5.3-8.4,7.4c-1.4,1-2.2,2.3-1.7,4.1c0.4,1.5,1.4,2.5,3.1,2.6c1.9,0.1,3.4-0.4,4.3-2.2c0.4-0.8,0.6-1.6,1-2.4
		c2-4,4.6-7.3,8.7-9.3c2-1,4.2-1.6,5.9,0.4c1.7,2,0.6,3.6-1,5.2c-2.6,2.5-1.2,4,1.6,4.9c1.1,0.3,2.3,0.6,3.4,0.6
		c4.1,0,7,1.7,9.4,4.4c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c2.7,3.5,4.8,7.3,6.1,11.3c9.1,28.8-9,57.6-33.2,71
		c-2.4,1.7-3.9,3-4.9,4.2c-1.3,1.5-2,3.5-2,5.4v2.1h75.9V0C187.8,0,114.4,0,114.3,0z"
            dEnd="M114.2,0c-2.3,0-5.2,1.6-4,4.7c3.6,8.9-2.9,9.4-8.2,8.3c-4.8-1-6.8-2.4-8.3-2.9c-2.5-0.9-5.2-0.8-8.2,1.2
	c-5.7,3.7-6.4,2.1-12.7,2.8c-4.6,0.5-9,4.7-9.8,8.7c-0.5,2.3,0,4.8,2.6,5.3c2,0.3,5.2-2.5,6.8-4c2.3-2,5.8-0.8,7.1,1.6
	c1.4,2.7-0.9,7.6-6.8,10.2c-4.2,1.8-5.5,2.5-7,7.8c-0.6,2.5-1.4,4.4-4.9,8.4c-3.1,3.6-4.6,6.4-4.4,12.1c0.2,3.4-1.5,8.1-2,10.8
	c-0.7,3.1,0,13.3,4.8,14.2c5.3,0.3,0.7-8,1.5-12c0.6-3,3-4.1,3.9-4.6c3.2-1.2,5.4-0.2,7.1,1.6c0.7,1.2,1.2,2.7,1.4,4
	c0.7,4.9,0,8-0.3,13.9c-0.3,5.9,0.9,12.2,6.1,18.1c3.2,3.7,8.9,2.7,9.1-0.3c0.1-2-1.2-3.3-4.8-5.1c-4.8-2.4-5.4-6-5.2-8
	c0.4-2.9,2.3-4.3,4-5.3c3.5-1.6,6.5,0.5,7.7,2c2.3,3,6.6,7.3,10.9,8.5c5.3,1.5,9.5-0.5,9.1-3.6c-0.5-3.4-4.2-4.6-8-6.6
	c-6.2-3.2-15-8.6-15-20.5c0.1-10.4,3.2-15,7.3-18.5c4.6-3.9,18-4.6,23.8-0.6c3.1,2.1,4.7,4,3.1,7.2c-1.7,3.4-6.6,8.2-12.7,11.4
	c-1.4,1-3.8,3-3.3,4.8c0.4,1.5,1.4,2.5,3.1,2.6c1.9,0.1,3.4-0.4,4.3-2.2c0.4-0.8,0.6-1.6,1-2.4c2.3-5.5,8.8-11.7,12.9-13.7
	c2-1,4.4-1,4.9,0.4c0.6,1.8-3.7,4.8-5.3,6.4c-2.6,2.5-2.2,4.7,1.6,4.9c1.1,0.1,7.6-0.6,8.7-0.6c4.1,0,7,1.7,9.4,4.4c0,0,0,0,0.1,0.1
	c0.2,0.2,0.4,0.5,0.6,0.7c2.7,3.5,4.8,7.3,6.1,11.3c9.1,28.8-9,57.6-33.2,71c-2.4,1.7-3.9,3-4.9,4.2c-1.3,1.5-2,3.5-2,5.4v2.1H188V0
	C187.7,0,114.3,0,114.2,0z"
            fill="#8DFFBA"
          />
        </svg>
      </SpanRotate>
    </Wrapper>
  );
};
