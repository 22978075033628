import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
export class Load {
  private isAddLight = false;
  isAnimation = false;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  controls: OrbitControls;
  widthCanvas = 0;
  heightCanvas = 0;
  functionAnimate?: () => void;
  constructor() {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.camera = new THREE.PerspectiveCamera();
    this.scene.add(this.camera);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
  }
  initScene(containerCanvas: HTMLDivElement) {
    const box = containerCanvas.getBoundingClientRect();
    this.widthCanvas = box.width;
    this.heightCanvas = box.height;

    this.camera.aspect = box.width / box.height;
    this.camera.fov = 45;
    this.camera.near = 0.1;
    this.camera.far = 100;
    this.camera.position.copy(new THREE.Vector3(0, 2, 6));
    const targetLook = new THREE.Vector3(0, 1, 0);
    this.camera.lookAt(targetLook);
    this.controls.target = targetLook;
    this.camera.updateProjectionMatrix();

    this.renderer.setClearColor('#dcdcdc');
    this.renderer.shadowMap.enabled = true;
    // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    // this.renderer.outputEncoding = THREE.LinearEncoding;

    this.renderer.setPixelRatio(2);
    this.renderer.setSize(this.widthCanvas, this.heightCanvas);
    this.renderer.sortObjects = false;
    this.renderer.domElement.setAttribute('id', 'canvas_scheme_constructor');
    // this.renderer.domElement.style.pointerEvents = 'none';

    containerCanvas.innerHTML = '';
    containerCanvas.appendChild(this.renderer.domElement);
    if (!this.isAddLight) {
      this.addLight();
    }
  }
  startAnimation() {
    if (!this.isAnimation) {
      this.addHelpers();
      this.isAnimation = true;
      this.animate(this.camera, this.scene, this.renderer);
    }
  }
  stopAnimation() {
    if (this.isAnimation) {
      this.isAnimation = false;
    }
  }
  addLight() {
    this.isAddLight = true;
    const sphereSize = 0.1;
    const pointLight = new THREE.PointLight(0xffffff, 2, 10);
    pointLight.position.set(0, 2, 0);
    this.scene.add(pointLight);
    const pointLightHelper = new THREE.PointLightHelper(pointLight, sphereSize);
    this.scene.add(pointLightHelper);

    const pointLight1 = new THREE.PointLight(0xffffff, 2, 10);
    pointLight1.position.set(0, 0.2, 0);
    this.scene.add(pointLight1);
    const pointLightHelper1 = new THREE.PointLightHelper(pointLight1, sphereSize);
    this.scene.add(pointLightHelper1);

    const ambient = new THREE.AmbientLight(0xffffff, 3);
    this.scene.add(ambient);
  }
  addHelpers() {
    const gridHelper = new THREE.GridHelper(10, 10);
    gridHelper.position.y -= 0.5;
    const axesHelper = new THREE.AxesHelper(5);
    this.scene.add(axesHelper, gridHelper);
  }
  animate(camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer) {
    if (this.isAnimation) {
      requestAnimationFrame(() => this.animate(camera, scene, renderer));
      renderer.render(scene, camera);
      if (this.functionAnimate) {
        this.functionAnimate();
      }
    }
  }
}
