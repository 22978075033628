/* eslint-disable no-console */
import React, { BaseSyntheticEvent, Dispatch, FC, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/Texts/Text';
import { SpanColor } from '../../../components/SpanColor';
import { Box } from '../../../components/Box';
import { theme } from '../../../themes/theme';
import { FileComponent } from './FileComponent';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
`;

interface Props {
  filesUpload: Array<File>;
  setFilesUpload: Dispatch<SetStateAction<Array<File>>>;
}

export const AddImages: FC<Props> = ({ filesUpload, setFilesUpload }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleUploadImg = (evt: BaseSyntheticEvent) => {
    const file: File = evt.target.files[0];
    if (file && file.size < 2.5e7) {
      setFilesUpload([...filesUpload, file]);
    } else {
      if (inputRef.current) {
        inputRef.current.value = '';
        console.log('Слишком большой размер');
      }
    }
  };
  const removeSelectFile = (index: number) => {
    const arr = [...filesUpload];
    arr.splice(index, 1);
    console.log(arr);
    setFilesUpload(arr);
  };
  const getFiles = () => {
    return filesUpload.map((item, index) => (
      <FileComponent
        key={item.name}
        index={index}
        onPressComponent={() => removeSelectFile(index)}
      />
    ));
  };

  return (
    <Wrapper>
      <Text fontFamily="Neue Machina" weight={700} size="18px" marginBottom="6px">
        {'Прикрепить файл (файлы до '}
        <SpanColor>25</SpanColor>
        {'MB)'}
      </Text>
      <Box>
        {getFiles()}
        {filesUpload.length < 2 && (
          <Box
            position="relative"
            width="40px"
            height="40px"
            borderRadius="20px"
            background={theme.colors.softBlueViolet_100}
          >
            <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.63465 4.384V6.166H5.89065V9.91H4.10865V6.166H0.364648V4.384H4.10865V0.639999H5.89065V4.384H9.63465Z"
                fill={theme.colors.softBlueViolet}
              />
            </svg>
            <input
              ref={inputRef}
              style={{
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
                width: '100%',
                height: '100%',
              }}
              type="file"
              onChange={handleUploadImg}
            />
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};
