import React, { FC } from 'react';
import { Text } from '../../../Texts/Text';
import { Box } from '../../../Box';

interface Props {
  title: string;
  text: string;
}
export const ItemText: FC<Props> = ({ title, text }) => {
  return (
    <Box direction="column" align="flex-start" margin="0 0 20px 0">
      <Text fontFamily="Neue Machina" weight={700} size="16px" lineHeight="16px">
        {title}
      </Text>
      <Text width="260px" fontFamily="Neue Machina" weight={400} size="16px" lineHeight="18px">
        {text}
      </Text>
    </Box>
  );
};
