import React from 'react';
import { BlockPresent } from '../../components/Blocks/BlockPresent';
import { WrapperPage } from '../../components/WrapperPage';

export const Home = () => {
  return (
    <WrapperPage>
      <BlockPresent />
    </WrapperPage>
  );
};
