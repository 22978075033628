import React, { useState } from 'react';
import { Box } from '../../Box';
import { BoxImage } from './components/BoxImage';
import { ContainerCanvas } from './SliderAnimate/ContainerCanvas';
import { BlocKTexts } from './components/BloclTexts';
import { Details } from '../../../pages/Home/components/Details';
import { LoadPage } from '../../../pages/LoadPage';

export const BlockPresent = () => {
  const [isLoad, setIsLoad] = useState(true);
  return (
    <Box width="100%" justify="center" align="center" overflow="hidden" position="relative">
      <BoxImage overflow="hidden" background={'rgba(105,94,105,0.55)'}>
        <ContainerCanvas endLoadPage={() => setIsLoad(false)} />
        <BlocKTexts />
        <Details />
      </BoxImage>
      {isLoad && <LoadPage />}
    </Box>
  );
};
