import React, { FC, useEffect, useRef, useState } from 'react';
import questions from '../mock/questions.json';
import styled from 'styled-components';
import { QuestionDataType } from '../../../../helpers/constans';
import { ItemQuestion } from './ItemQuestion';

interface DataType {
  [key: number]: Array<QuestionDataType>;
}
const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 40px;
  height: 500px;
  overflow: auto;
  opacity: 0;
  //background-color: #61dafb;
  //transition: opacity 700ms linear;
  @media (max-height: 880px) {
    flex: 1;
  }
`;

interface Props {
  mode: number;
}
export const ContentTabAnimate: FC<Props> = ({ mode }) => {
  const [currentMode, setCurrentMode] = useState(mode);
  const container = useRef<HTMLDivElement>(null);

  const getContent = () => {
    const content = (questions as DataType)[currentMode];
    return content.map(item => (
      <ItemQuestion key={item.question} question={item.question} answer={item.answer} />
    ));
  };
  const handleEnterAnimate = () => {
    if (container.current) {
      container.current.animate([{ opacity: 0 }], {
        duration: 500,
        easing: 'linear',
      }).onfinish = () => {
        if (container.current) {
          container.current.style.opacity = '0';
        }
        setCurrentMode(mode);
      };
    }
  };
  const handleExitAnimate = () => {
    if (container.current) {
      container.current.animate([{ opacity: 1 }], {
        duration: 500,
        easing: 'linear',
      }).onfinish = () => {
        if (container.current) {
          container.current.style.opacity = '1';
        }
      };
    }
  };
  useEffect(() => {
    handleEnterAnimate();
  }, [mode]);
  useEffect(() => {
    if (currentMode === mode) {
      handleExitAnimate();
    }
  }, [currentMode]);
  return <Container ref={container}>{getContent()}</Container>;
};
