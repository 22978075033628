import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../../../../components/Box';
import { theme } from '../../../../../themes/theme';
import { Text } from '../../../../../components/Texts/Text';

export const WrapperItem = styled(Box)`
  cursor: pointer;
`;

interface Props {
  title: string;
  idCategory: number;
  selectCategory(id: number): void;
}

export const ItemCategory: FC<Props> = ({ title, idCategory, selectCategory }) => {
  return (
    <WrapperItem
      width="100%"
      height="60px"
      borderRadius="45px"
      background={theme.colors.lightGray}
      onClick={() => selectCategory(idCategory)}
      margin="0 0 20px 0"
    >
      <Text fontFamily="Gilroy" size="18px" weight={400}>
        {title}
      </Text>
    </WrapperItem>
  );
};
