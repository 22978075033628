import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { HOST, LOAD_CLASS_OBJECT } from '../../../../../helpers/constans';
import { Room } from '../../../canvas/classes/Room';
import { useTexturesGetByCategoryIdQuery } from '../../../../../graphql/queries/__generated__/texturesGetByCategoryId';
import { Box } from '../../../../../components/Box';
import { LoadIndicator } from '../../../../../components/LoadIndicator';
import { Text } from '../../../../../components/Texts/Text';
import { Size } from '../../../../../__generated__/types';

export const WrapperPanel = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  padding: 40px;
  gap: 20px;
`;
export const WrapperImg = styled.div`
  cursor: pointer;
  width: 46%;
  height: 110px;
  background-color: aqua;
  border-radius: 16px;
  overflow: hidden;
`;
export const Img = styled.img`
  object-fit: cover;
`;

interface Props {
  categoryId: number;
  onPressTexture(): void;
}

export const PanelSelectTexture: FC<Props> = ({ categoryId, onPressTexture }) => {
  const { data, loading } = useTexturesGetByCategoryIdQuery({
    variables: {
      input: {
        categoryId,
      },
    },
  });
  const Room = useRef<Room>(LOAD_CLASS_OBJECT.scene.getObjectByName('room') as Room).current;

  const addPanels = (objTexture: Size) => {
    onPressTexture();
    const { width, height, url } = objTexture;
    if (Room) {
      Room.addingPanelsObject(`${HOST}/${url}`, width, height);
    }
    // const img = new Image();
    // img.onload = function () {
    //   const el = this as HTMLImageElement;
    //   const { width, height } = el;
    //   if (Room) {
    //     Room.addingPanelsObject(textureKTX2, width, height);
    //   }
    // };
    // img.src = urlImg;
  };
  const getTextures = () => {
    if (data?.texturesGetByCategoryId.length) {
      // console.log(data.texturesGetByCategoryId);
      return data.texturesGetByCategoryId.map((item, index) => (
        <WrapperImg key={index.toString()} onClick={() => addPanels(item.size.ktx2)}>
          <Img src={`${HOST}/${item.size.s.url}`} width="100%" height="100%" alt="image" />
        </WrapperImg>
      ));
      /* return TEXTURES_MOCK.map(mock => (
        <WrapperImg key={mock.t} onClick={() => addPanels(mock.tk, mock.t)}>
          <Img src={mock.t} width="100%" height="100%" alt="image" />
        </WrapperImg>
      ));*/
    }

    return (
      <Box width="100%" height="100%">
        <Text fontFamily="Neue Machina" size="20px" weight={700}>
          Нет текстур
        </Text>
      </Box>
    );

    // return TEXTURES_MOCK.map(mock => (
    //   <WrapperImg key={mock.t} onClick={() => addPanels(mock.tk, mock.t)}>
    //     <Img src={mock.t} width="100%" height="100%" alt="image" />
    //   </WrapperImg>
    // ));
  };

  return (
    <WrapperPanel>
      {getTextures()}
      {loading && (
        <Box width="100%" height="100%">
          <LoadIndicator size={100} />
        </Box>
      )}
    </WrapperPanel>
  );
};
