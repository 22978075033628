import * as THREE from 'three';
import { Mesh, Scene } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
export const getClipObject = (scene: Scene) => {
  return new Promise<Mesh>((resolve, reject) => {
    const loader = new GLTFLoader();
    const path = new URL('../../../assets/models3d/scene.glb', import.meta.url);
    loader.load(
      path.href,
      function (gltf) {
        const obj = gltf.scene.children[0] as Mesh;
        obj.castShadow = true;
        obj.receiveShadow = false;
        const plane = gltf.scene.children[1] as Mesh;
        plane.receiveShadow = true;
        plane.position.set(-0.1, -1.2, -0.3);
        (obj.material as THREE.MeshBasicMaterial).color = new THREE.Color('#EA7B38');

        scene.add(obj);
        scene.add(plane);
        resolve(obj);
      },
      function () {
        // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function () {
        // console.log('An error happened', error);
        reject();
      }
    );
  });
};
export const createLightScene = (scene: Scene) => {
  const spotLight = new THREE.PointLight(0xffffff, 6);
  spotLight.position.set(1, -0.7, 2);
  scene.add(spotLight);
  // const spotLightHelper = new THREE.PointLightHelper(spotLight, 0.2);
  // scene.add(spotLightHelper);
  // const light = new THREE.AmbientLight(0xffffff, 3);
  // scene.add(light);
  const directionalLight = new THREE.DirectionalLight(0xffffff, 10);
  directionalLight.position.set(0.2, 2, 0.2);
  directionalLight.shadow.camera.near = 1;
  directionalLight.shadow.camera.far = 4;
  directionalLight.shadow.mapSize.width = 512;
  directionalLight.shadow.mapSize.height = 512;
  directionalLight.shadow.camera.top = 1;
  directionalLight.shadow.camera.bottom = -1;
  directionalLight.shadow.camera.left = -1;
  directionalLight.shadow.camera.right = 1;
  directionalLight.shadow.blurSamples = 2;
  directionalLight.castShadow = true;

  // const helper = new THREE.CameraHelper(directionalLight.shadow.camera);
  // scene.add(helper);

  scene.add(directionalLight);
};
export const addRotateObject = (object: THREE.Mesh | null) => {
  if (object) {
    if (object.rotation.y < Math.PI * 2) {
      object.rotation.y += 0.005;
    } else {
      object.rotation.y = 0;
    }
    if (object.rotation.x > 0.01) {
      object.rotation.x -= 0.01;
    } else if (object.rotation.x < -0.01) {
      object.rotation.x += 0.01;
    } else {
      object.rotation.x = 0;
    }
  }
};
