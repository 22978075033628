/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, HTMLProps, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../Texts/Text';
import { SpanColor } from '../SpanColor';
import { InputError } from '../InputError';
import { CustomInputStyle, TextAreaStyle } from '../Styles';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
`;

interface Props {
  title: string;
  placeHolder: string;
  isRequired?: boolean;
  isGoogleSearch?: boolean;
  isLoadScript?: boolean;
  inputProps?: HTMLProps<HTMLInputElement>;
  textAreaProps?: HTMLProps<HTMLTextAreaElement>;
  isTextArea?: boolean;
  error?: string;
}
export const CustomInput: FC<Props> = ({
  title,
  placeHolder,
  isRequired,
  isGoogleSearch,
  isLoadScript,
  inputProps,
  textAreaProps,
  isTextArea,
  error,
}) => {
  const [valueTextArea, setValueTextArea] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLTextAreaElement>(null);

  const initAutocomplete = () => {
    if (isLoadScript && inputRef.current && isGoogleSearch) {
      //@ts-ignore
      const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        fields: ['address_components'],
        types: ['(cities)'],
        componentRestrictions: { country: ['us', 'ua', 'kz'] },
      });
      autocomplete.addListener('place_changed', async function () {
        if (inputProps?.onChange) {
          const place = await autocomplete.getPlace();
          if (place) {
            // console.log(place.address_components);
            const address = place.address_components
              .map((item: { long_name: string }) => item.long_name)
              .join(' ');
            inputProps.onChange(address);
          }
        }
      });
    }
  };
  const handlerChangeSpan = (): void => {
    if (spanRef.current) {
      spanRef.current.style.height = 'auto';
      const h = spanRef.current.scrollHeight;
      spanRef.current.style.height = h + 'px';
      if (spanRef.current.value.length <= 300) {
        setValueTextArea(spanRef.current.value);
      } else {
        const val = spanRef.current.value.slice(0, 300);
        spanRef.current.value = val;
        setValueTextArea(val);
      }
    }
  };

  useEffect(() => {
    if (isLoadScript) {
      initAutocomplete();
    }
  }, [isLoadScript]);

  return (
    <Wrapper>
      <Text fontFamily="Neue Machina" weight={700} size="18px" marginBottom="6px">
        {isTextArea ? `${title} (${valueTextArea.length}/300)` : title}
        {isRequired && <SpanColor>*</SpanColor>}
      </Text>
      {isTextArea ? (
        <TextAreaStyle ref={spanRef} rows={1} onInput={handlerChangeSpan} {...textAreaProps} />
      ) : (
        <CustomInputStyle
          ref={inputRef}
          isError={!!error}
          placeholder={placeHolder}
          {...inputProps}
        />
      )}
      <InputError error={error} />
    </Wrapper>
  );
};
