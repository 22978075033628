/* eslint-disable no-var */
import React, { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';

const WrapperBox = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  height: 4px;
  border-radius: 2px;
  background: #dad3fa;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.softBlueViolet};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.softBlueViolet};
    cursor: pointer;
  }
`;

interface Props {
  min: string;
  max: string;
  step: string;
  handleChangeValue(value: string): void;
  handleDown(): void;
  handleUp?: (value: string) => void;
  initValue?: string;
}
export const SliderWall: FC<Props> = ({
  max,
  min,
  step,
  handleChangeValue,
  handleDown,
  handleUp,
  initValue = '0',
}) => {
  const [inputValue, setInputValue] = useState(initValue);

  const handlerChangeInput = (evt: ChangeEvent<HTMLInputElement>) => {
    var value = evt.target.value;
    setInputValue(value);
    handleChangeValue(value);
  };
  const handlerDownInput = () => {
    handleDown();
  };
  const handlerUpInput = () => {
    setInputValue(initValue);
    if (handleUp) {
      handleUp(inputValue);
    }
  };

  return (
    <WrapperBox>
      <Input
        type="range"
        value={inputValue}
        onChange={handlerChangeInput}
        onMouseDown={handlerDownInput}
        onMouseUp={handlerUpInput}
        min={min}
        max={max}
        step={step}
      />
    </WrapperBox>
  );
};
