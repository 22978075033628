import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Texts/Text';
import { SpanColor } from '../../../components/SpanColor';

const WrapperGrid = styled.div``;

export const BoxCompany: FC = () => {
  return (
    <Box width="100%" justify="flex-end" padding="30px 0 0 0">
      <Text fontFamily="Gilroy" weight={400} size="16px">
        {'“Smart3D Corporation” | '}
        {'2024 | '}
        <SpanColor>Все права защищены</SpanColor>
      </Text>
    </Box>
  );
};
