import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Logo } from './components/Logo';
import { MenuItem } from './components/MenuItem';
import { Text } from '../Texts/Text';
import { BoxHeader } from './components/BoxHeader';
import { BoxGap } from './components/BoxGap';
import { MenuItemsHeaderDefault, MenuItemsHeaderDop, MenuItemType } from '../../helpers/constans';
import { theme } from '../../themes/theme';
import { Box } from '../Box';
import { Icon } from '../Icons/Icon';

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  padding: 3px;
  cursor: pointer;
`;
const HoverBox = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  &:hover {
    color: ${theme.colors.softBlueViolet};
  }
`;
const HoverLine = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  pointer-events: auto;
  width: 100%;
  height: calc(100% + 7px);
  padding-right: 100%;
  transition: padding-right 0.7s ease-in-out;
  &:hover {
    padding-right: 0;
  }
`;
const HoverLeft = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.softBlueViolet};
`;
interface Props {
  isHeaderVisible: boolean;
  closeMenu: () => void;
}

export const Header: FC<Props> = ({ isHeaderVisible, closeMenu }) => {
  const getItems = (items: MenuItemType[]): ReactNode[] => {
    return items.map(item => (
      <MenuItem key={item.text} patch={item.path}>
        <HoverBox>
          <Text fontFamily="Neue Machina" size="20px" weight={300} lineHeight="19px">
            {item.text}
          </Text>
          <HoverLine>
            <HoverLeft />
          </HoverLine>
        </HoverBox>
      </MenuItem>
    ));
  };
  return (
    <BoxHeader isHeaderVisible={isHeaderVisible} closeMenu={closeMenu}>
      <Box width="100%" justify="space-between" padding="50px 70px 50px 60px">
        <Logo color={theme.colors.black} />
        <IconContainer onClick={closeMenu}>
          <Icon name="cross" width="100%" height="100%" />
        </IconContainer>
      </Box>
      <BoxGap isBorder padding="0 0 30px 0">
        {getItems(MenuItemsHeaderDefault)}
      </BoxGap>
      <BoxGap padding="30px 0 0 0">{getItems(MenuItemsHeaderDop)}</BoxGap>
    </BoxHeader>
  );
};
