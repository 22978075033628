import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  text?: string;
  size: 'large' | 'small';
}

const CircleWithNumberStyle = styled.div<Props>`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.softBlueViolet};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Neue Machina', serif;
  ${props =>
    props.size === 'large' &&
    css`
      width: 80px;
      height: 80px;
      border-radius: 40px;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
    `};
  ${props =>
    props.size === 'small' &&
    css`
      width: 44px;
      height: 44px;
      border-radius: 22px;
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
    `};
`;

export const CircleWithNumber: FC<PropsWithChildren<Props>> = ({ text, size }) => {
  return (
    <CircleWithNumberStyle size={size}>
      <span style={{ marginBottom: '-6px' }}>{text}</span>
    </CircleWithNumberStyle>
  );
};
