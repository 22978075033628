import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/theme';
import { Text } from '../../../components/Texts/Text';
import { Box } from '../../../components/Box';

const Wrapper = styled(Box)`
  cursor: pointer;
`;
const SvgStyle = styled.svg`
  pointer-events: none;
  display: none;
`;
const TextStyle = styled(Text)`
  pointer-events: none;
`;
interface Props {
  index: number;
  onPressComponent(): void;
}

export const FileComponent: FC<Props> = ({ index, onPressComponent }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const handlerOver = () => {
    if (svgRef.current && textRef.current) {
      textRef.current.style.display = 'none';
      svgRef.current.style.display = 'block';
    }
  };
  const handlerOut = () => {
    if (svgRef.current && textRef.current) {
      textRef.current.style.display = 'block';
      svgRef.current.style.display = 'none';
    }
  };
  return (
    <Wrapper
      width="40px"
      height="40px"
      borderRadius="20px"
      background={theme.colors.softBlueViolet_100}
      margin="0 10px 0 0"
      onMouseOver={handlerOver}
      onMouseOut={handlerOut}
      onClick={onPressComponent}
    >
      <SvgStyle
        ref={svgRef}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="#EE3B3B"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="11.0664"
          y="9.2207"
          width="2.60797"
          height="13.0398"
          rx="1.30398"
          transform="rotate(135 11.0664 9.2207)"
        />
        <rect
          x="1.9375"
          y="11.1768"
          width="2.60797"
          height="13.0398"
          rx="1.30398"
          transform="rotate(-135 1.9375 11.1768)"
        />
      </SvgStyle>

      <TextStyle
        ref={textRef}
        fontFamily="Neue Machina"
        weight={700}
        size="18px"
        color="softBlueViolet"
      >
        {index + 1}
      </TextStyle>
    </Wrapper>
  );
};
