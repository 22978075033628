import React from 'react';
import { theme } from '../../../../themes/theme';

export const Images = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 511.985 511.985">
      <g>
        <path
          d="M454.418 74.327 173.68 1.376a42.665 42.665 0 0 0-52.053 30.6l-16.102 61.875H68.268c-23.552.028-42.638 19.114-42.667 42.667v332.8c.028 23.552 19.114 42.638 42.667 42.667h290.133c23.552-.028 42.638-19.114 42.667-42.667v-20.105c.077-.273.145-.478.23-.794l83.721-322.031c5.886-22.82-7.799-46.102-30.601-52.061zm-386.15 36.591h290.133c14.138 0 25.6 11.462 25.6 25.6v128.751c-11.503.93-33.28 3.081-60.749 7.68-5.772-37.442-37.901-65.136-75.785-65.323a76.245 76.245 0 0 0-64.452 35.217A354.195 354.195 0 0 0 42.667 208.01v-71.492c0-14.138 11.462-25.6 25.601-25.6zm315.733 245.607c-7.339.785-16.708 2.057-27.605 4.147a332.88 332.88 0 0 0-37.333 9.344 243 243 0 0 0-24.132 8.747c-29.833 12.553-92.689 56.815-105.634 116.156h-82.304c39.415-86.528 90.803-114.133 136.209-133.001 11.366-4.727 23.663-9.139 36.523-13.124a541.452 541.452 0 0 1 57.353-14.106c19.925-3.738 36.395-5.692 46.891-6.699zM241.255 291.33a131.998 131.998 0 0 0-15.65-18.167 159.567 159.567 0 0 0-27.827-21.837c13.697-20.628 38.719-30.641 62.866-25.157 24.146 5.484 42.389 25.324 45.832 49.844-22.315 4.181-44.186 9.31-65.221 15.317zM42.667 225.094c59.29 2.876 132.821 22.084 171.025 60.279a117.61 117.61 0 0 1 10.172 11.093C165.462 314.864 92.579 347.743 42.667 404.37zm0 244.224v-37.879c49.681-67.49 134.682-103.663 197.623-122.121a700.814 700.814 0 0 1 76.8-17.92c30.037-5.419 54.093-7.902 66.833-8.96v28.45c-10.778.99-28.493 3.029-50.065 7.074a558.436 558.436 0 0 0-59.213 14.566c-13.38 4.147-26.18 8.747-38.042 13.653-49.63 20.608-106.044 51.012-148.275 148.736h-20.06c-14.139.001-25.601-11.46-25.601-25.599zm315.734 25.6H206.712c13.175-50.594 69.419-89.728 94.865-100.437a226.757 226.757 0 0 1 22.511-8.158 319.37 319.37 0 0 1 35.499-8.892 319.106 319.106 0 0 1 24.414-3.729v95.616c0 14.139-11.462 25.6-25.6 25.6zm110.08-372.83-67.413 259.396V136.518c-.028-23.552-19.114-42.638-42.667-42.667H123.162l14.976-57.574a25.6 25.6 0 0 1 31.232-18.372l280.747 72.951c13.678 3.577 21.888 17.541 18.364 31.232z"
          fill={theme.colors.white}
        />
        <g fillRule="evenodd" clipRule="evenodd">
          <path
            d="M247.468 196.252a8.533 8.533 0 0 0 8.533-8.533v-17.067a8.533 8.533 0 0 0-17.066 0v17.067a8.533 8.533 0 0 0 8.533 8.533zM315.734 213.318c2.263 0 4.433-.9 6.033-2.5l12.066-12.066a8.534 8.534 0 1 0-12.066-12.066l-12.066 12.066a8.533 8.533 0 0 0 6.033 14.566zM173.168 210.818a8.533 8.533 0 0 0 11.962-.104 8.533 8.533 0 0 0 .104-11.962l-12.066-12.066a8.534 8.534 0 1 0-12.066 12.066z"
            fill={theme.colors.white}
          />
        </g>
      </g>
    </svg>
  );
};
