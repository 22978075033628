import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { Box } from './Box';
import styled from 'styled-components';
import { theme } from '../themes/theme';

interface PropsCheck {
  backgroundColor: CSSProperties['backgroundColor'];
  size: number;
  isCheck: boolean;
}
const WrapperBox = styled(Box)`
  transition: background-color 200ms linear;
`;
const Checker = styled.div.withConfig({
  shouldForwardProp: prop => !['size', 'backgroundColor', 'isCheck'].includes(prop),
})<PropsCheck>`
  width: ${({ size }) => size - 6}px;
  height: ${({ size }) => size - 6}px;
  border-radius: ${({ size }) => (size - 6) / 2}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transform: translateX(${({ isCheck, size }) => (isCheck ? -size : 0)}px);
  transition: transform 200ms linear;
`;
interface Props {
  size?: number;
  isCheck: boolean;
  changeCheck: Dispatch<SetStateAction<boolean>>;
}
export const Switch: FC<Props> = ({ size = 40, isCheck, changeCheck }) => {
  return (
    <WrapperBox
      width={`${size * 2}px`}
      height={`${size}px`}
      borderRadius={`${size / 2}px`}
      background={isCheck ? theme.colors.softBlueViolet : theme.colors.softBlueViolet_50}
      justify="flex-end"
      padding="0 3px"
      onClick={() => changeCheck(!isCheck)}
    >
      <Checker size={size} backgroundColor={theme.colors.white} isCheck={isCheck} />
    </WrapperBox>
  );
};
