import React, { FC, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import styled from 'styled-components';
import concrete from '../../assets/img/beton.ktx2';
import floor from '../../assets/img/wallTexture/floor.ktx2';
import { Room } from './canvas/classes/Room';
import { PanelConstructor } from './components/PanelConstructor';
import { EDIT_CLASS_OBJECT, KTX2_LOADER, LOAD_CLASS_OBJECT } from '../../helpers/constans';
import { LoadCurtain } from '../../components/LoadCurtain';
import { useIsLoadingQuery } from '../../api/store/query/__generated__/isLoading';
import { WrapperPage } from '../../components/WrapperPage';

const WrapperCanvas = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  //background-color: #22828b;
`;

export const Constructor: FC = () => {
  const { data } = useIsLoadingQuery();
  const [textureConcrete, setTextureConcrete] = useState<THREE.Texture>();
  const [textureFloor, setTextureFloor] = useState<THREE.Texture>();
  const container = useRef<HTMLDivElement>(null);
  const isLoadScene = useRef(false);
  const isLoadTexture = useRef(false);

  useEffect(() => {
    if (textureConcrete && textureFloor && !isLoadTexture.current) {
      const _room = LOAD_CLASS_OBJECT.scene.getObjectByName('room');
      if (!_room) {
        const room = new Room({
          depthRoom: 3,
          heightRoom: 2.8,
          widthRoom: 4,
          baseWallTexture: textureConcrete,
          baseFloorTexture: textureFloor,
          LoadComponent: LOAD_CLASS_OBJECT,
          edit: EDIT_CLASS_OBJECT,
        });
        LOAD_CLASS_OBJECT.scene.add(room);
      }
    }
  }, [textureConcrete, textureFloor]);
  useEffect(() => {
    if (container.current && !isLoadScene.current) {
      isLoadScene.current = true;
      LOAD_CLASS_OBJECT.initScene(container.current);
      LOAD_CLASS_OBJECT.startAnimation();
      KTX2_LOADER.detectSupport(LOAD_CLASS_OBJECT.renderer);
      KTX2_LOADER.loadAsync(floor).then(texture => {
        texture.minFilter = THREE.LinearFilter;
        setTextureFloor(texture);
      });
      KTX2_LOADER.loadAsync(concrete).then(texture => {
        setTextureConcrete(texture);
      });
      EDIT_CLASS_OBJECT.activate(container.current);
    }
    return () => {
      LOAD_CLASS_OBJECT.stopAnimation();
      EDIT_CLASS_OBJECT.deactivateListeners();
    };
  }, []);

  return (
    <WrapperPage isHeader={false}>
      <WrapperCanvas ref={container} />
      <PanelConstructor />
      {data?.isLoading && <LoadCurtain />}
    </WrapperPage>
  );
};
