import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { PatchesName } from '../../../__generated__/types';
import { setSelectPage } from '../../../api/store/functionsWitingCache';

const MenuItemStyle = styled.div`
  cursor: pointer;
  //background-color: #61dafb;
`;
interface Props {
  patch?: PatchesName;
}
export const MenuItem: FC<PropsWithChildren<Props>> = ({ patch, children }) => {
  const handlerClickText = () => {
    if (patch) {
      setSelectPage({
        dataWrite: {
          selectPage: patch,
        },
      });
    }
  };
  return <MenuItemStyle onClick={handlerClickText}>{children}</MenuItemStyle>;
};
