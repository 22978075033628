import g1 from './1-1.webp';
import g2 from './1-2.webp';
import g3 from './1-3.webp';
import g4 from './1-4.webp';
import g5 from './1-5.webp';
import g6 from './1-6.webp';
import g7 from './1-7.webp';
import g8 from './1-8.webp';
import g9 from './1-9.webp';
import g10 from './1-10.webp';
import g11 from './1-11.webp';
import g12 from './1-12.webp';
import g13 from './1-13.webp';
import g14 from './1-14.webp';
import g15 from './1-15.webp';
import g16 from './1-16.webp';
import g17 from './1-17.webp';
import g18 from './1-18.webp';
import g19 from './1-19.webp';
import g20 from './1-20.webp';

export const GALLERY = [
  g1,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  g10,
  g11,
  g12,
  g13,
  g14,
  g15,
  g16,
  g17,
  g18,
  g19,
  g20,
];
