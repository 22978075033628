import React, { FC } from 'react';
import styled from 'styled-components';
import { BlockPartnerType } from '../../../../helpers/types';
import { Box } from '../../../Box';
import { Text } from '../../../Texts/Text';
import { Icon } from '../../../Icons/Icon';
import { theme } from '../../../../themes/theme';
import { TextMultiColor } from '../../../TextMultiColor';

const WrapperIcon = styled(Box)`
  border-bottom: solid 4px ${theme.colors.softBlueViolet};
  margin-top: -80px;
  @media (max-height: 890px) or (max-width: 1320px) {
    width: 140px;
    height: 140px;
  }
`;
const TextMultiMedia = styled(TextMultiColor)`
  @media (max-height: 890px) or (max-width: 1320px) {
    font-size: 20px;
    line-height: 23px;
  }
`;
const TextMedia = styled(Text)`
  @media (max-height: 890px) or (max-width: 1320px) {
    font-size: 46px;
    margin-bottom: 16px;
  }
`;

export const BlocItem: FC<BlockPartnerType> = ({ text, title, icon }) => {
  return (
    <Box width="380px" direction="column">
      <Text
        fontFamily="Neue Machina"
        weight="700"
        size="130px"
        lineHeight="125px"
        color="softBlueViolet_200"
      >
        {title.toUpperCase()}
      </Text>
      <WrapperIcon width="160px" height="160px" padding="22px">
        <Icon name={icon} width="100%" height="100%" />
      </WrapperIcon>
      <Box
        width="100%"
        background={theme.colors.lightGray}
        direction="column"
        borderRadius="30px"
        padding="28px 0 46px 0"
      >
        <TextMedia
          fontFamily="Neue Machina"
          weight="700"
          size="50px"
          lineHeight="48px"
          color="softBlueViolet"
          marginBottom="20px"
        >
          {title.toUpperCase()}
        </TextMedia>
        <TextMultiMedia
          text={text}
          fontFamily="Gilroy"
          textAlign="center"
          fontSize="25px"
          fontWeight="500"
          lineHeight="29px"
        />
      </Box>
    </Box>
  );
};
