import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CheckComponent } from './CheckComponent';

interface PropsStyle {
  delTranslate?: number;
  offset: number;
}
const ContainerStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['delTranslate', 'offset'].includes(prop),
})<PropsStyle>`
  position: relative;
  height: 88px;
  border-radius: 61px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 56px;
  font-family: Gilroy, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
  margin-left: 6px;
  transform: translateY(${({ offset }) => offset || 0}px);
  transition: transform 1200ms ease, opacity 1200ms ease;

  @media (max-height: 890px) or (max-width: 1320px) {
    height: 70px;
    border-radius: 40px;
    font-size: 16px;
  }
`;

interface Props {
  daley: number;
  offset: number;
}
export const ContainerText: FC<PropsWithChildren<Props>> = ({ children, daley, offset }) => {
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.style.transform = 'translateY(0px)';
      divRef.current.style.opacity = '1';
    }
  }, []);
  return (
    <ContainerStyle ref={divRef} offset={offset}>
      <CheckComponent daley={daley} />
      {children}
    </ContainerStyle>
  );
};
