import React, { CSSProperties, FC, JSX, useEffect, useRef } from 'react';
import { Box, PropsStyleBox } from '../../../Box';
import { theme } from '../../../../themes/theme';
import styled from 'styled-components';
import { Text } from '../../../Texts/Text';

const BoxBackground = styled(Box)`
  bottom: -200px;
  transition: transform 400ms ease;
`;
interface PropsText {
  color: CSSProperties['color'];
}
const BoxIcon = styled.div`
  position: relative;
  color: inherit;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  z-index: 1;
`;
const BoxText = styled('div').withConfig({
  shouldForwardProp: prop => !['color'].includes(prop),
})<PropsText>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  transition: all 200ms ease;
  z-index: 1;
`;
const BoxHover = styled(Box)`
  z-index: 2;
`;
const TextHover = styled(Text)`
  color: inherit;
  z-index: 1;
  margin-bottom: -6px;
`;
const CircleInterval = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.softBlueVioletTransparent};
  z-index: -1;
  opacity: 0;
  //transform: scale(5.6, 5.6);
`;
const ContainerHidden = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
`;
interface Props extends PropsStyleBox {
  title: string;
  icon?: JSX.Element;
  delay: number;
  onPress?: () => void;
}
export const ButtonStore: FC<Props> = ({
  title,
  icon,
  delay,
  onPress,
  width = '320px',
  ...props
}) => {
  const animateBack = useRef<HTMLDivElement>(null);
  const animateText = useRef<HTMLDivElement>(null);
  const containerIcon = useRef<HTMLDivElement>(null);
  const circle = useRef<HTMLDivElement>(null);
  const textHover = useRef<HTMLSpanElement>(null);
  const animateTextInterval = useRef<Animation>();
  const animateIconInterval = useRef<Animation>();
  const animateOpacityInterval = useRef<Animation>();
  const timer = useRef<NodeJS.Timer>();
  const isAnimateInterval = useRef(true);

  const handleMouseOver = () => {
    if (animateBack.current && animateText.current) {
      stopIntervalAnimate();
      animateBack.current.style.transform = 'scaleX(0) scaleY(0)';
      animateText.current.style.transform = 'scaleX(1.08) scaleY(1.08)';
      animateText.current.style.color = theme.colors.softBlueViolet;
    }
  };
  const handleMouseOut = () => {
    if (animateBack.current && animateText.current) {
      animateBack.current.style.transform = 'scaleX(1) scaleY(1)';
      animateText.current.style.transform = 'scaleX(1) scaleY(1)';
      animateText.current.style.color = theme.colors.white;
      isAnimateInterval.current = true;
    }
  };
  const handleMouseDown = () => {
    if (animateBack.current && animateText.current) {
      animateBack.current.style.transform = 'scaleX(1) scaleY(1)';
      animateText.current.style.transform = 'scaleX(0.9) scaleY(0.9)';
      animateText.current.style.color = theme.colors.white;
    }
  };
  const handlePress = () => {
    if (onPress) {
      onPress();
    }
  };
  const startIntervalAnimate = () => {
    timer.current = setInterval(() => {
      if (!isAnimateInterval.current) return;
      if (containerIcon.current && textHover.current && circle.current) {
        if (
          animateIconInterval.current &&
          animateTextInterval.current &&
          animateOpacityInterval.current
        ) {
          animateIconInterval.current?.play();
          animateTextInterval.current?.play();
          animateOpacityInterval.current?.play();
        } //
        else {
          animateIconInterval.current = containerIcon.current.animate(
            [
              { transform: 'rotateZ(-20deg)' },
              { transform: 'rotateZ(20deg)' },
              { transform: 'rotateZ(-10deg)' },
              { transform: 'rotateZ(14deg)' },
              { transform: 'rotateZ(0deg)' },
            ],
            {
              duration: 1400,
              easing: 'ease',
              delay,
            }
          );
          animateTextInterval.current = textHover.current.animate(
            [
              { transform: 'translateX(0px)' },
              { transform: 'translateX(0px)' },
              { transform: 'translateX(16px)' },
              { transform: 'translateX(-10px)' },
              { transform: 'translateX(0px)' },
            ],
            {
              duration: 1400,
              easing: 'ease',
              delay,
            }
          );
          animateOpacityInterval.current = circle.current.animate(
            [
              { transform: 'scale(1,1)', opacity: '1' },
              { transform: 'scale(5.4, 5.4)', opacity: '0' },
            ],
            {
              duration: 1300,
              easing: 'ease',
              delay,
            }
          );
        }
      }
    }, 6000);
  };
  const stopIntervalAnimate = () => {
    isAnimateInterval.current = false;
    animateIconInterval.current?.cancel();
    animateTextInterval.current?.cancel();
    animateOpacityInterval.current?.cancel();
  };

  useEffect(() => {
    if (icon) {
      startIntervalAnimate();
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, []);

  return (
    <Box
      position="relative"
      width={width}
      height="70px"
      borderRadius="35px"
      background={theme.colors.lightGray}
      // overflow="hidden"
      {...props}
    >
      <ContainerHidden>
        <BoxBackground
          ref={animateBack}
          position="absolute"
          background={theme.colors.softBlueViolet}
          width={`calc(${width} + 30px)`}
          height={`calc(${width} + 30px)`}
          borderRadius={`calc((${width} + 30px) / 2)`}
        />
      </ContainerHidden>

      <BoxText ref={animateText} color={theme.colors.white}>
        {icon && (
          <BoxIcon ref={containerIcon}>
            <CircleInterval ref={circle} />
            {icon}
          </BoxIcon>
        )}
        <TextHover
          ref={textHover}
          fontFamily="Neue Machina"
          size="30px"
          weight={700}
          lineHeight="40px"
        >
          {title.toUpperCase()}
        </TextHover>
      </BoxText>
      <BoxHover
        onClick={handlePress}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseOut}
        position="absolute"
        width="100%"
        height="100%"
      />
    </Box>
  );
};
