import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../../../../components/Box';
import { theme } from '../../../../../themes/theme';
import { Icon } from '../../../../../components/Icons/Icon';
import { DOOR_HEIGHT, DOOR_WIDTH, EDIT_CLASS_OBJECT } from '../../../../../helpers/constans';
import { DoorObject } from '../../../canvas/classes/DoorObject';
import { Wall } from '../../../canvas/classes/Wall';

const WrapperBox = styled(Box)`
  cursor: pointer;
`;
interface Props {
  handleAddPanelClick: () => void;
}
export const PanelAddDoorAndTexture: FC<Props> = ({ handleAddPanelClick }) => {
  const [isWall] = useState(() => {
    const wall = EDIT_CLASS_OBJECT.getSelectedObject();
    return wall && wall.name === 'wall';
  });
  const addDoor = () => {
    if (!isWall) {
      return;
    }
    const wall = EDIT_CLASS_OBJECT.getSelectedObject();
    if (wall && wall instanceof Wall) {
      const door = new DoorObject({
        width: DOOR_WIDTH,
        height: DOOR_HEIGHT,
      });
      wall.addDoorToWall(door);
    }
  };

  return (
    <Box width="100%" height="100%">
      <Box
        width="220px"
        height="100%"
        background={theme.colors.white}
        borderRadius="20px"
        justify="space-evenly"
      >
        {/*<WrapperBox
          width="60px"
          height="60px"
          borderRadius="30px"
          background={isWall ? theme.colors.softBlueViolet : theme.colors.softBlueViolet_50}
          onClick={changePanels}
        >
          <Icon name="configTexture" width="100%" height="100%" />
        </WrapperBox>*/}
        <WrapperBox
          width="60px"
          height="60px"
          borderRadius="30px"
          background={theme.colors.softBlueViolet}
          onClick={handleAddPanelClick}
        >
          <Icon name="addTexture" width="100%" height="100%" />
        </WrapperBox>
        <WrapperBox
          width="60px"
          height="60px"
          borderRadius="30px"
          background={isWall ? theme.colors.softBlueViolet : theme.colors.softBlueViolet_50}
          onClick={addDoor}
        >
          <Icon name="addDoor" width="100%" height="100%" />
        </WrapperBox>
      </Box>
    </Box>
  );
};
