import styled from 'styled-components';
import { CSSProperties } from 'react';
interface CustomInputStyleType {
  isError?: boolean;
  color?: string;
  letterSpacing?: CSSProperties['letterSpacing'];
}
export const CustomInputStyle = styled.input.withConfig({
  shouldForwardProp: prop => !['isError', 'color', 'letterSpacing'].includes(prop),
})<CustomInputStyleType>`
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme, isError, color }) =>
    isError ? theme.colors.redLight : color ? color : theme.colors.lightGray};
  border: 0;
  padding: 0 20px;
  margin-left: -20px;
  font-family: Gilroy, serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: Gilroy, serif;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.lightGray_100};
  }
`;
