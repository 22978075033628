import React, { FC, useEffect, useRef } from 'react';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';
import { Text } from '../../../Texts/Text';
import { QuestionDataType } from '../../../../helpers/constans';
import styled from 'styled-components';
import { SpanColor } from '../../../SpanColor';

const BoxAnim = styled(Box)`
  transition: all 400ms ease-in-out;
`;
const BoxButton = styled(Box)`
  cursor: pointer;
`;
const TextQuestions = styled(Text)`
  @media (max-height: 890px) or (max-width: 1320px) {
    font-size: 26px;
  }
`;
const TextAnswer = styled(Text)`
  @media (max-height: 890px) or (max-width: 1320px) {
    font-size: 22px;
  }
`;
export const ItemQuestion: FC<QuestionDataType> = ({ question, answer }) => {
  const isVisible = useRef(false);
  const boxAnim = useRef<HTMLDivElement>(null);
  const text = useRef<HTMLSpanElement>(null);
  const currentHeight = useRef(0);
  const handleClick = () => {
    isVisible.current = !isVisible.current;
    if (boxAnim.current && text.current) {
      if (isVisible.current) {
        boxAnim.current.style.height = `${currentHeight.current}px`;
        boxAnim.current.style.marginTop = '24px';
        text.current.innerText = '-';
      } else {
        boxAnim.current.style.height = '0px';
        boxAnim.current.style.marginTop = '0px';
        text.current.innerText = '+';
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (boxAnim.current) {
        if (currentHeight.current === 0) {
          currentHeight.current = boxAnim.current.getBoundingClientRect().height;
        }
        boxAnim.current.style.height = '0px';
        boxAnim.current.style.marginTop = '0px';
      }
    }, 50);
  }, []);
  return (
    <Box
      width="100%"
      direction="column"
      align="flex-start"
      background={theme.colors.lightGray}
      borderRadius="30px"
      margin="0 0 10px 0"
      padding="24px 40px"
    >
      <Box width="100%" justify="space-between">
        <TextQuestions width="80%" fontFamily="Neue Machina" weight={700} size="30px">
          {question}
          <SpanColor>?</SpanColor>
        </TextQuestions>
        <BoxButton width="40px" height="40px" onClick={handleClick}>
          <Text
            ref={text}
            fontFamily="Neue Machina"
            weight={700}
            size="30px"
            color="softBlueViolet"
          >
            +
          </Text>
        </BoxButton>
      </Box>
      <BoxAnim
        ref={boxAnim}
        width="100%"
        justify="flex-start"
        margin="24px 0 0 0"
        overflow="hidden"
      >
        <TextAnswer width="90%" fontFamily="Gilroy" weight={500} size="25px" lineHeight="30px">
          {answer}
        </TextAnswer>
      </BoxAnim>
    </Box>
  );
};
