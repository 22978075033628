import React from 'react';
import { ItemBlockIcons } from './components/ItemBlockIcons';
import { Images } from './components/Images';
import { Glacier } from './components/Glacier';
import { Insulation } from './components/Insulation';
import { Painting } from './components/Painting';

export const BlockIcons = () => {
  return (
    <>
      <ItemBlockIcons text="Готовая отделка с 3D рисунком">
        <Images />
      </ItemBlockIcons>
      <ItemBlockIcons text="Тепло и звуко изоляция">
        <Insulation />
      </ItemBlockIcons>
      <ItemBlockIcons text="Матовое или глянцевое покрытие">
        <Painting />
      </ItemBlockIcons>
      <ItemBlockIcons text="Высокая прочность">
        <Glacier />
      </ItemBlockIcons>
    </>
  );
};
