import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { TextTitle } from '../../TextTitle';
import { ItemText } from './components/ItemText';
import { useDefineScrollForAnchor } from '../../../hooks/useDefineScrollForAnchor';

const WrapperGrid = styled(Box)`
  flex-wrap: wrap;
  gap: 20px;
  column-gap: 50px;
`;
const TEXTS = [
  'Ознакомтесь с нашей технологией на сайте',
  'Заполните форму',
  'Предложите свои идеи по сотрудничеству',
  'Мы скоро с вами свяжемся',
];
export const BlockBecomePartner: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  useDefineScrollForAnchor({
    childRef: ref,
    orderNumber: 5,
  });
  return (
    <Box ref={ref} width="100%" padding="200px 0 0 0" direction="column">
      <TextTitle text={['как стать %партнером%?']} />
      <WrapperGrid height="264px" direction="column">
        {TEXTS.map((text, index) => (
          <ItemText
            key={text}
            numberItem={(index + 1).toString()}
            text={text}
            delay={index * 1000}
          />
        ))}
      </WrapperGrid>
    </Box>
  );
};
