import React from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { ContainerText } from './components/ContainerText';
import { PhoneShow } from './components/PhoneShow';
import { TextTitle } from '../../TextTitle';

const Wrapper = styled(Box)`
  position: relative;
  @media (max-height: 720px) {
    align-items: flex-start;
    padding-top: 20px;
  }
`;
const Container = styled(Box)`
  position: relative;
  //@media (max-height: 890px) or (max-width: 1320px) {
  //  height: 100%;
  //  width: 100%;
  //}
  //@media (max-height: 780px) {
  //    align-items: flex-start;
  //  //padding-top: 20px;
  //}
`;
const TextTitleMedia = styled(TextTitle)`
  @media (max-height: 720px) {
    padding-left: 100px;
  }
`;

export const BlockApp = () => {
  return (
    <Wrapper width="100%" height="100%">
      <Container width="1180px">
        <Box height="100%" direction="column" align="flex-start" justify="center">
          <TextTitleMedia
            text={['Платформа', '%smart 3d decor%']}
            textAlign="left"
            textProps={{ marginBottom: '10px' }}
          />
          <ContainerText daley={5} offset={100}>
            Открывает доступ к миллионам вариантов
            <br />
            дизайна для Вашего интерьера
          </ContainerText>
          <ContainerText daley={2000} offset={200}>
            Встроенный в приложение ИИ помогает перенести
            <br />
            интерьер Вашего помещения в 3D конструктор
          </ContainerText>
          <ContainerText daley={4000} offset={300}>
            Функция дополненной реальности позволяет
            <br />
            визуализировать декор на стенах и потолках
          </ContainerText>
          <ContainerText daley={6000} offset={400}>
            Делает просчет общей <br /> площади автоматически
          </ContainerText>
          <ContainerText daley={8000} offset={500}>
            Формирует заказ для производства
          </ContainerText>
        </Box>
        <PhoneShow />
      </Container>
    </Wrapper>
  );
};
