import React, { FC } from 'react';
import styled from 'styled-components';
import { useCategoriesGetAllQuery } from '../../../../../graphql/queries/__generated__/categoriesGetAll';
import { ItemCategory } from '../components/ItemCategory';
import { LoadCurtain } from '../../../../../components/LoadCurtain';
import { Box } from '../../../../../components/Box';
import { LoadIndicator } from '../../../../../components/LoadIndicator';

export const WrapperBox = styled.div`
  //gap: 20px;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: auto;
  background-color: white;
  border-radius: 20px;
`;

interface Props {
  selectCategory(id: number): void;
}

export const PanelCategories: FC<Props> = ({ selectCategory }) => {
  const { data: dataCategories, loading } = useCategoriesGetAllQuery();
  const getListCategories = () => {
    if (dataCategories?.categoriesGetAll.categories?.length) {
      return dataCategories.categoriesGetAll.categories.map((item, index) => (
        <ItemCategory
          key={index.toFixed()}
          title={item?.name || ''}
          idCategory={item?.id || 0}
          selectCategory={selectCategory}
        />
      ));
    }
    return null;
  };
  return (
    <WrapperBox>
      {getListCategories()}
      {loading && (
        <Box width="100%" height="100%">
          <LoadIndicator size={100} />
        </Box>
      )}
    </WrapperBox>
  );
};
