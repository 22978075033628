import React, { FC, useState } from 'react';
import { Box } from '../../../../../components/Box';
import { theme } from '../../../../../themes/theme';
import { Text } from '../../../../../components/Texts/Text';
import { RadioButton } from '../components/RadioButton';
import { Slider } from '../components/Slider';
import { Switch } from '../../../../../components/Switch';
import { SideLookDoor, SideOpeningDoor } from '../../../canvas/types';
import { EDIT_CLASS_OBJECT } from '../../../../../helpers/constans';
import { DoorObject } from '../../../canvas/classes/DoorObject';

export const PanelDoor: FC = () => {
  const [sideDoor, setSideDoor] = useState<SideOpeningDoor>(() => {
    const obj = EDIT_CLASS_OBJECT.getSelectedObject();
    if (obj && obj instanceof DoorObject) {
      return obj.sideOpeningDoor;
    }
    return 'in';
  });
  const [openingDoor, setOpeningDoor] = useState<SideLookDoor>(() => {
    const obj = EDIT_CLASS_OBJECT.getSelectedObject();
    if (obj && obj instanceof DoorObject) {
      return obj.openingLookDoor;
    }
    return 'left';
  });
  const [isOpeningDoor, setIsOpeningDoor] = useState(false);

  const handleSideDoor = (side: SideOpeningDoor) => {
    const obj = EDIT_CLASS_OBJECT.getSelectedObject();
    if (obj && obj instanceof DoorObject) {
      setSideDoor(side);
      obj.changeSideOpening(side);
      if (openingDoor === 'right') {
        setOpeningDoor('left');
        obj.changeOpeningDoor('left');
      }
    }
  };
  const handleOpeningDoor = (opening: SideLookDoor) => {
    const obj = EDIT_CLASS_OBJECT.getSelectedObject();
    if (obj && obj instanceof DoorObject) {
      setOpeningDoor(opening);
      obj.changeOpeningDoor(opening);
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      background={theme.colors.white}
      borderRadius="20px"
      direction="column"
    >
      <Text fontFamily="Neue Machina" size="20px" weight={700} marginBottom="30px">
        Сторона открытия
      </Text>
      <Box width="100%" justify="space-between" padding="0 40px" margin="0 0 10px 0">
        <RadioButton
          changeState={() => handleOpeningDoor('left')}
          title="Влево"
          isActive={openingDoor === 'left'}
        />
        <RadioButton
          changeState={() => handleSideDoor('in')}
          title="Внутрь"
          isActive={sideDoor === 'in'}
        />
      </Box>
      <Box width="100%" justify="space-between" padding="0 40px" margin="0 0 40px 0">
        <RadioButton
          changeState={() => handleOpeningDoor('right')}
          title="Вправо"
          isActive={openingDoor === 'right'}
        />
        <RadioButton
          changeState={() => handleSideDoor('out')}
          title="Наружу"
          isActive={sideDoor === 'out'}
        />
      </Box>
      <Box width="100%" justify="space-between" margin="0 0 16px 0" padding="0 40px">
        <Switch isCheck={isOpeningDoor} changeCheck={setIsOpeningDoor} />
        <Text fontFamily="Neue Machina" size="20px" weight={700}>
          {isOpeningDoor ? 'Открывать дверь' : 'Двигать дверь'}
        </Text>
      </Box>
      <Slider isOpening={isOpeningDoor} />
    </Box>
  );
};
