import React, { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../themes/theme';
import { Text } from '../../../Texts/Text';
import { CheckComponent } from '../../BlockApp/components/CheckComponent';

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 451px;
  height: 120px;
  border-radius: 60px;
  background-color: ${theme.colors.lightGray};
  padding-left: 60px;
`;

interface Props {
  numberItem: string;
  text: string;
  delay: number;
}

export const ItemText: FC<Props> = ({ text, numberItem, delay }) => {
  return (
    <WrapperText>
      <Text fontFamily="Gilroy" size="25px" weight="700" lineHeight="30px">
        {`${numberItem}. `}
        <Text fontFamily="Gilroy" size="25px" weight="500" lineHeight="39px">
          {text}
        </Text>
      </Text>
      <CheckComponent daley={delay} size={58} />
    </WrapperText>
  );
};
