import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type OrderFormSubmitMutationVariables = Types.Exact<{
  input: Types.OrderFormInput;
}>;


export type OrderFormSubmitMutation = { __typename?: 'Mutation', orderFormSubmit: { __typename?: 'OrderFormSubmitResponse', fullName: string, email: string, address: string, phoneNumber: string } };


export const OrderFormSubmitDocument = gql`
    mutation OrderFormSubmit($input: OrderFormInput!) {
  orderFormSubmit(input: $input) {
    fullName
    email
    address
    phoneNumber
  }
}
    `;
export type OrderFormSubmitMutationFn = Apollo.MutationFunction<OrderFormSubmitMutation, OrderFormSubmitMutationVariables>;

/**
 * __useOrderFormSubmitMutation__
 *
 * To run a mutation, you first call `useOrderFormSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderFormSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderFormSubmitMutation, { data, loading, error }] = useOrderFormSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderFormSubmitMutation(baseOptions?: Apollo.MutationHookOptions<OrderFormSubmitMutation, OrderFormSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderFormSubmitMutation, OrderFormSubmitMutationVariables>(OrderFormSubmitDocument, options);
      }
export type OrderFormSubmitMutationHookResult = ReturnType<typeof useOrderFormSubmitMutation>;
export type OrderFormSubmitMutationResult = Apollo.MutationResult<OrderFormSubmitMutation>;
export type OrderFormSubmitMutationOptions = Apollo.BaseMutationOptions<OrderFormSubmitMutation, OrderFormSubmitMutationVariables>;