import { DocumentNode } from 'graphql/language';
import { customWriteQuery } from './customWriteQuery';
import {
  OpenPanelAddImageAndDoorDocument,
  OpenPanelAddImageAndDoorQuery,
  OpenPanelAddImageAndDoorQueryVariables,
} from './query/__generated__/openPanelAddImageAndDoor';
import {
  OpenPanelSettingsDoorDocument,
  OpenPanelSettingsDoorQuery,
  OpenPanelSettingsDoorQueryVariables,
} from './query/__generated__/openPanelSettingsDoor';
import {
  ChangeSelectDoorDocument,
  ChangeSelectDoorQuery,
  ChangeSelectDoorQueryVariables,
} from './query/__generated__/changeSelectDoor';
import {
  IsLoadingDocument,
  IsLoadingQuery,
  IsLoadingQueryVariables,
} from './query/__generated__/isLoading';
import {SelectPageDocument, SelectPageQuery, SelectPageQueryVariables} from "./query/__generated__/selectPage";

interface PropsWriteQuery<T, U> {
  dataWrite: T;
  _variables?: U;
}
interface Props extends PropsWriteQuery<object, object> {
  node: DocumentNode;
}
function writeQueryCache(props: Props): void {
  customWriteQuery({ ...props });
}

export function setIsOpenPanelAddTextureAndDoor(
  props: PropsWriteQuery<OpenPanelAddImageAndDoorQuery, OpenPanelAddImageAndDoorQueryVariables>
): void {
  writeQueryCache({ ...props, node: OpenPanelAddImageAndDoorDocument });
}
export function setPanelsDoor(
  props: PropsWriteQuery<OpenPanelSettingsDoorQuery, OpenPanelSettingsDoorQueryVariables>
): void {
  writeQueryCache({ ...props, node: OpenPanelSettingsDoorDocument });
}
export function setChangeSelectDoor(
  props: PropsWriteQuery<ChangeSelectDoorQuery, ChangeSelectDoorQueryVariables>
): void {
  writeQueryCache({ ...props, node: ChangeSelectDoorDocument });
}
export function setIsLoading(
  props: PropsWriteQuery<IsLoadingQuery, IsLoadingQueryVariables>
): void {
  writeQueryCache({ ...props, node: IsLoadingDocument });
}
export function setSelectPage(
  props: PropsWriteQuery<SelectPageQuery, SelectPageQueryVariables>
): void {
  writeQueryCache({ ...props, node: SelectPageDocument });
}
