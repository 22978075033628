import React, { CSSProperties, FC, ReactNode } from 'react';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';
import { Text } from '../../../Texts/Text';
import { ButtonCircle } from '../../../ButtonCircle';
import styled from 'styled-components';

const ContButton = styled.div`
  position: absolute;
  right: -20px;
`;
const WrapperButton = styled(Box)`
  @media (max-height: 890px) or (max-width: 1320px) {
    height: 120px;
  }
`;
const TextMedia = styled(Text)`
  @media (max-height: 890px) or (max-width: 1320px) {
    font-size: 23px;
  }
`;
interface Props {
  title: string;
  subTitle: string | Array<string | ReactNode>;
  margin?: CSSProperties['margin'];
  iconButton?: ReactNode;
  onClick?: () => void;
  delay?: number;
}
export const ButtonItem: FC<Props> = ({ title, subTitle, margin, iconButton, onClick, delay }) => {
  return (
    <WrapperButton
      position="relative"
      width="400px"
      height="140px"
      borderRadius="30px"
      background={theme.colors.lightGray}
      direction="column"
      align="flex-start"
      margin={margin || '0'}
      padding="0 0 0 40px"
    >
      <TextMedia fontFamily="Gilroy" weight={700} size="25px" lineHeight="30px">
        {title}
      </TextMedia>
      <TextMedia fontFamily="Gilroy" weight={500} size="25px" lineHeight="29px">
        {subTitle}
      </TextMedia>
      <ContButton>
        <ButtonCircle isAnimate size={70} onButtonClick={onClick} delay={delay} interval={18000}>
          {iconButton}
        </ButtonCircle>
      </ContButton>
    </WrapperButton>
  );
};
