import React, { CSSProperties, forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { ITheme } from '../../themes/styled';

export interface PropsTextStyle {
  fontFamily: 'Neue Machina' | 'Gilroy';
  width?: CSSProperties['width'];
  size?: CSSProperties['fontSize'];
  weight?: CSSProperties['fontWeight'];
  marginBottom?: CSSProperties['marginBottom'];
  color?: keyof ITheme['colors'];
  textAlign?: CSSProperties['textAlign'];
  lineHeight?: CSSProperties['lineHeight'];
}

const TextStyle = styled('span').withConfig({
  shouldForwardProp: prop =>
    !['weight', 'marginBottom', 'textAlign', 'lineHeight', 'width'].includes(prop),
})<PropsTextStyle>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : 'inherit')};
  font-family: ${({ fontFamily }) => fontFamily}, serif;
  width: ${({ width }) => width || 'auto'};
  font-size: ${({ size }) => size || '14px'};
  font-weight: ${({ weight }) => weight || 'normal'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  ${props =>
    !!props?.lineHeight &&
    css<PropsTextStyle>`
      line-height: ${props => props.lineHeight};
    `};
  white-space: pre-line;
`;

// export const Text: FC<PropsWithChildren<PropsTextStyle>> = ({ children, ...props }) => {
//   return <TextStyle {...props}>{children}</TextStyle>;
// };
export const Text = forwardRef<HTMLSpanElement, PropsWithChildren<PropsTextStyle>>(
  ({ children, ...props }, ref) => {
    return (
      <TextStyle ref={ref} {...props}>
        {children}
      </TextStyle>
    );
  }
);
Text.displayName = 'Text';
