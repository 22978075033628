/* eslint-disable no-var */
import { ParamsConstructorWall } from './Wall';
import { Floor } from './Floor';

export class Ceiling extends Floor {
  constructor(params: ParamsConstructorWall) {
    super(params);
    this.name = 'ceiling';
  }
}
