import { ITheme } from './styled';
export const theme: ITheme = {
  colors: {
    white: '#ffffff',
    softBlueViolet: '#826EEE',
    softBlueViolet_100: '#F3F0FD',
    softBlueViolet_50: '#DAD3FA',
    softBlueViolet_200: '#F3F1FE',
    softBlueVioletDisable: '#BDB3F3',
    softBlueVioletTransparent: 'rgba(130,110,238,0.7)',
    black: '#000000',
    blackTransparent: 'rgba(0,0,0,0.61)',
    lightGray: '#f8f8f8',
    lightGray_100: '#AEAEAE',
    red: '#ff0000',
    redLight: '#FDEBEB',
  },
};
