import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonBlock } from './components/ButtonBlock';
import { PanelWrapperBottom } from './panels/PanelWrapperBottom';
import { PanelMeasure } from './panels/PanelMeasure';
import { PanelAddDoorAndTexture } from './panels/PanelAddDoorAndTexture';
import { ChangePanelType } from '../../../../helpers/types';
import { useOpenPanelAddImageAndDoorQuery } from '../../../../api/store/query/__generated__/openPanelAddImageAndDoor';
import { PanelDoor } from './panels/PanelDoor';
import { useOpenPanelSettingsDoorQuery } from '../../../../api/store/query/__generated__/openPanelSettingsDoor';
import { EDIT_CLASS_OBJECT } from '../../../../helpers/constans';
import { DoorObject } from '../../canvas/classes/DoorObject';
import { PanelSelectTexture } from './panels/PanelSelectTexture';
import { Wall } from '../../canvas/classes/Wall';
import { PanelCategories } from './panels/PanelCategories';
import { PanelChangeTexture } from './panels/PanelChangeTexture';
import { PanelTotal } from './panels/PanelTotal';

export const WrapperPanel = styled.div`
  width: 430px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: background-color 600ms ease, transform 600ms ease;
  background-color: rgba(248, 248, 248, 0.28);
  &:hover {
    background-color: rgba(248, 248, 248, 0.59);
  }
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  user-select: none;
`;

export const PanelConstructor: FC = () => {
  const { data: dataAddDoorAndTexturePanel } = useOpenPanelAddImageAndDoorQuery();
  const { data: dataDoorPanel } = useOpenPanelSettingsDoorQuery();

  const [panelMeasure, setPanelMeasure] = useState<ChangePanelType>(getDefaultStatePanel());
  const [panelDoor, setPanelDoor] = useState<ChangePanelType>(getDefaultStatePanel());
  const [panelAddDoorAndTexture, setPanelAddDoorAndTexture] = useState<ChangePanelType>(
    getDefaultStatePanel()
  );
  const [panelSelectTextures, setPanelSelectTextures] = useState<ChangePanelType>(
    getDefaultStatePanel()
  );
  const [panelChangeTexture, setPanelChangeTexture] = useState<ChangePanelType>(
    getDefaultStatePanel()
  );
  const [panelCategories, setPanelCategories] = useState<ChangePanelType>(getDefaultStatePanel());
  const [panelTotal, setPanelTotal] = useState<ChangePanelType>(getDefaultStatePanel());
  const wrapper = useRef<HTMLDivElement>(null);
  const selectIdCategory = useRef(0);
  const isSelectCategory = useRef(false);

  const removeDoor = () => {
    const door = EDIT_CLASS_OBJECT.getSelectedObject();
    if (door && door instanceof DoorObject) {
      door.removeSelf();
    }
  };
  const removePanels = () => {
    const wall = EDIT_CLASS_OBJECT.getSelectedObject();
    if (wall && wall instanceof Wall) {
      wall.removePanels();
      setPanelChangeTexture(prev => getClose(prev));
      EDIT_CLASS_OBJECT.cleanSelectObject();
    }
  };
  const handlePressItemCategory = (id: number) => {
    selectIdCategory.current = id;
    setPanelCategories(prev => getClose(prev));
  };
  const handlePressTexture = () => {
    setPanelSelectTextures(prev => getClose(prev));
  };
  const closePanelAddDoorAndTexture = () => {
    setPanelAddDoorAndTexture(prev => {
      return { ...prev, isVisible: false };
    });
    if (isSelectCategory.current) {
      isSelectCategory.current = false;
      setPanelCategories(prev => getVisible(prev));
    }
  };
  const closePanelCategories = () => {
    setPanelCategories(prev => {
      return { ...prev, isVisible: false };
    });
    const wall = EDIT_CLASS_OBJECT.getSelectedObject();
    if (wall) {
      setPanelSelectTextures(prev => getVisible(prev));
    }
  };
  const closePanelSelectTexture = () => {
    setPanelSelectTextures(prev => {
      return { ...prev, isVisible: false };
    });
    const wall = EDIT_CLASS_OBJECT.getSelectedObject();
    if (wall) {
      setPanelChangeTexture(prev => getVisible(prev));
    }
  };
  const openPanelCategory = () => {
    isSelectCategory.current = true;
    setPanelAddDoorAndTexture(prev => getClose(prev));
  };
  function getClose(prev: ChangePanelType): ChangePanelType {
    return { ...prev, ceilRandom: Math.ceil(Math.random() * 1000) };
  }
  function getVisible(prev: ChangePanelType): ChangePanelType {
    return { ...prev, isVisible: true };
  }
  function getDefaultStatePanel(): ChangePanelType {
    return {
      isVisible: false,
      ceilRandom: Math.ceil(Math.random() * 1000),
    };
  }

  useEffect(() => {
    if (dataAddDoorAndTexturePanel?.isOpenPanelAddImageAndDoor) {
      setPanelMeasure(prev => {
        return getClose(prev);
      });
      setPanelTotal(prev => getClose(prev));
      const wall = EDIT_CLASS_OBJECT.getSelectedObject();
      if (wall && wall instanceof Wall) {
        if (wall.panels !== null) {
          setPanelChangeTexture(prev => getVisible(prev));
        } else {
          setPanelAddDoorAndTexture(prev => getVisible(prev));
        }
      }
    } //
    else {
      setPanelAddDoorAndTexture(prev => getClose(prev));
      setPanelChangeTexture(prev => getClose(prev));
      setPanelCategories(prev => getClose(prev));
      setPanelSelectTextures(prev => getClose(prev));
      isSelectCategory.current = false;
    }
  }, [dataAddDoorAndTexturePanel]);
  useEffect(() => {
    if (dataDoorPanel?.isOpenPanelSettingsDoor) {
      setPanelMeasure(prev => {
        return getClose(prev);
      });
      setPanelAddDoorAndTexture(prev => {
        return getClose(prev);
      });
      setPanelDoor(prev => {
        return getVisible(prev);
      });
    } //
    else {
      setPanelDoor(prev => {
        return getClose(prev);
      });
    }
  }, [dataDoorPanel]);

  return (
    <WrapperPanel ref={wrapper}>
      <ButtonBlock
        openPaneMeasure={() => {
          setPanelMeasure(prev => {
            return { ...prev, isVisible: true };
          });
        }}
        openPanelTotal={() => {
          setPanelTotal(prev => getVisible(prev));
        }}
      />
      {panelMeasure.isVisible && (
        <PanelWrapperBottom
          valueClose={panelMeasure.ceilRandom}
          closeModal={() => {
            setPanelMeasure(prev => {
              return { ...prev, isVisible: false };
            });
          }}
          height={320}
          angleArrow={-90}
        >
          <PanelMeasure />
        </PanelWrapperBottom>
      )}
      {panelAddDoorAndTexture.isVisible && (
        <PanelWrapperBottom
          valueClose={panelAddDoorAndTexture.ceilRandom}
          closeModal={closePanelAddDoorAndTexture}
          height={100}
          angleArrow={-90}
        >
          <PanelAddDoorAndTexture handleAddPanelClick={openPanelCategory} />
        </PanelWrapperBottom>
      )}
      {panelDoor.isVisible && (
        <PanelWrapperBottom
          valueClose={panelDoor.ceilRandom}
          closeModal={() => {
            setPanelDoor(prev => {
              return { ...prev, isVisible: false };
            });
          }}
          height={340}
          angleArrow={-90}
          isRemove
          onRemove={removeDoor}
        >
          <PanelDoor />
        </PanelWrapperBottom>
      )}
      {panelSelectTextures.isVisible && (
        <PanelWrapperBottom
          valueClose={panelSelectTextures.ceilRandom}
          closeModal={closePanelSelectTexture}
          height={500}
          angleArrow={0}
          isLeftPosition
        >
          <PanelSelectTexture
            onPressTexture={handlePressTexture}
            categoryId={selectIdCategory.current}
          />
        </PanelWrapperBottom>
      )}
      {panelCategories.isVisible && (
        <PanelWrapperBottom
          valueClose={panelCategories.ceilRandom}
          closeModal={closePanelCategories}
          height={360}
          angleArrow={0}
          isLeftPosition
        >
          <PanelCategories selectCategory={handlePressItemCategory} />
        </PanelWrapperBottom>
      )}
      {panelChangeTexture.isVisible && (
        <PanelWrapperBottom
          valueClose={panelChangeTexture.ceilRandom}
          closeModal={() => {
            setPanelChangeTexture(prev => {
              return { ...prev, isVisible: false };
            });
            if (isSelectCategory.current) {
              isSelectCategory.current = false;
              setPanelCategories(prev => getVisible(prev));
            }
          }}
          height={400}
          angleArrow={0}
          isLeftPosition
          isRemove
          onRemove={removePanels}
        >
          <PanelChangeTexture
            closePanel={() => {
              setPanelChangeTexture(prev => getClose(prev));
              isSelectCategory.current = true;
            }}
          />
        </PanelWrapperBottom>
      )}
      {panelTotal.isVisible && (
        <PanelWrapperBottom
          valueClose={panelTotal.ceilRandom}
          closeModal={() => {
            setPanelTotal(prev => {
              return { ...prev, isVisible: false };
            });
          }}
          height={300}
          angleArrow={-90}
        >
          <PanelTotal />
        </PanelWrapperBottom>
      )}
    </WrapperPanel>
  );
};
