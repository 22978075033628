import React from 'react';
import { Box } from '../../Box';
import { useSetParamsElement } from '../../../helpers/hooks/useSetParamsElement';
import { Text } from '../../Texts/Text';
import { theme } from '../../../themes/theme';

export const BlockFooterEnd = () => {
  const { divRef } = useSetParamsElement('blockFooterEnd');
  return (
    <Box ref={divRef} width="100%" height="45px" overflow="hidden" background={theme.colors.black}>
      <Text
        fontFamily={'Gilroy'}
        color="white"
      >{`“Smart3D Corporation” | Все права защищены | 2024`}</Text>
    </Box>
  );
};
