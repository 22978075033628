import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/theme';
import { Text } from '../../../components/Texts/Text';

interface PropsButtonSimpleStyle {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}
const ButtonSimpleStyle = styled.div.withConfig({
  shouldForwardProp: prop => !['height', 'width'].includes(prop),
})<PropsButtonSimpleStyle>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || '245px'};
  height: ${({ height }) => height || '50px'};
  border-radius: ${({ height }) => (height ? `calc(${height} / 2)` : '25px')};
  background-color: ${theme.colors.softBlueViolet};
  color: ${theme.colors.white};
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${theme.colors.softBlueViolet_100};
    color: ${theme.colors.softBlueViolet};
  }
  &:active {
    transform: scale(0.9, 0.9);
  }
`;
interface Props extends PropsButtonSimpleStyle {
  clickButton: () => void;
  title: string;
}
export const ButtonSimple: FC<Props> = ({ clickButton, title, ...props }) => {
  return (
    <ButtonSimpleStyle onClick={clickButton} {...props}>
      <Text fontFamily="Neue Machina" size="20px" weight="700">
        {title.toUpperCase()}
      </Text>
    </ButtonSimpleStyle>
  );
};
