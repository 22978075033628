import React from 'react';
import { theme } from '../../../../themes/theme';

export const Painting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 60 60">
      <g>
        <g fill="#000" fillRule="nonzero">
          <path
            d="M59.87 49.493a1 1 0 0 0-.013-1.008L45.149 23.972A2.009 2.009 0 0 0 43.435 23H34v-3h1a2 2 0 0 0 2-2V4a4 4 0 1 0-8 0v14a2 2 0 0 0 2 2h1v3H16.565a2.011 2.011 0 0 0-1.714.971L.143 48.485A1 1 0 0 0 1 50h1.234L.143 53.485A1 1 0 0 0 1 55h1.234L.143 58.485A1 1 0 0 0 1 60h58a1 1 0 0 0 .857-1.515L57.766 55H59a1 1 0 0 0 .857-1.515L57.766 50H59a1 1 0 0 0 .87-.507zM31 4a2 2 0 1 1 4 0v14h-4zM16.565 25H32v1.377a1 1 0 0 1-.794.979l-14.236 3a5.023 5.023 0 0 0-3.97 4.89V38a3 3 0 0 0 3 3h1v3a2 2 0 0 0 2 2h26a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H19a2 2 0 0 0-2 2v3h-1a1 1 0 0 1-1-1v-2.754a3.014 3.014 0 0 1 2.382-2.936l14.236-3A3.014 3.014 0 0 0 34 26.377V25h9.435l13.8 23H2.767zM19 44v-8h26v8zm38.233 14H2.767l1.8-3h50.867zm0-5H2.767l1.8-3h50.867z"
            fill={theme.colors.white}
          />
          <path
            d="M24 40h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM39 40H28a1 1 0 0 0 0 2h11a1 1 0 0 0 0-2z"
            fill={theme.colors.white}
          />
        </g>
      </g>
    </svg>
  );
};
