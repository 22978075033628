import React, { CSSProperties, useEffect, useRef } from 'react';
import { Box } from '../../Box';
import styled from 'styled-components';
import { ButtonItem } from './components/ButtonItem';
import { ArrowSVG } from './components/ArrowSVG';
import { TriangleSvg } from './components/TriangleSVG';
import { MenuItemsHeader } from '../../../helpers/constans';
import { TextTitle } from '../../TextTitle';
import { IMAGE_URLS } from '../../PreloadImages/helpers/constans';

const ContainerImg = styled.div`
  width: 691px;
  height: 773px;
  position: absolute;
  bottom: 0;
  left: -200px;
  z-index: 0;
  background-image: url('${IMAGE_URLS.man_2}');
  background-size: cover;
  @media (max-height: 890px) or (max-width: 1320px) {
    width: calc(691px * 0.8);
    height: calc(773px * 0.8);
    left: -100px;
  }
  @media (max-height: 720px) {
    width: calc(691px * 0.76);
    height: calc(773px * 0.76);
    left: -100px;
  }
`;
interface PropsContainerAnimate {
  duration: string;
  timeFunc: CSSProperties['animationTimingFunction'];
}

const ContainerAnimate = styled(Box).withConfig({
  shouldForwardProp: prop => !['duration', 'timeFunc'].includes(prop),
})<PropsContainerAnimate>`
  opacity: 0;
  transform: translateX(600px);
  transition: ${({ duration, timeFunc }) => `all ${duration} ${timeFunc}`};
  @media (max-height: 720px) {
    height: 94%;
  }
`;
const ContainerButtons = styled.div`
  height: 560px;
  width: 860px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  @media (max-height: 890px) or (max-width: 1320px) {
    height: 460px;
    //width: 760px;
  }
`;
const WrapperBox = styled(Box)`
  @media (max-height: 890px) {
    justify-content: flex-start;
    padding-top: 80px;
  }
  @media (max-height: 720px) {
    justify-content: flex-start;
    padding-top: 50px;
  }
`;
const STEPS = [
  {
    title: 'Шаг 1',
    delay: 0,
    isFirst: true,
    subTitle: 'Посетите платформу',
    icon: <ArrowSVG />,
    onClick: () => {
      window.scrollTo({ top: MenuItemsHeader.downloadApp.scrollTo, behavior: 'smooth' });
    },
  },
  {
    title: 'Шаг 2',
    delay: 3000,
    isFirst: false,
    subTitle: 'Откройте 3D-Market',
    icon: <TriangleSvg />,
  },
  {
    title: 'Шаг 3',
    delay: 6000,
    isFirst: true,
    subTitle: 'Выберите 3D декор',
    icon: <TriangleSvg />,
  },
  {
    title: 'Шаг 4',
    delay: 9000,
    isFirst: false,
    subTitle: ['Создайте дизайн', <br key="1" />, 'в конструкторе'],
    icon: <TriangleSvg />,
  },
  {
    title: 'Шаг 5',
    delay: 12000,
    isFirst: true,
    subTitle: 'Оформите заказ',
    icon: <TriangleSvg />,
  },
  {
    title: 'Шаг 6',
    delay: 15000,
    isFirst: false,
    subTitle: ['Отслеживайте', <br key="1" />, 'Ваш заказ'],
    icon: <TriangleSvg />,
  },
];

export const BlockSteps = () => {
  const boxAnimate0 = useRef<HTMLDivElement>(null);
  const boxAnimate1 = useRef<HTMLDivElement>(null);
  const boxes = [boxAnimate0, boxAnimate1];

  function handleOverAction() {
    boxes.map(item => {
      if (item.current) {
        item.current.style.transform = `translateX(0px)`;
        item.current.style.opacity = '1';
      }
    });
  }
  /*function handleOutAction() {
    boxes.map(item => {
      if (item.current) {
        item.current.style.transform = `translateX(600px)`;
        item.current.style.opacity = '0';
      }
    });
  }*/

  useEffect(() => {
    handleOverAction();
  }, []);

  return (
    <WrapperBox
      position="relative"
      width="1180px"
      height="100%"
      direction="column"
      align="flex-end"
      justify="space-evenly"
      margin="0 auto"
    >
      <ContainerImg />
      <Box width="100%" justify="flex-start" padding="0 0 0 320px">
        <TextTitle
          text={['как заказать %3d decor%', 'на платформе']}
          textAlign="left"
          textProps={{ marginBottom: '20px' }}
        />
      </Box>
      <Box width="100%" justify="flex-end">
        <ContainerButtons>
          <ContainerAnimate
            ref={boxAnimate0}
            height="100%"
            direction="column"
            justify="space-between"
            duration="1600ms"
            timeFunc="cubic-bezier(0.665, 1.26, 0.450, 0.965)"
          >
            {STEPS.filter(item => item.isFirst).map(item => (
              <ButtonItem
                key={item.title}
                title={item.title}
                subTitle={item.subTitle}
                iconButton={item.icon}
                onClick={item.onClick}
                delay={item.delay}
              />
            ))}
          </ContainerAnimate>
          <ContainerAnimate
            ref={boxAnimate1}
            height="100%"
            direction="column"
            justify="space-between"
            duration="2000ms"
            timeFunc="cubic-bezier(0.665, 1.42, 0.450, 0.965)"
          >
            {STEPS.filter(item => !item.isFirst).map(item => (
              <ButtonItem
                key={item.title}
                title={item.title}
                subTitle={item.subTitle}
                iconButton={item.icon}
                onClick={item.onClick}
                delay={item.delay}
              />
            ))}
          </ContainerAnimate>
        </ContainerButtons>
      </Box>
    </WrapperBox>
  );
};
