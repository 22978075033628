import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/theme';
import { Box } from '../../Box';
import { ContainerAbsolute } from './components/ContainerAbsolute';
import { CircleWithNumber } from '../../CircleWithNumber';
import { PathComponent } from './components/PathComponent';
import { Text } from '../../Texts/Text';
import { ButtonCircle } from '../../ButtonCircle';
import { TextTitle } from '../../TextTitle';
import { IMAGE_URLS } from '../../PreloadImages/helpers/constans';
import { setSelectPage } from '../../../api/store/functionsWitingCache';
import { PatchesName } from '../../../__generated__/types';

const ContainerStyle = styled.div`
  width: 43%;
  height: 100%;
  position: relative;
  @media (max-width: 1420px) {
    width: 50%;
  }
`;
const WrapperBox = styled(Box)`
  @media (max-height: 890px) {
    padding: 80px;
  }
  @media (max-width: 1420px) {
    width: 50%;
  }
`;
const Container = styled(Box)`
  @media (max-height: 890px) {
    height: 86%;
  }
`;
const ContainerMan = styled.div`
  position: absolute;
  width: 605px;
  height: 796px;
  right: 0;
  bottom: -120px;
  background-image: url(${IMAGE_URLS.manMin});
  @media (max-height: 890px) {
    transform: scale(0.9, 0.9);
  }
  @media (max-height: 760px) {
    transform: scale(0.76, 0.76);
  }
`;
const ContainerAi = styled.div`
  width: 113px;
  height: 113px;
  background-image: url(${IMAGE_URLS.ai});
  border-radius: 32px;
`;

export const BlockTechnology = () => {
  const goToVideo = () => {
    setSelectPage({
      dataWrite: {
        selectPage: PatchesName.VideoExample,
      },
    });
  };
  return (
    <Container width="100%" height="751px" background={theme.colors.white}>
      <ContainerStyle>
        <ContainerAbsolute
          width="244px"
          height="149px"
          top="156px"
          right="320px"
          img={IMAGE_URLS.step2}
        >
          <ContainerAbsolute top="-18px" left="-16px">
            <CircleWithNumber size="small" text="2" />
          </ContainerAbsolute>
        </ContainerAbsolute>
        <ContainerAbsolute width="357px" height="203px" top="20px" right="0" img={IMAGE_URLS.step3}>
          <ContainerAbsolute top="-18px" right="-16px">
            <CircleWithNumber size="small" text="3" />
          </ContainerAbsolute>
        </ContainerAbsolute>
        <ContainerMan />
        <ContainerAbsolute
          width="138px"
          height="138px"
          right="66px"
          bottom="307px"
          border={`4px solid ${theme.colors.softBlueViolet}`}
          borderRadius="35px"
        >
          <ContainerAi />
          <ContainerAbsolute top="-10px" right="-10px">
            <CircleWithNumber size="small" text="1" />
          </ContainerAbsolute>
          <PathComponent />
        </ContainerAbsolute>
      </ContainerStyle>
      <WrapperBox
        width="57%"
        height="100%"
        direction="column"
        align="flex-start"
        justify="center"
        padding="160px 0 0 100px"
      >
        <TextTitle
          text={['Что такое технология', '%smart 3d%?']}
          textAlign="left"
          textProps={{ marginBottom: '50px' }}
        />
        <div style={{ width: '563px' }}>
          <Text fontFamily="Gilroy" weight={500} size="20px" color="black">
            Технология
            {` `}
          </Text>
          <Text fontFamily="Gilroy" weight={700} size="20px" color="black">
            Smart3D сочетает
            {` `}
          </Text>
          <Text fontFamily="Gilroy" weight={500} size="20px" color="black">
            в себе уникальный
            {` `}
          </Text>
          <Text fontFamily="Gilroy" weight={700} size="20px" color="black">
            материал для внутренней облицовки стен, передовой софт
            {` `}
          </Text>
          <Text fontFamily="Gilroy" weight={500} size="20px" color="black">
            с элементами искусственного интеллекта и высокотехнологичное
            {` `}
          </Text>
          <Text fontFamily="Gilroy" weight={700} size="20px" color="black">
            производство.
          </Text>
        </div>
        <Box
          width="524px"
          height="139px"
          borderRadius="30px"
          background={theme.colors.softBlueViolet_200}
          justify="space-between"
          margin="80px 0 0 0"
        >
          <Box direction="column" align="flex-start" margin="0 0 0 50px">
            <Text fontFamily="Neue Machina" weight={700} size="30px" color="softBlueViolet">
              Хотите узнать больше?
            </Text>
            <Text fontFamily="Gilroy" weight={500} size="20px" color="black">
              Посмотрите видео
            </Text>
          </Box>
          <Box margin="0 -30px 0 0">
            <ButtonCircle size={70} isAnimate onButtonClick={goToVideo}>
              <svg
                width="19"
                height="22"
                style={{ marginLeft: '4px', pointerEvents: 'none' }}
                viewBox="0 0 19 22"
              >
                <path
                  d="M18 9.26795C19.3333 10.0378 19.3333 11.9623 18 12.7321L3 21.3923C1.66667 22.1621 8.49539e-07 21.1999 9.16837e-07 19.6603L1.67394e-06 2.33975C1.74124e-06 0.800144 1.66667 -0.162106 3 0.607695L18 9.26795Z"
                  fill={theme.colors.white}
                />
              </svg>
            </ButtonCircle>
          </Box>
        </Box>
      </WrapperBox>
    </Container>
  );
};
