import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockVideoFuture } from '../../components/Blocks/BlockVideoFuture/BlockVideoFuture';
import { IMAGE_URLS } from '../../components/PreloadImages/helpers/constans';

export const VideoPresent: FC = () => {
  return (
    <WrapperPage>
      <BlockVideoFuture
        poster={IMAGE_URLS.videoPoster}
        title={['%технические%', 'характеристики и монтаж']}
        srcVideo={require('../../assets/video/video_present.mp4')}
      />
    </WrapperPage>
  );
};
