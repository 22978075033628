import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type ChangeSelectDoorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ChangeSelectDoorQuery = { __typename?: 'Query', changeSelectDoor: number };


export const ChangeSelectDoorDocument = gql`
    query ChangeSelectDoor {
  changeSelectDoor @client
}
    `;

/**
 * __useChangeSelectDoorQuery__
 *
 * To run a query within a React component, call `useChangeSelectDoorQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeSelectDoorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeSelectDoorQuery({
 *   variables: {
 *   },
 * });
 */
export function useChangeSelectDoorQuery(baseOptions?: Apollo.QueryHookOptions<ChangeSelectDoorQuery, ChangeSelectDoorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChangeSelectDoorQuery, ChangeSelectDoorQueryVariables>(ChangeSelectDoorDocument, options);
      }
export function useChangeSelectDoorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChangeSelectDoorQuery, ChangeSelectDoorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChangeSelectDoorQuery, ChangeSelectDoorQueryVariables>(ChangeSelectDoorDocument, options);
        }
export type ChangeSelectDoorQueryHookResult = ReturnType<typeof useChangeSelectDoorQuery>;
export type ChangeSelectDoorLazyQueryHookResult = ReturnType<typeof useChangeSelectDoorLazyQuery>;
export type ChangeSelectDoorQueryResult = Apollo.QueryResult<ChangeSelectDoorQuery, ChangeSelectDoorQueryVariables>;