import { ReactNode } from 'react';
import * as yup from 'yup';
import { OrderFormInput } from '../../../__generated__/types';
export interface FormType extends OrderFormInput {
  isCheckPrivatePolicy?: boolean;
}
type PropsFields = {
  title: string;
  suffix: string | ReactNode;
  isRequired: boolean;
  fieldFormName: keyof OrderFormInput;
  component?: 'phone' | 'location';
  isGoogleSearch?: boolean;
  isTextArea?: boolean;
};
export const FIELDS: Array<PropsFields> = [
  { fieldFormName: 'fullName', title: 'ФИО контактного лица', suffix: '', isRequired: true },
  {
    fieldFormName: 'companyName',
    title: 'Название юридического лица',
    suffix: '',
    isRequired: false,
  },
  { fieldFormName: 'email', title: 'Электронная почта', suffix: '', isRequired: true },
  { fieldFormName: 'companyTaxNumber', title: 'ИНН/БНН', suffix: '', isRequired: false },
  {
    fieldFormName: 'phoneNumber',
    title: 'Номер телефона',
    suffix: '',
    isRequired: true,
    component: 'phone',
  },
  {
    fieldFormName: 'companyAddress',
    title: 'Адрес компании',
    suffix: '',
    isRequired: false,
    isGoogleSearch: true,
  },
  {
    fieldFormName: 'address',
    title: 'Регион ',
    suffix: '(страна, город)',
    isRequired: true,
    isGoogleSearch: true,
  },
  { fieldFormName: 'companySite', title: 'Адрес сайта', suffix: '', isRequired: false },
  {
    fieldFormName: 'comment',
    title: 'Примечание ',
    suffix: '',
    isRequired: false,
    isTextArea: true,
  },
];

const nameRegExp =
  /(?<=^|\s)([Ѐ-ЯA-Z](?:[а-џa-z]+))((?:(?<=\.|[Ѐ-ЯA-Z])| )[Ѐ-ЯA-Z](?:[а-џa-z]*|\.|(?=[Ѐ-ЯA-Z]|\s|$))){1,2}(?=\s*(?:$|\r|\n|\s[а-џa-z]|[^Ѐ-ЯA-Zа-џa-z\s.]))/;
export const validationSchema: yup.ObjectSchema<FormType> = yup.object().shape({
  address: yup.string().required('Выберете страну и город (*)'),
  email: yup.string().email('E-mail не валидный').required('Небходимо указать почту (*)'),
  fullName: yup
    .string()
    .required('Имя и фамилия обязателны (*)')
    .matches(nameRegExp, 'Имя и фамилия не валидны'),
  attachment: yup.string(),
  companyAddress: yup.string(),
  companyName: yup.string(),
  companySite: yup.string(),
  companyTaxNumber: yup.string(),
  comment: yup.string(),
  phoneNumber: yup.string().required('Поле с телефоном не может быть пустым (*)'),
  isCheckPrivatePolicy: yup.boolean().oneOf([true], 'Согласитесь с Политикой конфиденциальности'),
});
