import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Texts/Text';

const WrapperBox = styled(Box)`
  gap: 16px;
`;

const ADDRESSES = [
  { title: 'почта', subTitle: 'office@smart3dcorporation.com' },
  { title: 'телефон', subTitle: '+7702 281 42 01' },
  {
    title: 'Юридический адрес завода производителя ТОО “Smart3D Technology Kazakhstan',
    subTitle:
      'Республика Казахстан, Западно-Казахстанская область, Теректинский район, с.о. Аксуатский',
  },
];

export const AddressBox: FC = () => {
  return (
    <WrapperBox
      width="100%"
      padding="0 0 40px 0"
      justify="flex-start"
      direction="column"
      align="flex-start"
    >
      {ADDRESSES.map(item => (
        <div key={item.title}>
          <Text fontFamily="Neue Machina" weight={700} size="20px" color="softBlueViolet">
            {item.title.toUpperCase()}
          </Text>
          <br />
          <Text fontFamily="Gilroy" weight={400} size="16px">
            {item.subTitle}
          </Text>
        </div>
      ))}
    </WrapperBox>
  );
};
