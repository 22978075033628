import React from 'react';
import {
  MenuItemsHeaderDefault,
  MenuItemsHeaderDop,
  MenuItemType,
} from '../../../../helpers/constans';
import styled from 'styled-components';
import { Box } from '../../../Box';
import { Text } from '../../../Texts/Text';
import { PatchesName } from '../../../../__generated__/types';
import { setSelectPage } from '../../../../api/store/functionsWitingCache';

const MenuItemStyle = styled.div`
  min-width: 80px;
  display: inline-block;
  padding: 6px 0;
  cursor: pointer;
`;
const BoxGap = styled(Box)`
  flex-wrap: wrap;
`;
export const HeaderFooter = () => {
  const handlerClickText = (path?: PatchesName) => {
    if (path) {
      setSelectPage({
        dataWrite: {
          selectPage: path,
        },
      });
    }
  };
  const getMenuItems = () => {
    const menuItems: MenuItemType[] = [...MenuItemsHeaderDefault, ...MenuItemsHeaderDop];
    menuItems.sort((a, b) => a.text.length - b.text.length);
    return menuItems.map(item => (
      <MenuItemStyle onClick={() => handlerClickText(item.path)} key={item.text}>
        <Text fontFamily="Neue Machina" size="16px" weight={400} color="black" lineHeight="15px">
          {item.text}
        </Text>
      </MenuItemStyle>
    ));
  };
  return (
    <BoxGap width="520px" height="100%" justify="flex-start" align="flex-start" direction="column">
      {getMenuItems()}
    </BoxGap>
  );
};
