import React from 'react';
import { theme } from '../../../../themes/theme';
import { ContainerAbsolute } from './ContainerAbsolute';

export const PathComponent = () => {
  return (
    <ContainerAbsolute width="116px" height="42px" left="-48px" bottom="-48px">
      <ContainerAbsolute
        width="22px"
        height="22px"
        borderRadius="11px"
        background={theme.colors.white}
        bottom="-11px"
        left="-11px"
      >
        <ContainerAbsolute
          width="14px"
          height="14px"
          background={theme.colors.softBlueViolet}
          borderRadius="7px"
        />
      </ContainerAbsolute>
      <svg
        width="118"
        height="44"
        viewBox="0 0 118 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M116 0V12C116 28.5685 102.569 42 86 42H0" stroke="white" strokeWidth="4" />
      </svg>
    </ContainerAbsolute>
  );
};
