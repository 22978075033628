/* eslint-disable no-var */
import * as THREE from 'three';
import BasicObject from './BasicObject';
import { setChangeSelectDoor } from '../../../../api/store/functionsWitingCache';
import { Wall } from './Wall';
import { DOOR_WIDTH, EDIT_CLASS_OBJECT } from '../../../../helpers/constans';
import { SideLookDoor, SideOpeningDoor } from '../types';

interface PropsConstructor {
  width: number;
  height: number;
}
export class DoorObject extends BasicObject {
  width: number;
  height: number;
  material: THREE.MeshBasicMaterial;
  materialDoorObject: THREE.MeshStandardMaterial;
  indexToWall = 0;
  limitDragMin = 0;
  limitDragMax = 0;
  sideOpeningDoor: SideOpeningDoor = 'in';
  openingLookDoor: SideLookDoor = 'left';
  holeDoorsPanels: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial> | null = null;
  private isSelect = false;
  private startX = 0;
  private startOpeningAngle = 0;
  readonly WIDTH = 0.72;
  constructor({ width, height }: PropsConstructor) {
    super();
    this.width = width - 0.1;
    this.height = height - 0.1;
    this.material = new THREE.MeshBasicMaterial({
      color: '#ffffff',
      transparent: true,
      opacity: 0,
      // depthTest: true,
      // depthWrite: false,
    });
    this.materialDoorObject = new THREE.MeshStandardMaterial({
      color: '#672903',
      roughness: 0.3,
      metalness: 0.2,
      // transparent: true,
    });

    this.buildGeometry();
  }
  buildGeometry() {
    super.buildGeometry();
    var { width, height } = this;
    this.geometry = new THREE.PlaneGeometry(width, height);
    // this.renderOrder = 0;
    // this.position.z += 0.3;
    this.addingDoorModel();
  }
  selectUnselectSelfObj() {
    if (!this.isSelect) {
      this.materialDoorObject.color = new THREE.Color('#826EEE');
      this.materialDoorObject.opacity = 0.9;
    } else {
      this.materialDoorObject.color = new THREE.Color('#672903');
      this.materialDoorObject.opacity = 1;
    }
    this.isSelect = !this.isSelect;
    if (this.isSelect) {
      setChangeSelectDoor({
        dataWrite: {
          changeSelectDoor: Math.ceil(Math.random() * 1000),
        },
      });
    }
  }
  startDrag() {
    this.startX = this.position.x;
    var wall = this.parent as Wall;
    if (this.indexToWall === 0) {
      this.limitDragMin = -wall.width / 2 + DOOR_WIDTH / 2 + 0.1;
      if (wall.doors.length === 1) {
        this.limitDragMax = wall.width / 2 - DOOR_WIDTH / 2 - 0.1;
      } else {
        this.limitDragMax = wall.doors[this.indexToWall + 1].position.x - DOOR_WIDTH - 0.1;
      }
    } //
    else if (this.indexToWall === wall.doors.length - 1) {
      this.limitDragMin = wall.doors[this.indexToWall - 1].position.x + DOOR_WIDTH + 0.1;
      this.limitDragMax = wall.width / 2 - DOOR_WIDTH / 2 - 0.1;
    } //
    else {
      this.limitDragMin = wall.doors[this.indexToWall - 1].position.x + DOOR_WIDTH + 0.02;
      this.limitDragMax = wall.doors[this.indexToWall + 1].position.x - DOOR_WIDTH - 0.02;
    }
  }
  drag(offset: number) {
    var wall = this.parent as Wall;
    var positions = wall.geometry.attributes['position'].array;
    var start = 3 + 6 * this.indexToWall;
    var end = start + 6;
    var mid = start + 3;
    var p0 = new THREE.Vector3(positions[0], positions[1], positions[2]);
    if (this.startX + offset > this.limitDragMin && this.startX + offset < this.limitDragMax) {
      for (let i = start; i < end; i++) {
        var x = positions[i * 3];
        var y = positions[i * 3 + 1];
        var z = positions[i * 3 + 2];

        if (i < mid) {
          x = this.startX - DOOR_WIDTH / 2 + offset;
        } else {
          x = this.startX + DOOR_WIDTH / 2 + offset;
        }

        wall.geometry.attributes.position.setXYZ(i, x, y, z);
        wall.updateUV(p0, x, y, wall.height, wall.width, i);
      }
      wall.geometry.attributes.position.needsUpdate = true;
      wall.geometry.attributes.uv.needsUpdate = true;

      this.position.x = this.startX + offset;
      if (this.holeDoorsPanels) {
        this.holeDoorsPanels.position.x = this.startX + offset;
      }
    }
  }
  endDrag() {
    // console.log(this.position.x, this.limitDragMin);
  }
  startOpening() {
    if (this.children.length === 2) {
      this.startOpeningAngle = this.children[1].rotation.y;
    }
  }
  opening(angle: number) {
    if (this.children.length === 2) {
      var offsetAngle = this.startOpeningAngle + angle;
      if (this.openingLookDoor === 'left') {
        if (offsetAngle >= -Math.PI / 1.7 && offsetAngle <= 0) {
          this.children[1].rotation.y = offsetAngle;
        } //
        else {
          if (offsetAngle >= -Math.PI / 3) {
            this.children[1].rotation.y = 0;
          } //
          else {
            this.children[1].rotation.y = -Math.PI / 1.7;
          }
        }
      } //
      else {
        if (offsetAngle >= 0 && offsetAngle <= Math.PI / 1.7) {
          this.children[1].rotation.y = offsetAngle;
        } //
        else {
          if (offsetAngle <= Math.PI / 3) {
            this.children[1].rotation.y = 0;
          } //
          else {
            this.children[1].rotation.y = Math.PI / 1.7;
          }
        }
      }
    }
  }
  changeSideOpening(lookOpening: SideOpeningDoor) {
    this.sideOpeningDoor = lookOpening;
    if (this.children.length === 2) {
      if (this.sideOpeningDoor === 'out') {
        this.children[0].scale.set(0.001, 0.001, -0.001);
        this.children[1].scale.set(-1, 1, -1);
        this.children[1].position.x = this.WIDTH / 2;
        this.children[1].position.z = -0.06;
      } else {
        this.children[0].scale.set(0.001, 0.001, 0.001);
        this.children[1].scale.set(1, 1, 1);
        this.children[1].position.x = -this.WIDTH / 2;
        this.children[1].position.z = 0.06;
      }
    }
  }
  changeOpeningDoor(sideOpening: SideLookDoor) {
    this.openingLookDoor = sideOpening;
    if (this.children.length === 2) {
      var coif = this.sideOpeningDoor === 'in' ? -1 : 1;
      if (this.openingLookDoor === 'left') {
        this.children[1].scale.x = -1 * coif;
        this.children[1].position.x = (this.WIDTH / 2) * coif;
      } else {
        this.children[1].scale.x = coif;
        this.children[1].position.x = (-this.WIDTH / 2) * coif;
      }
      this.children[1].rotation.y *= -1;
    }
  }
  addingDoorModel() {
    const doorGeometry = EDIT_CLASS_OBJECT.getDoorGeometry();
    if (doorGeometry) {
      const geometry = doorGeometry.clone();

      geometry.center();
      geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
      const mesh = new THREE.Mesh(geometry, this.materialDoorObject);
      mesh.scale.set(0.001, 0.001, 0.001);
      mesh.position.y += 0.02;
      this.add(mesh);

      const polGeometry = this.createBoxWithRoundedEdges();
      const meshPol = new THREE.Mesh(polGeometry, this.materialDoorObject);
      meshPol.position.z += 0.06;
      meshPol.position.x -= this.WIDTH / 2;
      this.add(meshPol);
    }
  }
  removeDoorModel() {
    if (this.children.length) {
      while (this.children.length > 0) {
        const child = this.children[0] as THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>;
        BasicObject.removeMesh(child);
        this.remove(child);
      }
    }
    this.removeHoleDoorPanels();
  }
  removeHoleDoorPanels() {
    if (this.holeDoorsPanels) {
      this.holeDoorsPanels.geometry.dispose();
      this.holeDoorsPanels.material.dispose();
      this.holeDoorsPanels.parent?.remove(this.holeDoorsPanels);
      this.holeDoorsPanels = null;
    }
  }
  removeSelf() {
    this.removeDoorModel();
    var wall = this.parent as Wall;
    wall.removeDoorToWall(this.uuid);

    super.removeSelf();
  }
  private createBoxWithRoundedEdges(): THREE.BufferGeometry {
    const y = 1.94;
    const shape = new THREE.Shape();
    shape.moveTo(0, 0);
    shape.lineTo(0, y);
    shape.lineTo(this.WIDTH, y);
    shape.lineTo(this.WIDTH, 0);
    shape.lineTo(0, 0);
    const geometry = new THREE.ExtrudeGeometry(shape, {
      steps: 1,
      depth: 0.036,
      bevelEnabled: true,
      bevelThickness: 0.01,
      bevelSize: 0.01,
      bevelOffset: 0,
      bevelSegments: 6,
    });
    geometry.center();
    geometry.applyMatrix4(
      new THREE.Matrix4().makeTranslation(new THREE.Vector3(this.WIDTH / 2, 0, -0.022))
    );

    return geometry;
  }
}
