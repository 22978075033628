import React, { CSSProperties, FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { Gallery } from '../../Gallery';
import { Icon } from '../../Icons/Icon';
import { theme } from '../../../themes/theme';
import { TextTitle } from '../../TextTitle';
import { GALLERY } from '../../../assets/gallery/constans';
import { useDefineScrollForAnchor } from '../../../hooks/useDefineScrollForAnchor';

const WrapperGallery = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-height: 890px) {
    height: 500px;
  }
  @media (max-height: 720px) {
    height: 480px;
  }
`;
const WrapperPage = styled(Box)`
  @media (max-height: 790px) {
    justify-content: flex-start;
    padding-top: 50px;
  }
`;
const WrapperButton = styled(Box)`
  cursor: pointer;
  z-index: 2;
`;
const Button = styled(Box)`
  cursor: pointer;
  z-index: 2;
  -webkit-box-shadow: 0 0 21px 0 rgba(138, 138, 138, 1);
  -moz-box-shadow: 0 0 21px 0 rgba(138, 138, 138, 1);
  box-shadow: 0 0 21px 0 rgba(138, 138, 138, 1);
`;
const BoxRotate = styled(Button)`
  transform: rotateY(180deg);
`;
// const LIMITS = {
//   start: window.innerWidth / 1.8,
//   range:
// };
interface Props {
  padding: CSSProperties['padding'];
}
export const BlockExamples: FC<Props> = ({ padding }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [numberSteps, setNumberSteps] = useState(0);
  const box = useRef<HTMLDivElement>(null);
  useDefineScrollForAnchor({
    childRef: box,
    orderNumber: 4,
  });
  // const [selectTab, setSelectTab] = useState(0);
  // const [dataTabElement, setDataTabElement] = useState<DOMRect>();
  // const wrapper = useRef<HTMLDivElement>(null);
  // const div = useRef<HTMLDivElement>(null);
  // const isDownButton = useRef(false);
  // const offset = useRef(0);
  // const isMaxRange = useRef<'L' | 'R' | 'N'>('N');
  // const currentOffsetParent = useRef(0);
  // const timer = useRef<NodeJS.Timer>();
  // let start = 0;
  // let offsetContainerX = 0;
  // let widthContainerX = 0;
  /*const callbackAnimateSlide = (data: DOMRect, offsetParent: number) => {
    if (div.current) {
      currentOffsetParent.current = offsetParent;
      const _offset = offsetParent - data.x + (window.innerWidth / 2 - data.width / 2);
      div.current.animate([{ transform: `translateX(${_offset}px)` }], {
        duration: 1000,
        easing: 'cubic-bezier(0.425, -0.02, 0.385, 1.2)',
      }).onfinish = () => {
        if (div.current) {
          div.current.style.transform = `translateX(${_offset}px)`;
        }
      };
    }
  };
  const setDefaultPropertiesCurrentButton = () => {
    isDownButton.current = false;
  };
  function handleMouseDown(evt: MouseEvent) {
    if (evt.buttons === 1) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
      start = evt.x;
      isDownButton.current = true;
      offset.current = 0;
      if (div.current) {
        offsetContainerX = div.current.getBoundingClientRect().x;
        widthContainerX = div.current.getBoundingClientRect().width;
      }
    }
  }
  function handleMouseMove(evt: MouseEvent) {
    if (evt.buttons === 1 && div.current && isDownButton.current) {
      offset.current = evt.x - start;
      const startRan = window.innerWidth / 2;
      const translateX = offsetContainerX + (evt.x - start);
      if (translateX > startRan) {
        isMaxRange.current = 'R';
        const _translateX = startRan + (translateX - startRan) * 0.4;
        div.current.style.transform = `translateX(${_translateX}px)`;
      } //
      else if (translateX + widthContainerX < startRan) {
        isMaxRange.current = 'L';
        const _translateX =
          startRan + (translateX + widthContainerX - startRan) * 0.4 - widthContainerX;
        div.current.style.transform = `translateX(${_translateX}px)`;
      } //
      else {
        isMaxRange.current = 'N';
        div.current.style.transform = `translateX(${translateX}px)`;
      }
    }
  }
  function handleMouseUp(evt: MouseEvent) {
    if (isMaxRange.current !== 'N') {
      isDownButton.current = false;
      if (div.current) {
        const _offset =
          isMaxRange.current === 'R'
            ? window.innerWidth / 2 - 90.5
            : -widthContainerX + window.innerWidth / 2;
        div.current.animate([{ transform: `translateX(${_offset}px)` }], {
          duration: 200,
          easing: 'cubic-bezier(0.425, -0.02, 0.385, 1.2)',
        }).onfinish = () => {
          if (div.current) {
            div.current.style.transform = `translateX(${_offset}px)`;
          }
        };
      }
      isMaxRange.current = 'N';
    }
    const id = (evt.target as HTMLDivElement).id;
    if (id.includes('wrapper')) {
      isDownButton.current = false;
      // offset.current = 0;
    }

    timer.current = setTimeout(() => {
      setDataTabElement(prev => {
        if (Math.abs(offset.current) >= 9 && prev) {
          callbackAnimateSlide(prev, currentOffsetParent.current);
        }
        return prev;
      });
    }, 3000);
  }*/
  /*useEffect(() => {
    if (wrapper.current && box.current) {
      wrapper.current.addEventListener('mousedown', handleMouseDown);
      box.current.addEventListener('mousemove', handleMouseMove);
      box.current.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      if (wrapper.current && box.current) {
        wrapper.current.removeEventListener('mousedown', handleMouseDown);
        box.current.removeEventListener('mousemove', handleMouseMove);
        box.current.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, []);*/
  const handleClickLeft = () => {
    if (numberSteps - 1 >= 0) {
      setCurrentPosition(currentPosition + 1200);
      setNumberSteps(numberSteps - 1);
    }
  };
  const handleClickRight = () => {
    if (numberSteps + 1 < GALLERY.length) {
      setCurrentPosition(currentPosition - 1200);
      setNumberSteps(numberSteps + 1);
    }
  };

  return (
    <WrapperPage
      id="wrapper_1"
      ref={box}
      width="100%"
      height="100%"
      direction="column"
      padding={padding}
    >
      <TextTitle text={['3d ремонт в интерьере']} textProps={{ marginBottom: '20px' }} />
      <WrapperGallery>
        <Gallery offset={currentPosition} />
        <WrapperButton width="1000px" justify="space-between">
          <Button
            width="40px"
            height="40px"
            borderRadius="50%"
            background={theme.colors.white}
            onClick={handleClickLeft}
          >
            <Icon name="arrowLeft" />
          </Button>
          <BoxRotate
            width="40px"
            height="40px"
            borderRadius="50%"
            background={theme.colors.white}
            onClick={handleClickRight}
          >
            <Icon name="arrowLeft" />
          </BoxRotate>
        </WrapperButton>
      </WrapperGallery>
      {/*<Wrapper ref={wrapper} id="wrapper_2">
        <TabContainer ref={div}>
          <TabComponent
            setDefaultProperties={setDefaultPropertiesCurrentButton}
            offset={offset}
            dataTabElement={dataTabElement}
            setDataTabElement={setDataTabElement}
            selectTab={selectTab}
            changeTab={setSelectTab}
            endAnimate={callbackAnimateSlide}
          />
        </TabContainer>
      </Wrapper>*/}
    </WrapperPage>
  );
};
