import React, { FC } from 'react';
import { theme } from '../../../themes/theme';
interface Props {
  color?: string;
}
export const LogoIcon: FC<Props> = ({ color = theme.colors.white }) => {
  return (
    <svg viewBox="0 0 750 170" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M74,170H0V0h75.9v168.1C75.9,169.1,75,170,74,170z" fill="#5F4FCA" />
        <path d="M187.7,170h-75.9V2.7c0-1.5,1.2-2.7,2.7-2.7h73.2V170z" fill="#72EB9F" />
        <circle cx="94" cy="86.3" r="46.6" />
        <g fill={color}>
          <path
            d="M235,136c0-7.8,2-14.2,6.1-19.2s9.7-7.5,16.7-7.5c6.2,0,11.3,1.8,15.2,5.5c3.9,3.7,6.3,8.8,7,15.3h-2.6
			c-0.6-6-2.5-10.6-5.9-13.9s-7.9-4.9-13.8-4.9c-6.7,0-11.7,2.3-15.2,6.9c-3.4,4.6-5.1,10.5-5.1,17.7c0,7.3,1.7,13.2,5.1,17.8
			c3.4,4.6,8.5,6.9,15.2,6.9c5.9,0,10.5-1.6,13.8-4.9c3.3-3.3,5.3-7.9,5.9-13.9h2.6c-0.8,6.5-3.1,11.6-7,15.3
			c-3.9,3.7-9,5.6-15.2,5.6c-7.1,0-12.7-2.5-16.7-7.5C237,150.2,235,143.8,235,136z"
          />
          <path
            d="M289.3,136c0-7.8,2.1-14.2,6.2-19.2c4.1-5,9.7-7.5,16.8-7.5c7,0,12.6,2.5,16.7,7.5s6.2,11.4,6.2,19.2
			c0,7.8-2.1,14.2-6.2,19.2c-4.1,5-9.7,7.5-16.7,7.5c-7.1,0-12.7-2.5-16.8-7.5C291.3,150.2,289.3,143.8,289.3,136z M291.9,136
			c0,7.3,1.7,13.3,5.2,17.8c3.5,4.6,8.5,6.9,15.1,6.9c6.6,0,11.6-2.3,15.1-6.9c3.5-4.6,5.2-10.5,5.2-17.8c0-7.3-1.7-13.2-5.2-17.8
			c-3.5-4.6-8.5-6.9-15.1-6.9c-6.6,0-11.6,2.3-15.1,6.9C293.6,122.8,291.9,128.7,291.9,136z"
          />
          <path
            d="M344.8,162v-52h15.6c6.5,0,11.3,1,14.2,3.1c2.9,2,4.4,5.9,4.4,11.5c0,7.4-2.5,11.6-7.6,12.6v1h5.5c1.1,0,1.9,0.2,2.2,0.5
			c0.4,0.3,0.6,1,0.6,2V162H377v-22.4h-29.6V162H344.8z M347.4,137.6h13c2.8,0,5.1-0.1,6.8-0.4s3.3-0.8,4.8-1.7
			c1.5-0.9,2.6-2.2,3.3-3.9c0.7-1.7,1-4,1-6.7c0-2.8-0.3-5.1-1-6.8c-0.7-1.7-1.8-3-3.3-3.8c-1.5-0.8-3.2-1.4-4.9-1.7
			c-1.7-0.3-3.9-0.4-6.7-0.4h-13V137.6z"
          />
          <path
            d="M403.1,110.1c6.9,0,12,1.2,15.4,3.7c3.4,2.5,5.1,6.6,5.1,12.5c0,6-1.7,10.2-5.1,12.6c-3.4,2.5-8.5,3.7-15.4,3.7h-9.5V162
			H391v-52H403.1z M402.9,140.6c2.8,0,5.1-0.2,7.1-0.6c2-0.4,3.9-1,5.6-2c1.8-1,3.1-2.4,4-4.4c0.9-1.9,1.4-4.4,1.4-7.3
			c0-2.9-0.5-5.3-1.4-7.3c-0.9-1.9-2.2-3.4-4-4.4c-1.8-1-3.7-1.6-5.6-2c-2-0.4-4.4-0.6-7.1-0.6h-9.3v28.5H402.9z"
          />
          <path
            d="M431.1,136c0-7.8,2.1-14.2,6.2-19.2c4.1-5,9.7-7.5,16.8-7.5c7,0,12.6,2.5,16.7,7.5c4.1,5,6.2,11.4,6.2,19.2
			c0,7.8-2.1,14.2-6.2,19.2c-4.1,5-9.7,7.5-16.7,7.5c-7.1,0-12.7-2.5-16.8-7.5C433.2,150.2,431.1,143.8,431.1,136z M433.7,136
			c0,7.3,1.7,13.3,5.2,17.8c3.5,4.6,8.5,6.9,15.1,6.9c6.6,0,11.6-2.3,15.1-6.9c3.5-4.6,5.2-10.5,5.2-17.8c0-7.3-1.7-13.2-5.2-17.8
			c-3.5-4.6-8.5-6.9-15.1-6.9c-6.6,0-11.6,2.3-15.1,6.9C435.4,122.8,433.7,128.7,433.7,136z"
          />
          <path
            d="M486.7,162v-52h15.6c6.5,0,11.3,1,14.2,3.1c2.9,2,4.4,5.9,4.4,11.5c0,7.4-2.5,11.6-7.6,12.6v1h5.5c1.1,0,1.9,0.2,2.2,0.5
			c0.4,0.3,0.6,1,0.6,2V162h-2.6v-22.4h-29.6V162H486.7z M489.2,137.6h13c2.8,0,5.1-0.1,6.8-0.4c1.7-0.3,3.3-0.8,4.8-1.7
			c1.5-0.9,2.6-2.2,3.3-3.9c0.7-1.7,1-4,1-6.7c0-2.8-0.3-5.1-1-6.8c-0.7-1.7-1.8-3-3.3-3.8c-1.5-0.8-3.2-1.4-4.9-1.7
			c-1.7-0.3-3.9-0.4-6.7-0.4h-13V137.6z"
          />
          <path d="M569.1,162l-7.7-22.6h-20.6L533,162h-2.4l17.8-52h5.2l17.8,52H569.1z M541.5,137.4h19.1l-9.1-26.4h-1L541.5,137.4z" />
          <path d="M608.6,110.1v2.1h-18.6V162h-2.6v-49.9h-18.7v-2.1H608.6z" />
          <path d="M643.3,112.1h-11.5V160h11.5v2.1h-25.6V160h11.5v-47.9h-11.5v-2.1h25.6V112.1z" />
          <path
            d="M647.7,136c0-7.8,2.1-14.2,6.2-19.2c4.1-5,9.7-7.5,16.8-7.5c7,0,12.6,2.5,16.7,7.5c4.1,5,6.2,11.4,6.2,19.2
			c0,7.8-2.1,14.2-6.2,19.2c-4.1,5-9.7,7.5-16.7,7.5c-7.1,0-12.7-2.5-16.8-7.5C649.7,150.2,647.7,143.8,647.7,136z M650.2,136
			c0,7.3,1.7,13.3,5.2,17.8c3.5,4.6,8.5,6.9,15.1,6.9c6.6,0,11.6-2.3,15.1-6.9c3.5-4.6,5.2-10.5,5.2-17.8c0-7.3-1.7-13.2-5.2-17.8
			c-3.5-4.6-8.5-6.9-15.1-6.9c-6.6,0-11.6,2.3-15.1,6.9C652,122.8,650.2,128.7,650.2,136z"
          />
          <path d="M703.2,162v-52h5.5l32.1,51h1l-0.2-2.5v-48.5h2.5v52h-5.5l-32-51h-1l0.2,2.5V162H703.2z" />
        </g>
        <g fill={color}>
          <path
            d="M293.5,61.8c0,12.7-9.2,19-27.5,19c-11.7,0-20-2.3-24.9-7c-4.9-4.7-7.3-11.4-7.3-20.3h9.6c0,7.3,1.8,12.3,5.5,15
			c3.7,2.7,9.4,4,17.2,4c6,0,10.4-0.8,13.3-2.4c2.9-1.6,4.4-4.2,4.4-7.9c0-1.3-0.2-2.5-0.5-3.6c-0.4-1.1-1.1-2.1-2.1-3
			c-1-0.9-1.9-1.6-2.7-2.2c-0.8-0.6-2.1-1.2-3.9-1.9c-1.8-0.7-3.1-1.2-4-1.5c-0.8-0.3-2.4-0.8-4.8-1.5c-2.4-0.7-3.9-1.1-4.6-1.3
			c-3.3-1-6-1.9-8-2.6c-2-0.7-4.2-1.7-6.5-2.9c-2.3-1.2-4.1-2.5-5.4-3.8c-1.3-1.3-2.3-3-3.2-4.9c-0.9-2-1.3-4.2-1.3-6.6
			c0-11.7,8.5-17.6,25.4-17.6c9.6,0,16.7,2.4,21.4,7.1c4.6,4.7,7,10.4,7,17.1H281c0-5.3-1.7-9.3-5.2-11.9c-3.5-2.6-8-3.9-13.5-3.9
			c-10.5,0-15.7,3-15.7,9.1c0,3,1.2,5.4,3.6,7c2.4,1.6,6.8,3.5,13.1,5.5c9,2.9,14.9,5,17.8,6.4C289.4,49.2,293.5,54.8,293.5,61.8z"
          />
          <path
            d="M304.4,79.8V9.7h19.3l18,67.4h2.7l18-67.4h19.3v70.1H372V18.9l1.6-6.6H371l-18.4,67.4h-19.3L315,12.4h-2.7l1.7,6.6v60.8
			H304.4z"
          />
          <path d="M445.3,79.8L437,53.3h-28.3l-8.3,26.4h-9.6l22.1-70.1h20l22.1,70.1H445.3z M411.2,45h23.2l-10.2-32.6h-2.7L411.2,45z" />
          <path
            d="M464,79.8V9.7h24c9.1,0,16.1,1.4,20.9,4.3c4.8,2.9,7.2,8,7.2,15.3c0,9.4-4.7,14.9-14,16.4v2.6h8.7c4.1,0,6.2,2,6.2,6v25.6
			h-9.6V52.3h-33.7v27.4H464z M473.7,44H488c2.7,0,4.8-0.1,6.4-0.2c1.6-0.1,3.3-0.4,5-0.9c1.7-0.5,3-1.2,3.9-2.1
			c0.9-0.9,1.7-2.2,2.3-3.8c0.6-1.6,0.9-3.6,0.9-6s-0.3-4.4-0.9-6c-0.6-1.6-1.4-2.9-2.3-3.8c-0.9-0.9-2.2-1.6-3.9-2.1
			c-1.7-0.5-3.3-0.8-5-0.9c-1.6-0.1-3.8-0.2-6.4-0.2h-14.3V44z"
          />
          <path d="M580.3,9.7v8.4h-24.1v61.7h-9.6V18.1h-24.1V9.7H580.3z" />
          <path
            d="M621.5,53h9.6c0,7,1.6,12,4.7,15c3.1,3,8.2,4.4,15.3,4.4c11.5,0,17.3-4.4,17.3-13.3c0-8-4.2-11.9-12.7-11.9H646v-8.4h9.7
			c7.8,0,11.7-3.4,11.7-10.1c0-7.8-5.4-11.6-16.3-11.6c-6.8,0-11.6,1.3-14.6,3.8c-3,2.5-4.4,6.5-4.4,11.9h-9.6c0-8,2.5-14,7.5-18
			c5-4,12-6.1,21.2-6.1c8.3,0,14.7,1.7,19.2,5.1c4.5,3.4,6.8,7.9,6.8,13.6c0,4.3-1.2,7.6-3.6,9.8c-2.4,2.3-5.9,3.7-10.5,4.5v2.7
			c5,0.9,8.7,2.5,11.3,4.8c2.6,2.4,3.8,6.1,3.8,11.3c0,6.4-2.4,11.3-7.2,14.9c-4.8,3.6-11.4,5.4-19.8,5.4
			C631.4,80.8,621.5,71.5,621.5,53z"
          />
          <path
            d="M714.1,9.7c3.8,0,7.3,0.2,10.4,0.7c3.1,0.5,6.3,1.4,9.6,2.9c3.3,1.5,6,3.5,8.2,6s4,5.9,5.4,10.2c1.4,4.3,2.1,9.4,2.1,15.2
			c0,5.8-0.7,10.9-2.1,15.2c-1.4,4.3-3.2,7.7-5.4,10.2c-2.2,2.5-4.9,4.5-8.2,6c-3.3,1.5-6.5,2.5-9.6,3c-3.1,0.5-6.6,0.7-10.4,0.7
			h-22.9V9.7H714.1z M714.1,71.4c3,0,5.5-0.1,7.6-0.3c2.1-0.2,4.4-0.9,6.9-1.9c2.5-1,4.5-2.5,6.1-4.3c1.6-1.9,2.9-4.5,3.9-7.9
			c1.1-3.4,1.6-7.5,1.6-12.3c0-4.8-0.5-8.9-1.6-12.3c-1.1-3.4-2.4-6-3.9-7.9c-1.6-1.8-3.6-3.2-6.1-4.3c-2.5-1-4.8-1.7-6.9-1.9
			c-2.1-0.2-4.6-0.3-7.6-0.3h-13.2v53.4H714.1z"
          />
        </g>
        <path
          d="M111.1,114.8c1.1,0.7,2.2,0.1,2.6-1c1-2.4,2.9-3.3,5.1-4.5c1.1-0.7,1.9-1.7,2-3.3c0-1.7-0.7-3.2-2.5-3.9
		c-2.1-0.8-3.7,0.1-4.7,1.9c-1.5,2.7-1,5.8-3.1,8.4C109.9,113,110.2,114.3,111.1,114.8z"
          fill="#826EEE"
        />
        <path
          d="M142.9,105c-3-1.3-4-3.3-5.3-6.4c-1-2.2-3.7-2.6-5.7-1.7c-2.2,0.9-2.3,4.1-1.5,5.6c1.1,1.9,3.2,2,5,2.8
		c2.4,1.1,4.4,2.8,4.3,5.9c-0.2,3-2.6,3.7-4.9,4c-3,0.4-5.2,1.4-7.1,3.4c-3.4,3.6-7,6.2-11.4,8.7c-2.3,1.3-4.7,2.3-7,1.2
		c-2.3-1.1-0.5-3.9,0.7-5.8c0.8-1.4,1.1-3-0.6-4.1c-1.4-0.9-3-0.4-4.1,0.6c-1.8,1.5-1.6,3.9-2.8,5.7c-4.7,6.9-14.8,8-17.5,4.6
		c-2.6-3.3-2.7-5.4-8.7-4.4c-11.3,1.9-18.7-11.8-19.7-14.2c-1.3-3.2-2.3-5.9-5-7.8C39,94.2,33.2,79.6,33.4,65
		c1-22.3,16.3-42.7,35.6-53.3c2.4-1.7,3.9-3,4.9-4.2c1.3-1.5,2-3.5,2-5.4V0H0v170h74.7c1.7,0,2.4-0.6,2.9-2.3
		c1.8-5.3,6.6-7.7,12-5.8c4.1,1.4,6.9,0.6,10.1-2.2c4-3.4,7.9-9,14.7-7.9c4.4,0.8,6.2-1.9,6.2-3.5c0.1-1.5-1-2.4-4.7-2.8
		c-0.7-0.1-1.6-0.7-1.7-1.5c-0.2-1.6,2.2-2.4,2.8-2.6c3.2-1.1,5.1-2.2,5.8-5.9c2.1-11.4,4.6-12,13.1-13c3.2-0.4,5.1-2.1,5.4-5.1
		c0.3-2.8,1.2-5.3,2.9-7.6C145.2,108.2,144.5,105.7,142.9,105z"
          fill="#826EEE"
        />
        <path
          d="M114.1,0c-2.3,0-3.2,0.7-3.8,2.9c-1.3,5-4.4,6.8-9.3,5.4c-1.5-0.4-3-1-4.5-1.6c-2.3-1-4.7-1.1-6.5,0.7
		c-4.9,4.7-11,5.9-17.3,6.6c-4.6,0.5-9,4.7-9.8,8.7c-0.5,2.3,0,4.8,2.6,5.3c2,0.3,3-2.7,3.9-4.1c1.4-2.2,3.7-1.1,4.6,0.6
		c1.4,2.7,2.5,8.9-3.4,11.5c-4.2,1.8-5.6,0.5-7.4,8.1c-0.6,2.5-3.1,3.7-5.2,5c-4.3,2.9-5.8,6.8-4,11.5c1.5,3.9,2.2,7.7,1.4,11.8
		c-0.4,2.1,0.1,4.1,2.2,5.1c2.3,1.2,3.8-0.4,5.3-1.8c0.5-0.5,1-1,1.4-1.6c2.2-3.3,5.4-3.4,7.3-0.1c0.7,1.2,1.2,2.7,1.4,4
		c0.7,4.9,0.5,9.7-1.2,14.4c-1.9,5.5-1,10.5,3.3,14.7c2,1.9,4.1,2.5,6.7,1.1c2.6-1.4,1.8-3.7,1.5-5.7c-0.3-2-1.7-3.6-1.8-5.7
		c0-1.8,0.2-3.5,1.9-4.5c1.9-1.1,3.3,0.2,4.5,1.4c1.7,1.7,3.1,3.8,5.3,5.1c3.6,2.2,7.2,1.8,8.8-0.9c1.6-2.9,0.2-6-3.6-8
		c-6.2-3.2-8.5-8.2-6.9-15.1c1.8-7.7,5.4-14.6,10.4-20.7c4.4-5.4,9.9-6.1,15.7-2.1c3.1,2.1,3.7,4.5,1.4,7.6
		c-2.3,3.1-5.3,5.3-8.4,7.4c-1.4,1-2.2,2.3-1.7,4.1c0.4,1.5,1.4,2.5,3.1,2.6c1.9,0.1,3.4-0.4,4.3-2.2c0.4-0.8,0.6-1.6,1-2.4
		c2-4,4.6-7.3,8.7-9.3c2-1,4.2-1.6,5.9,0.4c1.7,2,0.6,3.6-1,5.2c-2.6,2.5-1.2,4,1.6,4.9c1.1,0.3,2.3,0.6,3.4,0.6
		c4.1,0,7,1.7,9.4,4.4c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c2.7,3.5,4.8,7.3,6.1,11.3c9.1,28.8-9,57.6-33.2,71
		c-2.4,1.7-3.9,3-4.9,4.2c-1.3,1.5-2,3.5-2,5.4v2.1h75.9V0C187.7,0,114.2,0,114.1,0z"
          fill="#8DFFBA"
        />
        <circle cx="71.9" cy="66.7" r="1.9" />
        <circle cx="82.5" cy="86.6" r="2.6" />
        <circle cx="107.4" cy="79.2" r="2.6" fill="#8DFFBA" />
        <circle cx="84.5" cy="121.2" r="2.2" fill="#826EEE" />
        <circle cx="66" cy="31.9" r="1.9" fill="#8DFFBA" />
      </g>
    </svg>
  );
};
