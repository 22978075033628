import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockShow3D } from '../../components/Blocks/BlockShow3D';
export const Clip3d: FC = () => {
  return (
    <WrapperPage>
      <BlockShow3D />
    </WrapperPage>
  );
};
