import { BlockPartnerType } from '../../../../helpers/types';

export const BlockPartnerProgramItems: BlockPartnerType[] = [
  {
    title: 'b2c',
    icon: 'partnerHome',
    text: ['Бизнес услуги для', 'физических лиц', '(Жилые дома)'],
  },
  {
    title: 'b2b',
    icon: 'partnerShop',
    text: ['Бизнес услуги для', 'юридических лиц', '(Коммерческие,', 'общественные', 'помещения)'],
  },
  {
    title: 'b2g',
    icon: 'partnerMuseum',
    text: ['Бизнес услуги для ', 'государственных лиц', '(Государственные', 'здания)'],
  },
];
