import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { theme } from '../../themes/theme';

const scale = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(0.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
interface PropsAnim {
  daley: number;
}
const animation = (props: PropsAnim) =>
  css<Props>`
    ${scale} 900ms ${props.daley}ms infinite;
  `;
interface CircleProps extends PropsAnim {
  size: number;
  top: number;
  left: number;
  color: string;
}
const CircleStyle = styled.div.withConfig({
  shouldForwardProp: prop => !['size', 'top', 'left', 'color'].includes(prop),
})<CircleProps>`
  position: absolute;
  background-color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  animation: ${animation};
`;
interface WrapProps {
  size?: number;
}
const Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['width', 'height'].includes(prop),
})<WrapProps>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  //background-color: rgba(97, 218, 251, 0.49);
`;

interface Props extends WrapProps {
  color?: string;
}
export const LoadIndicator: FC<Props> = ({ size = 50, color = theme.colors.softBlueViolet }) => {
  const getCircles = () => {
    return Array.from('012345678').map((item, i) => {
      const _size = size / 4.6;
      const x = (size / 2 - _size / 2) * Math.sin(i * (-Math.PI / 4.5)) + (size / 2 - _size / 2);
      const y = (size / 2 - _size / 2) * Math.cos(i * (-Math.PI / 4.5)) + (size / 2 - _size / 2);
      return (
        <CircleStyle key={item} size={_size} left={x} top={y} daley={i * 100 + 100} color={color} />
      );
    });
  };
  return <Wrapper size={size}>{getCircles()}</Wrapper>;
};
