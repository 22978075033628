import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Texts/Text';
import { setSelectPage } from '../../../api/store/functionsWitingCache';
import { PatchesName } from '../../../__generated__/types';
import { theme } from '../../../themes/theme';

const WrapperBox = styled(Box)`
  position: absolute;
  left: calc(50% - 100px);
  top: calc(32% + 100px);
  border: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  transition: color 200ms, background-color 200ms, transform 100ms, opacity 1000ms;
  //margin-top: -50px;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${props => props.theme.colors.white};
  }
  z-index: 2;
  opacity: 0;
`;

export const Details: FC = () => {
  const wrapper = useRef<HTMLDivElement>(null);

  const handleScaleAnimate = () => {
    const scale = Math.max(window.innerWidth, window.innerHeight) / 120;
    if (wrapper.current) {
      wrapper.current.animate([{ transform: `scale(${scale}, ${scale})` }], {
        duration: 300,
        easing: 'linear',
      }).onfinish = () => {
        if (wrapper.current) {
          wrapper.current.style.transform = `scale(${scale}, ${scale})`;
        }
        setSelectPage({
          dataWrite: {
            selectPage: PatchesName.Technology,
          },
        });
      };
    }
  };
  const handleDownBox = () => {
    if (wrapper.current) {
      wrapper.current.style.transform = 'scale(0.8, 0.8)';
    }
  };
  const handleUpBox = () => {
    if (wrapper.current) {
      wrapper.current.style.transition = 'none';
      wrapper.current.style.transform = 'scale(1, 1)';
      wrapper.current.style.color = theme.colors.white;
      handleScaleAnimate();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (wrapper.current) {
        wrapper.current.style.opacity = '1';
      }
    }, 1000);
  }, []);
  return (
    <WrapperBox
      ref={wrapper}
      width="200px"
      height="200px"
      borderRadius="50%"
      onMouseDown={handleDownBox}
      onMouseUp={handleUpBox}
    >
      <Text fontFamily="Neue Machina" size="20px" weight={300}>
        ПОДРОБНОСТИ
      </Text>
    </WrapperBox>
  );
};
