import React, { CSSProperties, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../Texts/Text';
import { SpanColor } from '../SpanColor';

interface PropsStyle {
  fontSize?: CSSProperties['fontSize'];
  fontWeight?: CSSProperties['fontWeight'];
  lineHeight?: CSSProperties['lineHeight'];
  textAlign?: CSSProperties['textAlign'];
}
interface Props extends PropsStyle {
  text: string[];
  fontFamily: 'Neue Machina' | 'Gilroy';
}
const TextStyle = styled(Text).withConfig({
  shouldForwardProp: prop => !['fontSize', 'fontWeight', 'lineHeight', 'textAlign'].includes(prop),
})<PropsStyle>`
  ${prop =>
    prop?.fontWeight &&
    css<PropsStyle>`
      font-weight: ${({ fontWeight }) => fontWeight};
    `};
  ${prop =>
    prop?.lineHeight &&
    css<PropsStyle>`
      line-height: ${({ lineHeight }) => lineHeight};
    `};
  font-size: ${({ fontSize }) => fontSize || '20px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export const TextMultiColor: FC<Props> = ({ text, ...props }) => {
  const getText = () => {
    const arrText: Array<string | ReactNode> = [];
    for (let i = 0; i < text.length; i++) {
      if (text[i].includes('%')) {
        const spl = text[i].split('%');
        if (spl.length === 3) {
          arrText.push(spl[0]);
          arrText.push(<SpanColor key={i.toString()}>{spl[1]}</SpanColor>);
          arrText.push(spl[2]);
          arrText.push(<br key={i.toString() + 'a'} />);
        }
      } else {
        arrText.push(text[i], <br key={i.toString()} />);
      }
    }
    return arrText;
  };
  return <TextStyle {...props}>{getText()}</TextStyle>;
};
