import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Box } from '../../../Box';
import { theme } from '../../../../themes/theme';
import styled from 'styled-components';
import { CircleWithNumber } from '../../../CircleWithNumber';
import { Text } from '../../../Texts/Text';
import { SpanColor } from '../../../SpanColor';

const ContainerNumber = styled.div`
  position: absolute;
  top: -60px;
  left: calc(50% - 40px);
  @media (max-height: 1040px) {
    transform: scale(0.8, 0.8);
  }
  @media (max-height: 890px) or (max-width: 1320px) {
    transform: scale(0.7, 0.7);
  }
  @media (max-height: 780px) {
    top: -50px;
    transform: scale(0.5, 0.5);
  }
`;
const BoxMedia = styled(Box)`
  width: 100%;
  max-width: 560px;
  @media (max-height: 1040px) {
    width: 49%;
  }
  @media (max-height: 890px) or (max-width: 1320px) {
    height: 120px;
    padding: 0;
  }
  @media (max-height: 780px) {
    height: 110px;
  }
  //@media (max-width: 560px) {
  //  height: auto;
  //  padding: 26px 0;
  //  border-radius: 18px;
  //}
`;
const TextMedia = styled(Text)`
  @media (max-height: 780px) {
    font-size: 18px;
    line-height: 20px;
  }
`;
type FontSizeType = {
  size: string;
  lineHeight: string;
};
interface Props {
  titleNumber: string;
  text: string[];
}
export const ItemBlock: FC<Props> = ({ titleNumber, text }) => {
  const [fontProp, setFontProp] = useState<FontSizeType>({
    size: '22px',
    lineHeight: '26px',
  });
  const boxMedia = useRef<HTMLDivElement>(null);
  const textMedia = useRef<HTMLSpanElement>(null);
  const calcFontSize = () => {
    if (boxMedia.current) {
      const w = boxMedia.current.getBoundingClientRect().width;
      // console.log(w);
      // return `${w * 0.045}px`;
      setFontProp({
        size: `${w * 0.04}px`,
        lineHeight: `${w * 0.04 + 4}px`,
      });
    }
  };
  const getText = () => {
    const arrText: Array<string | ReactNode> = [];
    for (let i = 0; i < text.length; i++) {
      if (text[i].includes('%')) {
        const spl = text[i].split('%');
        if (spl.length === 3) {
          arrText.push(spl[0]);
          arrText.push(<SpanColor key={i.toString()}>{spl[1]}</SpanColor>);
          arrText.push(spl[2]);
          arrText.push(<br key={i.toString() + 'a'} />);
        }
      } else {
        arrText.push(text[i], <br key={i.toString()} />);
      }
    }
    return arrText;
  };
  const onResize = () => {
    if (textMedia.current) {
      calcFontSize();
    }
  };

  useEffect(() => {
    calcFontSize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <BoxMedia
      ref={boxMedia}
      position="relative"
      height="160px"
      borderRadius="30px"
      background={theme.colors.lightGray}
      padding="0 16px"
    >
      <ContainerNumber>
        <CircleWithNumber size="large" text={titleNumber} />
      </ContainerNumber>
      <TextMedia ref={textMedia} fontFamily="Gilroy" weight={400} textAlign="center" {...fontProp}>
        {getText()}
      </TextMedia>
    </BoxMedia>
  );
};
