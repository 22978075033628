import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type EmailVerificationCodeGetQueryVariables = Types.Exact<{
  input: Types.EmailVerificationCodeGetInput;
}>;


export type EmailVerificationCodeGetQuery = { __typename?: 'Query', emailVerificationCodeGet: { __typename?: 'EmailVerificationResponse', success: boolean } };


export const EmailVerificationCodeGetDocument = gql`
    query EmailVerificationCodeGet($input: EmailVerificationCodeGetInput!) {
  emailVerificationCodeGet(input: $input) {
    success
  }
}
    `;

/**
 * __useEmailVerificationCodeGetQuery__
 *
 * To run a query within a React component, call `useEmailVerificationCodeGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerificationCodeGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerificationCodeGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailVerificationCodeGetQuery(baseOptions: Apollo.QueryHookOptions<EmailVerificationCodeGetQuery, EmailVerificationCodeGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailVerificationCodeGetQuery, EmailVerificationCodeGetQueryVariables>(EmailVerificationCodeGetDocument, options);
      }
export function useEmailVerificationCodeGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerificationCodeGetQuery, EmailVerificationCodeGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailVerificationCodeGetQuery, EmailVerificationCodeGetQueryVariables>(EmailVerificationCodeGetDocument, options);
        }
export type EmailVerificationCodeGetQueryHookResult = ReturnType<typeof useEmailVerificationCodeGetQuery>;
export type EmailVerificationCodeGetLazyQueryHookResult = ReturnType<typeof useEmailVerificationCodeGetLazyQuery>;
export type EmailVerificationCodeGetQueryResult = Apollo.QueryResult<EmailVerificationCodeGetQuery, EmailVerificationCodeGetQueryVariables>;