import React, { FC, PropsWithChildren, createContext, useContext, useState, useRef } from 'react';
import { ITheme } from '../themes/styled';

type ColorType = keyof ITheme['colors'];
interface PropsContext {
  isOpacity: boolean;
  currentColorHeader: ColorType;
  isFixedHeader: boolean;
  isVisibleFixedHeader: boolean;
  onChangeButtonHeader(val: boolean): void;
  changeColor(color: ColorType): void;
  setIsHover(val: boolean): void;
  handleVisibleFixedHeader(val: boolean): void;
  closeHeaderFixed(): void;
}
const defaultValue: PropsContext = {
  isOpacity: false,
  isFixedHeader: false,
  isVisibleFixedHeader: false,
  currentColorHeader: 'black',
  onChangeButtonHeader() {
    /**/
  },
  changeColor() {
    /**/
  },
  setIsHover() {
    /**/
  },
  handleVisibleFixedHeader() {
    /**/
  },
  closeHeaderFixed() {
    /**/
  },
};
const Context = createContext<PropsContext>(defaultValue);

export const useAppContext = () => {
  return useContext<PropsContext>(Context);
};

export const AppContext: FC<PropsWithChildren> = ({ children }) => {
  const [currentColorHeader, setCurrentColorHeader] = useState<ColorType>(
    defaultValue.currentColorHeader
  );
  const [isFixedHeader, setIsFixedHeader] = useState(false);
  const [isVisibleFixedHeader, setIsVisibleFixedHeader] = useState(false);

  const temporaryColor = useRef<ColorType>(defaultValue.currentColorHeader);
  const isHover = useRef(false);
  const isOpacity = useRef(defaultValue.isOpacity);
  const temporaryFirst = useRef(false);

  const changeColor = (color: ColorType) => {
    if (!isHover.current) {
      setCurrentColorHeader(color);
    }
    temporaryColor.current = color;
  };
  const setIsHover = (val: boolean) => {
    isHover.current = val;
    if (val) {
      setCurrentColorHeader('white');
    } else {
      setCurrentColorHeader(temporaryColor.current);
      setIsVisibleFixedHeader(false);
    }
  };
  const onChangeButtonHeader = (val: boolean) => {
    if (val !== temporaryFirst.current) {
      setIsFixedHeader(val);
      temporaryFirst.current = val;
      if (!val) {
        setIsVisibleFixedHeader(val);
        isOpacity.current = false;
      } else {
        setTimeout(() => {
          isOpacity.current = true;
        }, 1000);
      }
    }
  };
  const handleVisibleFixedHeader = (val: boolean) => {
    setIsVisibleFixedHeader(val);
  };
  const closeHeaderFixed = () => {
    setCurrentColorHeader(temporaryColor.current);
    setIsVisibleFixedHeader(false);
  };

  return (
    <Context.Provider
      value={{
        isOpacity: isOpacity.current,
        isVisibleFixedHeader,
        isFixedHeader,
        onChangeButtonHeader,
        currentColorHeader,
        changeColor,
        setIsHover,
        handleVisibleFixedHeader,
        closeHeaderFixed,
      }}
    >
      {children}
    </Context.Provider>
  );
};
