import React, { Dispatch, FC, HTMLProps, SetStateAction } from 'react';
import { theme } from '../../themes/theme';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneField, { Value } from 'react-phone-number-input';
import { SpanColor } from '../SpanColor';
import { Text } from '../Texts/Text';
import { Box } from '../Box';
import { InputError } from '../InputError';

const WrapperStyle = styled(Box)`
  position: relative;
`;
const InputPhone = styled.input`
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 0;
  padding: 0 20px;
  margin-left: -20px;
  font-family: Gilroy, serif;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: Gilroy, serif;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.lightGray_100};
  }
`;
interface Props {
  title: string;
  value?: Value;
  isRequired?: boolean;
  setValue: Dispatch<SetStateAction<Value | undefined>>;
  inputProps?: HTMLProps<HTMLInputElement>;
  error?: string;
}
export const PhoneInput: FC<Props> = ({
  value,
  setValue,
  title,
  isRequired,
  inputProps,
  error,
}) => {
  return (
    <WrapperStyle direction="column" align="flex-start" padding="0 0 0 20px">
      <Text fontFamily="Neue Machina" weight={700} size="18px" marginBottom="6px">
        {title}
        {isRequired && <SpanColor>*</SpanColor>}
      </Text>
      <PhoneField
        international
        placeholder="Номер телефона"
        value={value}
        onChange={setValue}
        style={{
          width: '100%',
          height: '40px',
          fontSize: '16px',
          borderRadius: '23px',
          border: 'none',
          padding: '0 20px',
          marginTop: '5px',
          backgroundColor: theme.colors.lightGray,
          marginLeft: '-20px',
        }}
        inputComponent={InputPhone}
        numberInputProps={inputProps}
      />
      <InputError error={error} />
    </WrapperStyle>
  );
};
