/* eslint-disable no-console */
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { QueryResult } from '@apollo/client';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { theme } from '../../../themes/theme';
import { Text } from '../../../components/Texts/Text';
import { SpanColor } from '../../../components/SpanColor';
import { CustomInputStyle } from '../../../components/Styles';
import { TimerActive } from './TimerActive';
import {
  EmailVerificationCodeCheckQuery,
  EmailVerificationCodeCheckQueryVariables,
} from '../../../graphql/queries/__generated__/emailVerificationCodeCheck';
import { InputError } from '../../../components/InputError';

const WrapperBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const WrapperInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
  width: 130px;
`;
interface Props {
  email: string;
  onResend(): void;
  submit(): void;
  checkCodeEmail(prop: {
    variables: EmailVerificationCodeCheckQueryVariables;
  }): Promise<
    QueryResult<EmailVerificationCodeCheckQuery, EmailVerificationCodeCheckQueryVariables>
  >;
}

export const CodeDialog: FC<Props> = ({ email, onResend, submit, checkCodeEmail }) => {
  const [disabled, setDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handlerInputEvent = (evt: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    // console.log(evt.target.value);
    const isValid = evt.target.value.match(/[^0-9]/g);
    if (isValid) {
      evt.target.value = evt.target.value.replace(/[^0-9]/g, '');
    }
    if (evt.target.value.length >= 6) {
      const code = evt.target.value.slice(0, 6);
      setDisabled(true);
      evt.target.value = code;
      submitCheckCodeEmail(+code).then();
    }
  };
  const submitCheckCodeEmail = async (code: number) => {
    try {
      const respCode = await checkCodeEmail({
        variables: {
          input: {
            email: email,
            code,
          },
        },
      });
      if (respCode.data?.emailVerificationCodeCheck) {
        const { success } = respCode.data.emailVerificationCodeCheck;
        if (success) {
          submit();
        } else {
          setIsError(true);
        }
      }
    } catch (err) {
      console.log('[ERROR submitCheckCodeEmail]', err);
    } finally {
      setDisabled(false);
    }
  };
  const handleOnResend = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setIsError(false);
    onResend();
  };

  return (
    <Box
      width="916px"
      height="100px"
      borderRadius="30px"
      background={theme.colors.lightGray}
      padding="0 40px"
    >
      <WrapperBox>
        <Text fontFamily="Neue Machina" size="16px" weight={700}>
          {'На '}
          <SpanColor>{`${email} `}</SpanColor>
          {'был отправлен'}
          <br />
          <SpanColor>6-значный код,</SpanColor>
          {' укажите его для '}
          <SpanColor>подтверждения</SpanColor>
        </Text>
      </WrapperBox>
      <WrapperInput>
        <CustomInputStyle
          ref={inputRef}
          isError={isError}
          placeholder="000000"
          color={theme.colors.white}
          onInput={handlerInputEvent}
          disabled={disabled}
          letterSpacing="2px"
        />
        <InputError error={isError ? 'Неверный код' : undefined} left="10px" />
      </WrapperInput>
      <Box width="220px" height="100%" justify="flex-end">
        <TimerActive handlerResend={handleOnResend} />
      </Box>
    </Box>
  );
};
