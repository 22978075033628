import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../../../../components/Box';
import { theme } from '../../../../../themes/theme';
import { Text } from '../../../../../components/Texts/Text';
import { EDIT_CLASS_OBJECT } from '../../../../../helpers/constans';
import { Wall } from '../../../canvas/classes/Wall';
import { SliderWall } from '../components/SliderWall';

export const WrapperButton = styled(Box)`
  cursor: pointer;
`;

interface Props {
  closePanel(): void;
}

export const PanelChangeTexture: FC<Props> = ({ closePanel }) => {
  const [wall] = useState<Wall | undefined>(() => {
    const obj = EDIT_CLASS_OBJECT.getSelectedObject();
    if (obj && obj instanceof Wall) {
      return obj;
    }
    return undefined;
  });
  const handleSlideZoomTexture = (val: string) => {
    if (wall && wall?.panels) {
      wall.panels.scalingTexture(+val);
    }
  };
  const handleDownSlider = () => {
    wall?.panels?.moveTextureStart();
  };
  const handleUpSlider = (val: string) => {
    wall?.panels?.scalingTextureEnd(+val);
  };
  const handleSlideMoveHorizonTexture = (val: string, cord: 'x' | 'y') => {
    if (wall && wall?.panels) {
      wall.panels.moveTexture(+val, cord);
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      background={theme.colors.white}
      borderRadius="20px"
      direction="column"
      padding="0 40px"
    >
      <Box width="100%" margin="20px 0 30px 0">
        <WrapperButton
          width="250px"
          height="50px"
          borderRadius="25px"
          background={theme.colors.softBlueViolet}
          onClick={closePanel}
        >
          <Text fontFamily="Neue Machina" size="18px" weight={700} color="white">
            Изменить
          </Text>
        </WrapperButton>
      </Box>
      <Text fontFamily="Neue Machina" size="20px" weight={700} marginBottom="16px">
        Уменьшить/Увеличить
      </Text>
      <SliderWall
        min="-0.1"
        max="0.1"
        step="0.002"
        initValue="0"
        handleChangeValue={handleSlideZoomTexture}
        handleDown={handleDownSlider}
        handleUp={handleUpSlider}
      />
      <Text fontFamily="Neue Machina" size="20px" weight={700} marginBottom="16px">
        Двигать по горизонтали
      </Text>
      <SliderWall
        min="-0.1"
        max="0.1"
        step="0.002"
        handleChangeValue={val => handleSlideMoveHorizonTexture(val, 'x')}
        handleDown={handleDownSlider}
      />
      <Text fontFamily="Neue Machina" size="20px" weight={700} marginBottom="16px">
        Двигать по вертикали
      </Text>
      <SliderWall
        min="-0.1"
        max="0.1"
        step="0.002"
        handleChangeValue={val => handleSlideMoveHorizonTexture(val, 'y')}
        handleDown={handleDownSlider}
      />
    </Box>
  );
};
