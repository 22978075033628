import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockSteps } from '../../components/Blocks/BlockSteps';

export const Steps: FC = () => {
  return (
    <WrapperPage>
      <BlockSteps />
    </WrapperPage>
  );
};
