import { apolloLink } from './apolloLinks';
import { ApolloClient } from '@apollo/client';
import { apolloCache } from './apolloCacheConfiguration';
import { LOCALE_SCHEME } from './store/localeScheme';

export const apolloClient = new ApolloClient({
  link: apolloLink,
  cache: apolloCache,
  typeDefs: LOCALE_SCHEME,
});
