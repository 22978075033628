import React, { CSSProperties, FC } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../../../../../components/Icons/Icon';
import { PatchesName } from '../../../../../__generated__/types';
import { setSelectPage } from '../../../../../api/store/functionsWitingCache';

type PropsWrapperButton = Pick<CSSProperties, 'left' | 'top' | 'bottom' | 'right'>;
export const WrapperButton = styled('div').withConfig({
  shouldForwardProp: prop => !Object.keys(prop).includes(prop),
})<PropsWrapperButton>`
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: absolute;
  ${props =>
    !!props?.left &&
    css<PropsWrapperButton>`
      left: ${props => props.left};
    `};
  ${props =>
    !!props?.right &&
    css<PropsWrapperButton>`
      right: ${props => props.right};
    `};
  ${props =>
    !!props?.top &&
    css<PropsWrapperButton>`
      top: ${props => props.top};
    `};
  ${props =>
    !!props?.bottom &&
    css<PropsWrapperButton>`
      bottom: ${props => props.bottom};
    `};
`;
interface Props {
  openPaneMeasure(): void;
  openPanelTotal(): void;
}
export const ButtonBlock: FC<Props> = ({ openPaneMeasure, openPanelTotal }) => {
  const handleHomeButton = () => {
    setSelectPage({
      dataWrite: {
        selectPage: PatchesName.Home,
      },
    });
  };
  return (
    <>
      <WrapperButton onClick={handleHomeButton} left="30px" top="30px">
        <Icon name="homeButton" width="100%" height="100%" />
      </WrapperButton>
      <WrapperButton left="30px" bottom="30px" onClick={openPaneMeasure}>
        <Icon name="measure" width="100%" height="100%" />
      </WrapperButton>
      <WrapperButton onClick={openPanelTotal} right="30px" bottom="30px">
        <Icon name="basket" width="100%" height="100%" />
      </WrapperButton>
    </>
  );
};
