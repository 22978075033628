import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type CategoriesGetAllQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CategoriesGetAllQuery = { __typename?: 'Query', categoriesGetAll: { __typename?: 'TextureCategoriesResponse', categories?: Array<{ __typename?: 'TextureCategory', description?: string | null, id: number, name: string, subcategory?: Array<{ __typename?: 'TextureSubCategory', id: number, name: string, description?: string | null } | null> | null } | null> | null } };


export const CategoriesGetAllDocument = gql`
    query CategoriesGetAll {
  categoriesGetAll {
    categories {
      description
      id
      name
      subcategory {
        id
        name
        description
      }
    }
  }
}
    `;

/**
 * __useCategoriesGetAllQuery__
 *
 * To run a query within a React component, call `useCategoriesGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesGetAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesGetAllQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesGetAllQuery, CategoriesGetAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesGetAllQuery, CategoriesGetAllQueryVariables>(CategoriesGetAllDocument, options);
      }
export function useCategoriesGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesGetAllQuery, CategoriesGetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesGetAllQuery, CategoriesGetAllQueryVariables>(CategoriesGetAllDocument, options);
        }
export type CategoriesGetAllQueryHookResult = ReturnType<typeof useCategoriesGetAllQuery>;
export type CategoriesGetAllLazyQueryHookResult = ReturnType<typeof useCategoriesGetAllLazyQuery>;
export type CategoriesGetAllQueryResult = Apollo.QueryResult<CategoriesGetAllQuery, CategoriesGetAllQueryVariables>;