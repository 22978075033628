import React, { FC, useEffect, useRef, useState } from 'react';
import { theme } from '../../themes/theme';
import styled from 'styled-components';
import { WrapperPage } from '../../components/WrapperPage';
import { Box } from '../../components/Box';
import { Text } from '../../components/Texts/Text';
import { DemoBlock } from './components/DemoBlock';
import { ImagesPanel1, ImagesPanel2 } from './components/Images';
import { TextTitle } from '../../components/TextTitle';
import { SwitchSlider } from './components/SwitchSlider';
import { BlockIcons } from '../../components/Blocks/BlockIcons';

interface IProps {
  size: number;
}
const BoxPerspective = styled.div.withConfig({
  shouldForwardProp: prop => !['size'].includes(prop),
})<IProps>`
  width: 610px;
  height: 100%;
  position: relative;
  perspective: ${({ size }) => size * 3}px;
`;
const TextStyle = styled(Text)`
  @media (max-height: 890px) {
    font-size: 21px;
    margin-bottom: 40px;
  }
`;
const TextStyle1 = styled(Text)`
  @media (max-height: 890px) {
    font-size: 21px;
  }
`;
const BlockDecor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
`;
const BlockAdvantages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  opacity: 0;
`;

export const Decor3d: FC = () => {
  const [sizeContainer, setSizeContainer] = useState(() => {
    return window.innerHeight < 890 ? 320 : 400;
  });
  const [isRight, setIsRight] = useState(true);
  const blDecor = useRef<HTMLDivElement>(null);
  const blAdvantages = useRef<HTMLDivElement>(null);

  function handleResizeWindow() {
    setSizeContainer(window.innerHeight < 890 ? 320 : 400);
  }

  useEffect(() => {
    if (blAdvantages.current && blDecor.current) {
      if (isRight) {
        blDecor.current.style.transform = 'translateX(-100%)';
        blDecor.current.style.opacity = '1';
        blAdvantages.current.style.transform = 'translateX(-100%)';
        blAdvantages.current.style.opacity = '0';
      } else {
        blDecor.current.style.transform = 'translateX(0)';
        blDecor.current.style.opacity = '0';
        blAdvantages.current.style.transform = 'translateX(0)';
        blAdvantages.current.style.opacity = '1';
      }
    }
  }, [isRight]);
  useEffect(() => {
    setTimeout(() => {
      if (blAdvantages.current && blDecor.current) {
        blDecor.current.style.transition =
          'opacity 400ms ease, transform 400ms cubic-bezier(0.38, 0.29, 0.65, 1.8)';
        blAdvantages.current.style.transition =
          'opacity 400ms ease, transform 400ms cubic-bezier(0.38, 0.29, 0.65, 1.8)';
      }
    }, 500);

    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);
  return (
    <WrapperPage backgroundColor={theme.colors.lightGray}>
      <Box width="100%" height="100%">
        <Box width="580px" height="100%" direction="column" align="flex-start">
          <SwitchSlider isRight={isRight} setIsRight={setIsRight} />
          <Box
            width="200%"
            position="relative"
            background={theme.colors.lightGray}
            align="flex-start"
          >
            <BlockAdvantages ref={blAdvantages}>
              <TextTitle
                text={['%доп-%преимущества']}
                textAlign="left"
                textProps={{ marginBottom: '30px' }}
              />
              <BlockIcons />
            </BlockAdvantages>
            <BlockDecor ref={blDecor}>
              <TextTitle
                text={['%3d% decor']}
                textAlign="left"
                textProps={{ marginBottom: '30px' }}
              />
              <TextStyle1 fontFamily="Gilroy" weight={500} size="20px" marginBottom="20px">
                Строительно-декоративный материал предназначен для{' '}
                <TextStyle1 fontFamily="Gilroy" weight={700} size="20px">
                  внутренних отделочных работ по облицовке стен и потолков.
                </TextStyle1>
              </TextStyle1>
              <TextStyle fontFamily="Gilroy" weight={500} size="20px" marginBottom="80px">
                3D Decor позволяет создавать {` `}
                <TextStyle fontFamily="Gilroy" weight={700} size="20px">
                  дизайны любой сложности {` `}
                </TextStyle>
                и визуализировать их с
                <TextStyle fontFamily="Gilroy" weight={700} size="20px">
                  поразительной реалистичностью.
                </TextStyle>
              </TextStyle>
              <Text fontFamily="Neue Machina" weight={700} size="24px" lineHeight="32px">
                Размер: {` `}
                <Text fontFamily="Gilroy" weight={500} size="25px" lineHeight="32px">
                  1000/1000/41 мм
                </Text>
              </Text>
              <Text fontFamily="Neue Machina" weight={700} size="24px" lineHeight="32px">
                Вес: {` `}
                <Text fontFamily="Gilroy" weight={500} size="25px" lineHeight="32px">
                  5,6 кг
                </Text>
              </Text>
              <Text fontFamily="Neue Machina" weight={700} size="24px" marginBottom="40px">
                Стоимость: {` `}
                <Text fontFamily="Gilroy" weight={500} size="25px">
                  х/м2
                </Text>
              </Text>
              <Text fontFamily="Neue Machina" size="40px" weight={700} marginBottom="30px">
                Гарантия {` `}
                <span style={{ color: theme.colors.softBlueViolet }}>10 </span>
                лет
              </Text>
            </BlockDecor>
          </Box>
        </Box>
        <BoxPerspective size={sizeContainer}>
          <DemoBlock
            cubeSize={sizeContainer}
            transform={`
            rotateY(30deg) 
            rotateX(6deg) 
            rotateZ(-8deg) 
            translateX(360px) 
            translateZ(-100px) 
            translateY(10px)`}
            images={ImagesPanel2}
          />
          <DemoBlock
            cubeSize={sizeContainer}
            transform="rotateY(-26deg) rotateX(10deg) rotateZ(8deg) translateX(-130px)"
            images={ImagesPanel1}
          />
        </BoxPerspective>
      </Box>
    </WrapperPage>
  );
};
