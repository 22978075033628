import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockAdvantages } from '../../components/Blocks/BlockAdvantages';

export const Advantages: FC = () => {
  return (
    <WrapperPage>
      <BlockAdvantages />
    </WrapperPage>
  );
};
