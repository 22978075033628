import { RefObject, useEffect, useRef } from 'react';
import { BlockType, setParamElement } from '../constans';

interface ReturnHook {
  divRef: RefObject<HTMLDivElement>;
}
export const useSetParamsElement = (blockType: BlockType): ReturnHook => {
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        setParamElement(divRef.current, blockType);
      }
    }, 500);
  }, []);

  return { divRef };
};
