import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { Icon } from '../../../components/Icons/Icon';
import { theme } from '../../../themes/theme';

const Path = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.softBlueViolet_50};
`;
const PressBox = styled(Box)`
  margin-left: 10px;
  cursor: pointer;
  z-index: 2;
  transition: transform 400ms cubic-bezier(0.38, 0.29, 0.65, 1.8);
  &:hover {
    background-color: ${theme.colors.softBlueViolet};
  }
`;
const ContRotate = styled(Box)``;

interface Props {
  isRight: boolean;
  setIsRight: Dispatch<SetStateAction<boolean>>;
}

export const SwitchSlider: FC<Props> = ({ isRight, setIsRight }) => {
  const pressBox = useRef<HTMLDivElement>(null);
  const contRotate = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pressBox.current && contRotate.current) {
      if (isRight) {
        pressBox.current.style.transform = 'translateX(0)';
        contRotate.current.style.transform = 'rotateZ(0)';
      } else {
        pressBox.current.style.transform = 'translateX(100px)';
        contRotate.current.style.transform = 'rotateZ(-180deg)';
      }
    }
  }, [isRight]);

  return (
    <Box width="160px" height="50px" margin="0 0 10px 0" position="relative" justify="flex-start">
      <Path />
      <PressBox
        ref={pressBox}
        width="38px"
        height="38px"
        borderRadius="50%"
        background={theme.colors.black}
        onClick={() => setIsRight(!isRight)}
      >
        <ContRotate ref={contRotate}>
          <Icon name="arrowPadRight" fill={theme.colors.white} />
        </ContRotate>
      </PressBox>
    </Box>
  );
};
