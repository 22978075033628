import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type OpenPanelAddImageAndDoorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OpenPanelAddImageAndDoorQuery = { __typename?: 'Query', isOpenPanelAddImageAndDoor: boolean };


export const OpenPanelAddImageAndDoorDocument = gql`
    query OpenPanelAddImageAndDoor {
  isOpenPanelAddImageAndDoor @client
}
    `;

/**
 * __useOpenPanelAddImageAndDoorQuery__
 *
 * To run a query within a React component, call `useOpenPanelAddImageAndDoorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenPanelAddImageAndDoorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenPanelAddImageAndDoorQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenPanelAddImageAndDoorQuery(baseOptions?: Apollo.QueryHookOptions<OpenPanelAddImageAndDoorQuery, OpenPanelAddImageAndDoorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenPanelAddImageAndDoorQuery, OpenPanelAddImageAndDoorQueryVariables>(OpenPanelAddImageAndDoorDocument, options);
      }
export function useOpenPanelAddImageAndDoorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenPanelAddImageAndDoorQuery, OpenPanelAddImageAndDoorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenPanelAddImageAndDoorQuery, OpenPanelAddImageAndDoorQueryVariables>(OpenPanelAddImageAndDoorDocument, options);
        }
export type OpenPanelAddImageAndDoorQueryHookResult = ReturnType<typeof useOpenPanelAddImageAndDoorQuery>;
export type OpenPanelAddImageAndDoorLazyQueryHookResult = ReturnType<typeof useOpenPanelAddImageAndDoorLazyQuery>;
export type OpenPanelAddImageAndDoorQueryResult = Apollo.QueryResult<OpenPanelAddImageAndDoorQuery, OpenPanelAddImageAndDoorQueryVariables>;