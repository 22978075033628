import React, { FC, SVGProps } from 'react';
import IconsConfig from './IconsConfig';

export type IconNamesType = keyof typeof IconsConfig;
interface Props extends SVGProps<SVGElement> {
  name: IconNamesType;
}
export const Icon: FC<Props> = ({ name, ...props }) => {
  const IconC = IconsConfig[name];
  return <IconC {...props} />;
};
