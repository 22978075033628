import React, { FC, MouseEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';

const HEIGHT_THUMB = 200;

interface PropsStyle {
  widthBar: number;
}
const ScrollBarThumbStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['widthBar'].includes(prop),
})<PropsStyle>`
  width: ${props => `${props.widthBar}px`};
  height: ${HEIGHT_THUMB}px;
  background-color: #282c34;
  border-radius: ${props => `${props.widthBar / 2}px`};
  //transform: translateY();
`;

interface Props {
  changeOpacity(): void;
}
export const ScrollBarThumb: FC<Props & PropsStyle> = ({ changeOpacity, ...props }) => {
  const COI = useRef(0);
  const isSelectThumb = useRef({
    isMove: false,
    offset: 0,
  });

  const thumbRef = useRef<HTMLDivElement>(null);
  const handlerScroll = () => {
    changeOpacity();
    const offset = document.documentElement.scrollTop / COI.current;
    if (thumbRef.current) {
      thumbRef.current.style.transform = `translateY(${offset}px)`;
    }
  };
  const handlerResize = () => {
    COI.current =
      (document.documentElement.scrollHeight - document.documentElement.clientHeight) /
      (document.documentElement.clientHeight - HEIGHT_THUMB);
  };
  const handleMouseMove = (evt: MouseEvent) => {
    if (thumbRef.current && isSelectThumb.current.isMove) {
      const offset = evt.clientY - isSelectThumb.current.offset;
      // console.log(offset);
      if (offset > 0 && offset < document.documentElement.clientHeight - HEIGHT_THUMB) {
        thumbRef.current.style.transform = `translateY(${offset}px)`;
      }

      document.documentElement.scrollTop = offset * COI.current;
    }
  };
  const handleMouseDown = (evt: MouseEvent) => {
    isSelectThumb.current.isMove = true;
    isSelectThumb.current.offset = evt.nativeEvent.offsetY;
  };
  const handleMouseUp = () => {
    isSelectThumb.current.isMove = false;
    isSelectThumb.current.offset = 0;
  };

  useEffect(() => {
    document.onmousemove = function (e) {
      handleMouseMove(e as unknown as MouseEvent);
    };
    document.onmouseup = function () {
      handleMouseUp();
    };
    addEventListener('scroll', handlerScroll);
    addEventListener('resize', handlerResize);
    handlerResize();
    return () => {
      document.onmousemove = null;
      document.onmouseup = null;
      removeEventListener('scroll', handlerScroll);
      removeEventListener('resize', handlerResize);
    };
  }, []);

  return <ScrollBarThumbStyle ref={thumbRef} onMouseDown={handleMouseDown} {...props} />;
};
