import React, { CSSProperties, FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../../components/Icons/Icon';
import { EDIT_CLASS_OBJECT } from '../../../../../helpers/constans';

interface PropsBottomPanel {
  height?: number;
  angleArrow?: number;
  backgroundColor?: CSSProperties['backgroundColor'];
  isLeftPosition?: boolean;
}
const WrapperBottomPanel = styled.div.withConfig({
  shouldForwardProp: prop => !['height', 'angleArrow', 'isLeftPosition'].includes(prop),
})<PropsBottomPanel>`
  width: calc(100% - 40px);
  height: ${({ height }) => height || 400}px;
  z-index: 2;
  position: absolute;
  transform: ${({ isLeftPosition }) =>
    isLeftPosition ? 'translateX(-500px)' : 'translateY(500px)'};
`;
const WrapperButton = styled.div.withConfig({
  shouldForwardProp: prop => !['height', 'angleArrow'].includes(prop),
})`
  position: absolute;
  top: -70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
`;
const WrapperIcon = styled.div.withConfig({
  shouldForwardProp: prop => !['height', 'angleArrow', 'backgroundColor'].includes(prop),
})<PropsBottomPanel>`
  width: 60px;
  height: 60px;
  padding: 20px;
  border-radius: 30px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateZ(${({ angleArrow }) => angleArrow || 0}deg);
`;

interface Props extends PropsBottomPanel {
  closeModal: () => void;
  valueClose?: number;
  isRemove?: boolean;
  onRemove?: () => void;
  isLeftPosition?: boolean;
}

export const PanelWrapperBottom: FC<PropsWithChildren<Props>> = ({
  children,
  closeModal,
  valueClose,
  isRemove,
  onRemove,
  isLeftPosition,
  ...props
}) => {
  const [firstUseStateElement] = useState(valueClose);
  const wrapper = useRef<HTMLDivElement>(null);

  const handleEnterAnimate = (value: number, callback?: () => void) => {
    const trans = isLeftPosition
      ? { transform: `translateX(${value}px)` }
      : { transform: `translateY(${value}px)` };
    if (wrapper.current) {
      wrapper.current.animate([trans], {
        duration: 400,
        easing: 'linear',
      }).onfinish = () => {
        if (wrapper.current) {
          wrapper.current.style.transform = isLeftPosition
            ? `translateX(${value}px)`
            : `translateY(${value}px)`;
        }
        if (callback) {
          callback();
        }
      };
    }
  };
  const handleButtonArrow = () => {
    EDIT_CLASS_OBJECT.cleanSelectObject();
    const val = isLeftPosition ? -500 : 500;
    handleEnterAnimate(val, () => {
      closeModal();
    });
  };

  useEffect(() => {
    if (valueClose !== firstUseStateElement) {
      const val = isLeftPosition ? -500 : 500;
      handleEnterAnimate(val, closeModal);
    }
  }, [valueClose]);
  useEffect(() => {
    handleEnterAnimate(0);
  }, []);

  return (
    <WrapperBottomPanel ref={wrapper} isLeftPosition={isLeftPosition} {...props}>
      <WrapperButton onClick={handleButtonArrow} {...props}>
        <WrapperIcon {...props}>
          <Icon name="arrowLeft" width="100%" height="100%" />
        </WrapperIcon>
        {isRemove && (
          <WrapperIcon onClick={onRemove} backgroundColor={'#FDEBEB'}>
            <Icon name="trash" width="100%" height="100%" />
          </WrapperIcon>
        )}
      </WrapperButton>
      {children}
    </WrapperBottomPanel>
  );
};
