import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { theme } from '../../../themes/theme';
import { PatchesName } from '../../../__generated__/types';
import { setSelectPage } from '../../../api/store/functionsWitingCache';
import { LogoIcon } from './LogoIcon';

const BoxNoEvent = styled.div`
  cursor: pointer;
  width: 173px;
  display: flex;
  align-items: center;
`;

interface Props {
  color?: string;
}
export const Logo: FC<Props> = ({ color = theme.colors.white }) => {
  const handleClickLogo = () => {
    setSelectPage({
      dataWrite: {
        selectPage: PatchesName.Home,
      },
    });
  };
  return (
    <BoxNoEvent onClick={handleClickLogo} data-noeventhover={true}>
      <Box data-noeventhover={true} width="150px" height="34">
        <LogoIcon color={color} />
      </Box>
    </BoxNoEvent>
  );
};
