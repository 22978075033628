import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type TexturesGetByCategoryIdQueryVariables = Types.Exact<{
  input: Types.TextureCategoryFilterInput;
}>;


export type TexturesGetByCategoryIdQuery = { __typename?: 'Query', texturesGetByCategoryId: Array<{ __typename?: 'TextureFileResponse', id: string, name: string, categoryId: number, size: { __typename?: 'SizeEnum', ktx2: { __typename?: 'Size', height: number, url: string, width: number }, origin: { __typename?: 'Size', height: number, url: string, width: number }, s: { __typename?: 'Size', height: number, url: string, width: number }, m: { __typename?: 'Size', height: number, url: string, width: number } } }> };


export const TexturesGetByCategoryIdDocument = gql`
    query TexturesGetByCategoryId($input: TextureCategoryFilterInput!) {
  texturesGetByCategoryId(input: $input) {
    id
    name
    categoryId
    size {
      ktx2 {
        height
        url
        width
      }
      origin {
        height
        url
        width
      }
      s {
        height
        url
        width
      }
      m {
        height
        url
        width
      }
    }
  }
}
    `;

/**
 * __useTexturesGetByCategoryIdQuery__
 *
 * To run a query within a React component, call `useTexturesGetByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTexturesGetByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTexturesGetByCategoryIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTexturesGetByCategoryIdQuery(baseOptions: Apollo.QueryHookOptions<TexturesGetByCategoryIdQuery, TexturesGetByCategoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TexturesGetByCategoryIdQuery, TexturesGetByCategoryIdQueryVariables>(TexturesGetByCategoryIdDocument, options);
      }
export function useTexturesGetByCategoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TexturesGetByCategoryIdQuery, TexturesGetByCategoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TexturesGetByCategoryIdQuery, TexturesGetByCategoryIdQueryVariables>(TexturesGetByCategoryIdDocument, options);
        }
export type TexturesGetByCategoryIdQueryHookResult = ReturnType<typeof useTexturesGetByCategoryIdQuery>;
export type TexturesGetByCategoryIdLazyQueryHookResult = ReturnType<typeof useTexturesGetByCategoryIdLazyQuery>;
export type TexturesGetByCategoryIdQueryResult = Apollo.QueryResult<TexturesGetByCategoryIdQuery, TexturesGetByCategoryIdQueryVariables>;