import React, { CSSProperties, FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Texts/Text';
import { theme } from '../../../themes/theme';
import { TextMultiColor } from '../../../components/TextMultiColor';

interface PropsStyle {
  margin?: CSSProperties['margin'];
  pathImage?: string;
}
const BoxRotate = styled('div')`
  transition: all 800ms ease-in-out;
  overflow: hidden;
`;
const BoxBackground = styled(Box).withConfig({
  shouldForwardProp: prop => !['pathImage'].includes(prop),
})<Pick<PropsStyle, 'pathImage'>>`
  background-image: ${({ pathImage }) => `url(${pathImage})`};
  @media (max-height: 890px) {
    height: 180px;
  }
  @media (max-height: 720px) {
    height: 160px;
  }
`;
const BoxText = styled(Box)`
  @media (max-width: 1430px) {
    padding-right: 16px;
    padding-left: 16px;
  }
  @media (max-height: 1000px) {
    height: 240px;
    padding-top: 10px;
  }
  @media (max-height: 720px) {
    height: 210px;
  }
`;
const ContainerItemStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['margin'].includes(prop),
})<Pick<PropsStyle, 'margin'>>`
  width: 380px;
  border-bottom: ${({ theme }) => `solid 6px ${theme.colors.softBlueViolet}`};
  margin: ${({ margin }) => margin || 0};
  @media (max-width: 1430px) {
    width: 32%;
    border-bottom-width: 3px;
  }
  @media (max-height: 720px) {
    width: 32%;
    border-bottom-width: 3px;
  }
`;
const TextMediaTitle = styled(Text)`
  @media (max-height: 890px) or (max-width: 1430px) {
    font-size: 18px;
    margin: 12px;
  }
`;
const TextMultiColorMedia = styled(TextMultiColor)`
  @media (max-height: 720px) {
    font-size: 18px;
    line-height: 21px;
  }
`;
interface ImgProps {
  marginBottom: CSSProperties['marginBottom'];
}
const ImageStyle = styled.img.withConfig({
  shouldForwardProp: prop => !['marginBottom'].includes(prop),
})<ImgProps>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  transition: transform 2000ms ease;
  transform: translateY(100px);
`;

interface PropsComp {
  srcImage: string;
  srcPhone: string;
  offsetImagePhone: string;
  text: string[];
  title: string;
}
export const ContainerItem: FC<PropsStyle & PropsComp> = ({
  srcImage,
  srcPhone,
  text,
  title,
  offsetImagePhone,
  ...props
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (imgRef.current) {
      if (window.innerHeight >= 890) {
        imgRef.current.style.transform = 'translateY(0px)';
      } else if (window.innerHeight >= 720 && window.innerHeight < 890) {
        imgRef.current.style.transform = 'translateY(30px)';
      } else {
        imgRef.current.style.transform = 'translateY(60px)';
      }
    }
  }, []);
  return (
    <ContainerItemStyle {...props}>
      <BoxBackground width="100%" height="217px" pathImage={srcImage} align="flex-end">
        <BoxRotate>
          <ImageStyle marginBottom={offsetImagePhone} ref={imgRef} src={srcPhone} alt="image" />
        </BoxRotate>
      </BoxBackground>
      <BoxText
        width="100%"
        height="366px"
        background={theme.colors.lightGray}
        padding="40px 30px 0 30px"
        direction="column"
        justify="flex-start"
      >
        <TextMediaTitle
          fontFamily="Neue Machina"
          size="30px"
          weight={700}
          textAlign="center"
          marginBottom="25px"
          lineHeight="28px"
        >
          {title.toUpperCase()}
        </TextMediaTitle>
        <TextMultiColorMedia
          fontSize="20px"
          text={text}
          fontFamily="Neue Machina"
          textAlign="center"
          lineHeight="22px"
        />
      </BoxText>
    </ContainerItemStyle>
  );
};
