import { Group, Mesh, Scene } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const POS_START_Z = Math.PI / 2 + Math.PI / 4;
export const getClipObject = (scene: Scene) => {
  return new Promise<Mesh>((resolve, reject) => {
    const loader = new GLTFLoader();
    const path = new URL('../../../assets/models3d/iphone_15.glb', import.meta.url);
    loader.load(
      path.href,
      function (gltf) {
        const obj = gltf.scene.children[0] as Mesh;
        obj.rotation.z = -POS_START_Z;
        scene.add(obj);
        resolve(obj);
      },
      function () {
        // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function () {
        // console.log('An error happened', err);
        reject();
      }
    );
  });
};
export const getScreenObject = (video?: HTMLVideoElement | null) => {
  return new Promise<Group>((resolve, reject) => {
    const loader = new GLTFLoader();
    const path = new URL('../../../assets/models3d/screen2.glb', import.meta.url);
    loader.load(
      path.href,
      function (gltf) {
        const screen = gltf.scene.children[1] as Mesh;
        const mono = gltf.scene.children[0] as Mesh;
        const group = new Group();
        screen.rotation.z = -Math.PI * 2;
        group.add(screen, mono);

        if (video) {
          const texture = new THREE.VideoTexture(video);
          texture.minFilter = THREE.LinearFilter;
          texture.magFilter = THREE.LinearFilter;
          screen.material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.BackSide,
            toneMapped: false,
          });
        }
        resolve(group);
      },
      function () {
        // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function () {
        // console.log('An error happened', err);
        reject();
      }
    );
  });
};
export const createLightScene = (scene: Scene) => {
  // const pointLight = new THREE.PointLight(0xffffff, 20, 500);
  // const pointLightHelper = new THREE.PointLightHelper(pointLight, 5);
  // pointLight.position.set(150,50,150);
  // scene.add(pointLight)
  // scene.add(pointLightHelper);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 16);
  directionalLight.position.set(250, 300, 250);

  scene.add(directionalLight);
};
export const addRotateObject = (object: THREE.Mesh | null, callbackEndAnimate: () => void) => {
  if (object) {
    if (object.rotation.z < Math.PI / 2.2) {
      object.rotation.z += 0.008;
      object.rotation.y -= 0.00018;
    } else {
      callbackEndAnimate();
    }
  }
};

let TIME_START = 24;
export const animateWave = (object: THREE.Mesh | null, callbackEndAnimate: () => void) => {
  if (object && TIME_START > 0) {
    // const time = performance.now() * 0.008;
    object.rotation.z += Math.cos(TIME_START) * 0.0002 * TIME_START;
    object.rotation.y -= Math.cos(TIME_START) * 0.00002 * TIME_START;
    TIME_START -= 0.1;
  } else {
    callbackEndAnimate();
    TIME_START = 24;
  }
};
export const returnStartAnimate = (object: THREE.Mesh | null, callbackEndAnimate: () => void) => {
  if (object) {
    if (object.rotation.z > -POS_START_Z) {
      object.rotation.z -= 0.02;
      if (object.rotation.y < 0) {
        object.rotation.y += 0.001;
      }
    } else {
      callbackEndAnimate();
      object.rotation.z = -POS_START_Z;
      object.rotation.y = 0;
    }
  }
};

/*function recurs(mesh: THREE.Object3D | THREE.Mesh) {
  if (mesh?.children.length) {
    for (let i = 0; i < mesh.children.length; i++) {
      if (mesh.children[i].isObject3D) {
        if (mesh.children[i] instanceof THREE.Mesh) {
          const obj = mesh.children[i] as THREE.Mesh;
          const mat = obj.material as THREE.MeshStandardMaterial;
          if (obj.name === 'Object_27' && mat?.map) {
            // mat.map.dispose();
            // mat.map = null;
            // mat.color = new Color(0x000000);
          }
        }
        recurs(mesh.children[i] as THREE.Mesh);
      }
    }
  }
}*/
