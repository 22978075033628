import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { theme } from '../../../themes/theme';
import { TextTitle } from '../../TextTitle';
import { Text } from '../../Texts/Text';
import { BlockPartnerProgramItems } from './utils/constans';
import { BlocItem } from './components/BlocItem';
import { useDefineScrollForAnchor } from '../../../hooks/useDefineScrollForAnchor';

const WrapperBox = styled(Box)`
  gap: 20px;
`;

export const BlockPartnerProgram: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  useDefineScrollForAnchor({
    childRef: ref,
    orderNumber: 0,
  });

  return (
    <Box
      ref={ref}
      width="100%"
      height="100vh"
      background={theme.colors.white}
      direction="column"
      justify="flex-start"
    >
      <Box height="36%" direction="column">
        <TextTitle
          text={['%торговым%', 'представителям']}
          textAlign="center"
          textProps={{ marginBottom: '30px' }}
        />
        <Text
          fontFamily="Gilroy"
          textAlign="center"
          width="780px"
          size="20px"
          weight="500"
          lineHeight="24px"
        >
          Технология Smart3D сочетает в себе уникальный материал для внутренней облицовки стен,
          передовой софт с элементами искусственного интеллекта и высокотехнологичное производство.
        </Text>
      </Box>
      <WrapperBox align="flex-start">
        {BlockPartnerProgramItems.map(item => (
          <BlocItem key={item.title} title={item.title} icon={item.icon} text={item.text} />
        ))}
      </WrapperBox>
    </Box>
  );
};
