import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { EDIT_CLASS_OBJECT } from '../../../../../helpers/constans';
import { DoorObject } from '../../../canvas/classes/DoorObject';
import { useChangeSelectDoorQuery } from '../../../../../api/store/query/__generated__/changeSelectDoor';
const WrapperBox = styled.div`
  width: 100%;
  padding: 0 40px;
`;
const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  height: 4px;
  border-radius: 2px;
  background: #dad3fa;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.softBlueViolet};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.softBlueViolet};
    cursor: pointer;
  }
`;

interface Props {
  isOpening?: boolean;
}
type PropsInputType = {
  min: string;
  max: string;
  step: string;
};
export const Slider: FC<Props> = ({ isOpening }) => {
  const [inputValue, setInputValue] = useState('0');
  const [selectDoor, setSelectDoor] = useState<DoorObject>();
  const [inputProps, setInputProps] = useState<PropsInputType>({
    min: '-1',
    max: '1',
    step: '0.02',
  });
  const { data: dataSelectDoor } = useChangeSelectDoorQuery();

  const handlerChangeInput = (evt: ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value.toString());
    if (selectDoor) {
      if (isOpening) {
        selectDoor.opening(+evt.target.value);
      } else {
        selectDoor.drag(+evt.target.value);
      }
    }
  };
  const handlerDownInput = () => {
    if (selectDoor) {
      if (isOpening) {
        selectDoor.startOpening();
      } else {
        selectDoor.startDrag();
      }
    }
  };
  const handlerUpInput = () => {
    setInputValue('0');
    if (selectDoor) {
      selectDoor.endDrag();
    }
  };

  useEffect(() => {
    if (isOpening) {
      setInputProps({
        min: (-Math.PI).toString(),
        max: Math.PI.toString(),
        step: (Math.PI / 220).toString(),
      });
    } else {
      setInputProps({
        min: '-1',
        max: '1',
        step: '0.02',
      });
    }
  }, [isOpening]);
  useEffect(() => {
    if (dataSelectDoor) {
      const obj = EDIT_CLASS_OBJECT.getSelectedObject();
      if (obj && obj instanceof DoorObject) {
        setSelectDoor(obj);
      }
    }
  }, [dataSelectDoor]);
  useEffect(() => {
    const obj = EDIT_CLASS_OBJECT.getSelectedObject();
    if (obj && obj instanceof DoorObject) {
      setSelectDoor(obj);
    }
  }, []);
  return (
    <WrapperBox>
      <Input
        type="range"
        value={inputValue}
        onChange={handlerChangeInput}
        onMouseDown={handlerDownInput}
        onMouseUp={handlerUpInput}
        {...inputProps}
      />
    </WrapperBox>
  );
};
