/* eslint-disable no-var */
import * as THREE from 'three';
import BasicObject from './BasicObject';
import { DoorObject } from './DoorObject';
import { DOOR_HEIGHT, DOOR_WIDTH } from '../../../../helpers/constans';
import { Room } from './Room';
import { PanelsGroup } from './PanelsGroup';

export interface ParamsConstructorWall {
  width: number;
  height: number;
  position?: THREE.Vector3;
  rotate?: number;
  material: THREE.MeshBasicMaterial;
}

export class Wall extends BasicObject {
  type = 'wall';
  name = 'wall';
  width: number;
  height: number;
  doors: Array<DoorObject>;
  material: THREE.MeshBasicMaterial;
  panels: PanelsGroup | null = null;
  isSelect = false;
  private readonly fixedHeight: number;

  constructor(param: ParamsConstructorWall) {
    super();
    this.width = param.width;
    this.height = param.height;
    this.material = param.material;
    if (param?.position) {
      this.position.copy(param.position);
    }
    if (param.rotate) {
      this.rotation.y = param.rotate;
    }
    this.doors = [];
    this.fixedHeight = param.height;

    this.buildGeometry();
  }

  buildGeometry() {
    super.buildGeometry();
    this.changeGeometry();
  }
  selectUnselectSelfObj() {
    if (!this.isSelect) {
      this.material.color = new THREE.Color('#b1a3ea');
    } else {
      this.material.color = new THREE.Color('#fff');
    }

    this.isSelect = !this.isSelect;
  }
  changeHeightWall(newHeight: number) {
    var offset = newHeight - this.height;
    var positions = this.geometry.attributes['position'].array;
    var start = new THREE.Vector3(positions[0], positions[1], positions[2]);

    var ptCout = positions.length / 3;
    var max = positions[2 * 3 + 1] - 0.01;
    for (let i = 0; i < ptCout; i++) {
      var x = positions[i * 3];
      var y = positions[i * 3 + 1];
      var z = positions[i * 3 + 2];

      if (y > max) {
        y += offset;
        this.geometry.attributes.position.setXYZ(i, x, y, z);
      }
      this.updateUV(start, x, y, newHeight, this.width, i);
    }
    this.geometry.attributes.position.needsUpdate = true;
    this.geometry.attributes.uv.needsUpdate = true;
    this.height = newHeight;
  }
  changeWidthWall(newWidth: number) {
    var offset = newWidth - this.width;
    var positions = this.geometry.attributes['position'].array;
    var ptCout = positions.length / 3 - 3;
    for (var i = 0; i < 3; i++) {
      var x = positions[i * 3];
      var y = positions[i * 3 + 1];
      var z = positions[i * 3 + 2];
      x -= offset / 2;
      var _x = positions[(ptCout + i) * 3];
      var _y = positions[(ptCout + i) * 3 + 1];
      var _z = positions[(ptCout + i) * 3 + 2];
      _x += offset / 2;
      this.geometry.attributes.position.setXYZ(i, x, y, z);
      this.geometry.attributes.position.setXYZ(ptCout + i, _x, _y, _z);
    }
    this.geometry.attributes.position.needsUpdate = true;

    positions = this.geometry.attributes['position'].array;
    var start = new THREE.Vector3(positions[0], positions[1], positions[2]);
    ptCout = positions.length / 3;

    for (let j = 0; j < ptCout; j++) {
      var __x = positions[j * 3];
      var __y = positions[j * 3 + 1];
      this.updateUV(start, __x, __y, this.height, newWidth, j);
    }
    this.geometry.attributes.uv.needsUpdate = true;
    this.width = newWidth;
  }
  addDoorToWall(door: DoorObject) {
    // door.position.y -= (this.height - DOOR_HEIGHT) / 2;
    var _segment = this.getSegment(this.doors.length + 1);
    if (_segment <= 0.16) {
      return;
    }
    this.add(door);
    this.doors.push(door);
    this.doors.forEach(d => {
      d.position.y = -this.height / 2 + DOOR_HEIGHT / 2;
    });
    this.changeGeometry();
    if (this?.parent && this.parent instanceof Room) {
      this.parent.addingInters(door);
    }
  }
  removeDoorToWall(uuid: string) {
    const index = this.doors.findIndex(item => item.uuid === uuid);
    if (index !== -1) {
      this.doors.splice(index, 1);
    }
    this.changeGeometry();
  }
  updateUV(
    start: THREE.Vector3,
    x: number,
    y: number,
    height: number,
    width: number,
    cursor: number
  ) {
    var uvAttribute = this.geometry.attributes.uv;
    var vCurrX = new THREE.Vector3(x, start.y, start.z);
    var vCurrY = new THREE.Vector3(start.x, y, start.z);
    var coifU = start.distanceTo(vCurrX) / width;
    var coifV = start.distanceTo(vCurrY) / height;
    uvAttribute.setXY(cursor, coifU, coifV);
  }
  getParamsWall(): THREE.Vector3 {
    var positions = this.geometry.attributes['position'].array;
    return new THREE.Vector3(positions[0], positions[1], positions[2]);
  }
  removePanels() {
    this.doors.forEach(door => {
      door.removeHoleDoorPanels();
    });
    this.panels?.removePanelsGroup();
    this.panels = null;
  }

  private changeGeometry() {
    if (this.geometry) {
      this.geometry.dispose();
    }
    var geometry = new THREE.BufferGeometry();
    var vertices = [];
    var normals = [];
    var indices = [];
    var uvs = [];
    var offsets = [-this.width / 2];

    var _segment = this.getSegment(this.doors.length);
    var startOffset = _segment;
    for (let i = 0; i < this.doors.length; i++) {
      var _offsetX = -this.width / 2 + (startOffset + DOOR_WIDTH / 2);
      this.doors[i].position.x = _offsetX;
      this.doors[i].indexToWall = i;
      var hole = this.doors[i].holeDoorsPanels;
      if (hole) {
        hole.position.x = _offsetX;
      }

      offsets.push(_offsetX - DOOR_WIDTH / 2);
      offsets.push(_offsetX + DOOR_WIDTH / 2);

      startOffset += DOOR_WIDTH + _segment;
    }
    offsets.push(this.width / 2);

    var vStart = new THREE.Vector3(-this.width / 2, 0, 0);
    for (let x = 0; x < offsets.length; x++) {
      vertices.push(offsets[x], -this.height / 2, 0);
      normals.push(0, 0, 1);

      var vCurr = new THREE.Vector3(offsets[x], 0, 0);
      var coif = vStart.distanceTo(vCurr) / this.width;
      uvs.push(coif, 0);

      vertices.push(offsets[x], -this.height / 2 + DOOR_HEIGHT, 0);
      normals.push(0, 0, 1);
      uvs.push(coif, DOOR_HEIGHT / this.height);

      vertices.push(offsets[x], this.height / 2, 0);
      normals.push(0, 0, 1);
      uvs.push(coif, 1);
    }
    for (let i = 0; i < offsets.length - 1; i++) {
      var off = i * 3;
      for (let j = 0; j < 2; j++) {
        var a = off + j;
        var b = off + j + 3;
        var c = off + j + 4;
        var d = off + j + 1;

        if (i % 2 === 0) {
          indices.push(a, b, c); // face one
          indices.push(a, c, d); // face two
        } else if (j === 1) {
          indices.push(a, b, c); // face one
          indices.push(a, c, d); // face two
        }
      }
    }

    geometry.setIndex(indices);
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));

    this.geometry = geometry;
    if (this.height !== this.fixedHeight) {
      var h = this.height - this.fixedHeight;
      this.position.y = h / 2;
    }
  }
  private getSegment(countDoor: number): number {
    return (this.width - countDoor * DOOR_WIDTH) / (countDoor + 1);
  }
}
