import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { theme } from '../../themes/theme';

interface PropsStyled {
  size: number;
  backgroundColor?: string;
  isAnimate?: boolean;
  sw?: boolean;
}

const ContainerStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['backgroundColor', 'modeAnim'].includes(prop),
})<PropsStyled>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  width: ${({ size }) => `${size}px` || '90px'};
  height: ${({ size }) => `${size}px` || '90px'};
  border-radius: ${({ size }) => (size ? `${size / 2}px` : '45px')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
const AnimateOpacityComp = styled('div').withConfig({
  shouldForwardProp: prop => !['backgroundColor', 'isAnimate', 'modeAnim'].includes(prop),
})<PropsStyled>`
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 2}px`};
  pointer-events: none;
  z-index: -1;
`;
const AnimateTransformComp = styled('div').withConfig({
  shouldForwardProp: prop => !['backgroundColor', 'isAnimate', 'modeAnim'].includes(prop),
})<PropsStyled>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ size }) => `${size / 2}px`};
  background-color: ${({ theme }) => theme.colors.softBlueViolet};
`;
const HoverDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
`;

interface Props {
  size: number;
  isAnimate?: boolean;
  onButtonClick?: () => void;
  delay?: number;
  interval?: number;
}
export const ButtonCircle: FC<PropsWithChildren<Props>> = ({
  size,
  isAnimate,
  onButtonClick,
  delay,
  interval = 4000,
  children,
}) => {
  const animOpClick = useRef<Animation>();
  const animTrHoverOver = useRef<Animation>();
  const animTrHoverOut = useRef<Animation>();
  const animTrClick = useRef<Animation>();
  const animTrInterval = useRef<Animation>();
  const animOpInterval = useRef<Animation>();

  const contRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timer>();
  const isAnimInterval = useRef(true);

  const startIntervalAnimate = () => {
    if (animOpClick.current && animRef.current) {
      animOpClick.current?.cancel();
    }
    if (!isAnimate) return;
    timer.current = setInterval(() => {
      if (!isAnimInterval.current) return;
      if (contRef.current && animRef.current) {
        if (animTrInterval.current) {
          animTrInterval.current?.play();
          animOpInterval.current?.play();
        } //
        else {
          animTrInterval.current = contRef.current.animate(
            [
              { transform: 'scale(1) rotateZ(0deg)' },
              { transform: ' scale(0.8) rotateZ(-14deg)', offset: 0.1 },
              { transform: 'scale(1) rotateZ(0deg)' },
            ],
            {
              duration: 1200,
              easing: 'ease',
              delay,
            }
          );
          animOpInterval.current = animRef.current.animate(
            [
              { transform: 'scale(0.5)', opacity: '1' },
              { transform: 'scale(1.7)', opacity: '0' },
            ],
            {
              duration: 1800,
              easing: 'ease',
              delay,
            }
          );
        }
      }
    }, interval);
  };
  const endIntervalAnimate = () => {
    isAnimInterval.current = false;
    animOpInterval.current?.cancel();
  };
  const handleOver = () => {
    endIntervalAnimate();
    animHoverOver();
  };
  const handleOut = () => {
    // startIntervalAnimate();
    isAnimInterval.current = true;
    animHoverOut();
  };
  const handleClick = () => {
    animClick();
  };

  function animHoverOver() {
    if (animRef.current) {
      if (animTrHoverOver.current) {
        animTrHoverOver.current?.play();
      } else {
        animTrHoverOver.current = animRef.current.animate(
          [
            { transform: 'scale(1)', opacity: '0.4' },
            { transform: 'scale(1.3)', opacity: '0.4' },
          ],
          { duration: 500, easing: 'ease' }
        );
      }

      animRef.current.style.transform = 'scale(1.3)';
      animRef.current.style.opacity = '0.4';
    }
  }
  function animHoverOut() {
    if (animRef.current) {
      if (animTrHoverOut.current) {
        animTrHoverOut.current?.play();
      } else {
        animTrHoverOut.current = animRef.current.animate(
          [
            { transform: 'scale(1.3)', opacity: '0.4' },
            { transform: 'scale(1)', opacity: '0.4' },
          ],
          { duration: 500, easing: 'ease' }
        );
      }

      animRef.current.style.transform = 'scale(1)';
      animRef.current.style.opacity = '0.4';
    }
  }
  function animClick() {
    if (contRef.current && animRef.current) {
      if (animOpClick.current) {
        animOpClick.current?.play();
        animTrClick.current?.play();
      } else {
        const frames = [
          { transform: 'scale(1)' },
          { transform: 'scale(0.85)', offset: 0.4 },
          { transform: 'scale(1)' },
        ];
        const framesOp = [
          { transform: 'scale(1.3)', opacity: '0.4' },
          { transform: 'scale(1.7)', opacity: '0', offset: 0.39 },
          { transform: 'scale(1)', opacity: '0', offset: 0.4 },
          { transform: 'scale(1)', opacity: '0', offset: 0.8 },
          { transform: 'scale(1.3)', opacity: '0.4' },
        ];
        animTrClick.current = contRef.current.animate(frames, { duration: 1000, easing: 'ease' });
        animOpClick.current = animRef.current.animate(framesOp, {
          duration: 1200,
          easing: 'ease',
          delay: 400,
        });
        animTrClick.current.onfinish = () => {
          if (onButtonClick) {
            onButtonClick();
          }
        };
      }
    }
  }

  useEffect(() => {
    startIntervalAnimate();
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return (
    <ContainerStyle size={size}>
      <AnimateOpacityComp
        ref={animRef}
        isAnimate={isAnimate}
        size={size}
        backgroundColor={theme.colors.softBlueVioletTransparent}
      />
      <AnimateTransformComp
        ref={contRef}
        size={size}
        backgroundColor={theme.colors.softBlueVioletTransparent}
      >
        {children}
      </AnimateTransformComp>
      <HoverDiv onMouseOver={handleOver} onMouseOut={handleOut} onClick={handleClick} />
    </ContainerStyle>
  );
};
