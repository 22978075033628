import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockTechnology } from '../../components/Blocks/BlockTechnology';
import { Box } from '../../components/Box';

export const Technology: FC = () => {
  return (
    <WrapperPage>
      <Box width="100%" height="100%">
        <BlockTechnology />
      </Box>
    </WrapperPage>
  );
};
