import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Icon } from '../../../components/Icons/Icon';
import { theme } from '../../../themes/theme';
import _video from '../../../assets/video/video_back.mp4';
import { IMAGE_URLS } from '../../../components/PreloadImages/helpers/constans';

const WrapperBlockStartStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #282c34;
  position: relative;
`;
const WrapperIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 45px;
  padding: 10px;
  transform: rotateZ(-90deg);
  z-index: 2;
`;
const Video = styled.video`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
`;

export const WrapperBlockStart: FC = () => {
  const video = useRef<HTMLVideoElement>(null);
  const objectIntersection = useRef<HTMLDivElement>(null);
  const handlerClockArrow = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };
  const callback = (entries: IntersectionObserverEntry[]) => {
    // console.log('ping', entries[0].isIntersecting, entries[0].intersectionRatio);
    if (video.current) {
      if (entries[0].isIntersecting) {
        video.current.play().then();
      } else {
        video.current.pause();
      }
    }
  };

  useEffect(() => {
    if (objectIntersection.current) {
      const observer = new IntersectionObserver(callback, {
        // rootMargin: '5px',
        // threshold: 0.5,
      });
      observer.observe(objectIntersection.current);
    }
  }, []);

  return (
    <WrapperBlockStartStyle ref={objectIntersection}>
      <Video
        poster={IMAGE_URLS.poster_back}
        ref={video}
        width="100%"
        height="100%"
        controls={false}
        muted
        autoPlay
        loop
      >
        <source src={_video} type="video/mp4" />
      </Video>
      <WrapperIcon onClick={handlerClockArrow}>
        <Icon name="arrowLeft" height="100%" width="100%" fill={theme.colors.white} />
      </WrapperIcon>
    </WrapperBlockStartStyle>
  );
};
