import React, { FC } from 'react';
import styled from 'styled-components';
import { TextTitle } from '../../../components/TextTitle';
import { Text } from '../../../components/Texts/Text';
import { theme } from '../../../themes/theme';

const WrapperModal = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
`;
const ModalStyle = styled.div`
  width: 1100px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 7px -2px 65px 8px #826eee99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0;
`;
const ButtonText = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

interface Props {
  handlerPressText(): void;
}

export const SuccessConfirmForm: FC<Props> = ({ handlerPressText }) => {
  return (
    <WrapperModal>
      <ModalStyle>
        <TextTitle
          text={['%мы благодарны%', 'за проявленный интерес']}
          textAlign="center"
          textProps={{ marginBottom: '20px' }}
        />
        <Text fontFamily="Gilroy" weight="400" size="25px" marginBottom="70px">
          В скором времени мы с Вами свяжемся!
        </Text>
        <ButtonText onClick={handlerPressText}>
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill={theme.colors.softBlueViolet}
            style={{ marginRight: '30px' }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM2 7H1V9H2V7Z" />
          </svg>
          <Text fontFamily="Gilroy" weight="400" size="25px" color="softBlueViolet">
            Вернуться к заполнению формы
          </Text>
        </ButtonText>
      </ModalStyle>
    </WrapperModal>
  );
};
