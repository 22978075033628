import React from 'react';
import { theme } from '../../../../themes/theme';

export const ArrowSVG = () => {
  return (
    <svg width="14" height="24" viewBox="0 0 14 24" style={{ pointerEvents: 'none' }}>
      <path
        d="M13.0607 10.9393C13.6464 11.5251 13.6464 12.4749 13.0607 13.0607L3.51472 22.6066C2.92893 23.1924 1.97919 23.1924 1.3934 22.6066C0.807611 22.0208 0.807611 21.0711 1.3934 20.4853L9.87868 12L1.3934 3.51472C0.807611 2.92893 0.807611 1.97919 1.3934 1.3934C1.97919 0.807611 2.92893 0.807611 3.51472 1.3934L13.0607 10.9393ZM11 10.5H12V13.5H11V10.5Z"
        fill={theme.colors.white}
      />
    </svg>
  );
};
