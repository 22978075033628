import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../../../../../components/Box';
import { theme } from '../../../../../themes/theme';
import { Text } from '../../../../../components/Texts/Text';

const WrapperCircleIn = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.softBlueViolet};
`;
const WrapperBox = styled(Box)`
  cursor: pointer;
`;

interface Props {
  isActive: boolean;
  title: string;
  changeState(): void;
}

export const RadioButton: FC<Props> = ({ isActive, title, changeState }) => {
  return (
    <WrapperBox flex={1} justify="flex-start" onClick={changeState}>
      <Box
        width="40px"
        height="40px"
        borderRadius="20px"
        background={'#DAD3FA'}
        margin="0 20px 0 0"
      >
        {isActive && <WrapperCircleIn />}
      </Box>
      <Text fontFamily="Gilroy" size="18px" weight={400}>
        {title}
      </Text>
    </WrapperBox>
  );
};
