import React, { CSSProperties, useEffect, useRef } from 'react';
import { Box } from '../../Box';
import { ItemBlock } from './components/ItemBlock';
import styled from 'styled-components';
import { TextTitle } from '../../TextTitle';

const ANIMATE_CONTENT = [
  { duration: '1600ms', timeFunk: 'cubic-bezier(0.665, 1.3, 0.450, 0.965)' },
  { duration: '2000ms', timeFunk: 'cubic-bezier(0.665, 1.42, 0.450, 0.965)' },
  { duration: '2400ms', timeFunk: 'cubic-bezier(0.665, 1.54, 0.450, 0.965)' },
];
const ADVANTAGES = [
  {
    titleNumber: '1',
    text: [
      'Ещё никогда ремонт не был таким',
      'простым. Всё что нужно чтобы его',
      'заказать - %зайти на цифровую%',
      '%платформу Smart 3D Decor%',
    ],
  },
  {
    titleNumber: '2',
    text: [
      'Выбирая 3D Decor Вы',
      'существенно %экономите%',
      '%средства% и при этом получаете',
      '%превосходную отделку%',
    ],
  },
  {
    titleNumber: '3',
    text: [
      '%Безпрецедентная скорость.%',
      'Облицовка стен сводится к дням,',
      'а не кнеделям или даже месяцам',
    ],
  },
  {
    titleNumber: '4',
    text: [
      'Магазин 3D декора представляет Вам',
      'поистине %огромный ассортимент%',
      'авторских рисунков на %любой вкус%',
    ],
  },
  {
    titleNumber: '5',
    text: [
      'Используйте существующие',
      'варианты дизайна или загрузите',
      'свои фото, рисунки и %создайте%',
      '%собственный, неповторимый стиль!%',
    ],
  },
  {
    titleNumber: '6',
    text: [
      'Платформа Smart 3D Decor',
      'позволяет Вам %работать напрямую%',
      'с заводом производителем %минуя%',
      '%посредников%',
    ],
  },
];
interface PropsBoxAdvantages {
  timeFunc: CSSProperties['animationTimingFunction'];
  duration: CSSProperties['animationDuration'];
}

const BoxAdvantages = styled(Box).withConfig({
  shouldForwardProp: prop => !['timeFunc', 'delay'].includes(prop),
})<PropsBoxAdvantages>`
  opacity: 0;
  //flex-wrap: wrap;
  transform: translateY(600px);
  transition: ${({ timeFunc, duration }) =>
    `transform ${duration} ${timeFunc}, opacity 1600ms ease`};
  //@media (max-width: 1190px) {
  //  justify-content: center;
  //}
`;
const BoxMedia = styled(Box)`
  gap: 120px;
  @media (max-height: 1040px) or (max-width: 1320px) {
    width: 1000px;
    gap: 60px;
    justify-content: flex-start;
    margin-bottom: 30px;
    padding: 60px 0 0 0;
  }
  @media (max-height: 780px) {
    width: 860px;
  }
`;
const BoxWrapper = styled(Box)`
  @media (max-height: 890px) or (max-width: 1320px) {
    height: 66%;
  }
`;

export const BlockAdvantages = () => {
  const boxAdvantages0 = useRef<HTMLDivElement>(null);
  const boxAdvantages1 = useRef<HTMLDivElement>(null);
  const boxAdvantages2 = useRef<HTMLDivElement>(null);
  const boxes = [boxAdvantages0, boxAdvantages1, boxAdvantages2];

  const getContentBoxAdvantages = () => {
    return boxes.map((item, index) => (
      <BoxAdvantages
        key={ANIMATE_CONTENT[index].duration}
        ref={item}
        width="100%"
        justify="space-between"
        duration={ANIMATE_CONTENT[index].duration}
        timeFunc={ANIMATE_CONTENT[index].timeFunk}
      >
        <ItemBlock
          titleNumber={ADVANTAGES[2 * index].titleNumber}
          text={ADVANTAGES[2 * index].text}
        />
        <ItemBlock
          titleNumber={ADVANTAGES[1 + 2 * index].titleNumber}
          text={ADVANTAGES[1 + 2 * index].text}
        />
      </BoxAdvantages>
    ));
  };
  function handleOverAction() {
    boxes.map(item => {
      if (item.current) {
        item.current.style.transform = `translateY(0px)`;
        item.current.style.opacity = '1';
      }
    });
  }
  /*function handleOutAction() {
    boxes.map(item => {
      if (item.current) {
        item.current.style.transform = `translateY(600px)`;
        item.current.style.opacity = '0';
      }
    });
  }*/

  useEffect(() => {
    handleOverAction();
  }, []);

  return (
    <Box width="100%" height="100%">
      <BoxMedia
        position="relative"
        width="1150px"
        height="100%"
        direction="column"
        // align="center"
        // justify="space-evenly"
      >
        <TextTitle
          text={['%преимущества% ремонта', 'по технологии %smart 3d%']}
          textAlign="center"
          textProps={{ marginBottom: '0' }}
        />
        <BoxWrapper width="100%" direction="column" height="620px" justify="space-between">
          {getContentBoxAdvantages()}
        </BoxWrapper>
      </BoxMedia>
    </Box>
  );
};
