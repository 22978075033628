import React, { FC } from 'react';
import { WrapperPage } from '../../components/WrapperPage';
import { BlockVideoFuture } from '../../components/Blocks/BlockVideoFuture/BlockVideoFuture';
import { IMAGE_URLS } from '../../components/PreloadImages/helpers/constans';

export const VideoExample: FC = () => {
  return (
    <WrapperPage>
      <BlockVideoFuture
        poster={IMAGE_URLS.poster_2}
        title={['%3D ремонт% технология будущего']}
        srcVideo={require('../../assets/video/video.mp4')}
      />
    </WrapperPage>
  );
};
