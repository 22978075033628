import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/theme';
import { Box } from '../../Box';
import { ItemText } from './components/ItemText';
import { HeaderFooter } from './components/HeaderFooter';
import { LogoIcon } from '../../Header/components/LogoIcon';
import { Icon, IconNamesType } from '../../Icons/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 60px;
`;
const WrapperLogo = styled.div`
  width: 240px;
  height: 54px;
`;
const WrapperIcons = styled(Box)`
  gap: 20px;
`;

export const BlockFooterStart = () => {
  return (
    <Box
      position="relative"
      width="100%"
      height="310px"
      background={theme.colors.lightGray}
      padding="0 0 20px 0"
    >
      <Box width="1180px" height="100%" padding="40px 0 0 0" align="flex-start">
        <Box height="100%" direction="column" justify="flex-start" align="flex-start">
          <WrapperLogo>
            <LogoIcon color={theme.colors.black} />
          </WrapperLogo>
          <WrapperIcons justify="flex-start" margin="20px 0 0 0">
            {(['instagram', 'telegram', 'viber'] as IconNamesType[]).map(item => (
              <Box key={item} width="40px" height="40px" borderRadius="20px">
                <Icon name={item} width="100%" height="100%" />
              </Box>
            ))}
          </WrapperIcons>
        </Box>
        <Container>
          <ItemText title="Почта:" text="office@smart3dcorporation.com" />
          <ItemText title="Телефон: " text="+7702 281 42 01" />
          <ItemText
            title="Юридический адрес: "
            text={'“Smart3D Corporation” LLC ОАЭ -A-0059-421 FLAMINGO VILLAS AJMAN'}
          />
        </Container>
        <HeaderFooter />
      </Box>
    </Box>
  );
};
