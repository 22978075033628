import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { ButtonPlay } from './ButtonPlay';
import { TextTitle } from '../../TextTitle';

interface Props {
  title: string[];
  srcVideo: string;
  poster: string;
}
const Container = styled(Box)`
  max-width: 1800px;
  max-height: 600px;
  position: relative;
  @media (max-height: 840px) {
    //width: 846px;
    height: 480px;
  }
`;
const Wrapper = styled(Box)`
  @media (max-height: 840px) {
    padding-top: 100px;
    justify-content: flex-start;
  }
  @media (max-height: 760px) {
    //width: 846px;
    padding-top: 40px;
  }
`;

export const BlockVideoFuture: FC<Props> = ({ srcVideo, title, poster }) => {
  const [isPlay, setIsPlay] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleStopVideo = () => {
    if (videoRef.current) {
      videoRef.current.src = srcVideo;
    }
    setIsPlay(false);
  };
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play().then(() => {
        setIsPlay(true);
      });
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', handleStopVideo);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleStopVideo);
      }
    };
  }, []);

  return (
    <Wrapper width="100%" height="100%" direction="column">
      <TextTitle text={title} textAlign="center" textProps={{ marginBottom: '10px' }} />
      <Container width={`${window.innerWidth}px`} height={`${window.innerWidth / 3}px`}>
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          poster={poster}
          controls={isPlay}
          style={{ position: 'absolute' }}
          controlsList="nodownload"
        >
          <source src={srcVideo} type="video/mp4" />
        </video>
        {!isPlay && <ButtonPlay handleClick={playVideo} />}
      </Container>
    </Wrapper>
  );
};
