import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CreateScene } from '../../../../helpers/classes';
import {
  addRotateObject,
  animateWave,
  createLightScene,
  getClipObject,
  getScreenObject,
  returnStartAnimate,
} from '../js';
import { Box } from '../../../Box';
import { LoadIndicator } from '../../../LoadIndicator';

const PhoneShowStyle = styled.div`
  width: 500px;
  height: 800px;
  background-color: #ffffff;
  @media (max-height: 890px) or (max-width: 1320px) {
    width: 400px;
    height: 600px;
  }
`;

export const PhoneShow = () => {
  const [isLoad, setIsLoad] = useState(false);
  const [SCENE_PHONE] = useState(new CreateScene());
  const container = useRef<HTMLDivElement>(null);
  const video = useRef<HTMLVideoElement>(null);

  const stopAnimation = () => {
    SCENE_PHONE.functionAnimate = () =>
      returnStartAnimate(SCENE_PHONE.objectAnimate, () => {
        SCENE_PHONE.functionAnimate = undefined;
        SCENE_PHONE.stopAnimation();
        if (video.current) {
          video.current.pause();
        }
      });
  };
  const runAnimation = () => {
    SCENE_PHONE.functionAnimate = () => addRotateObject(SCENE_PHONE.objectAnimate, endStartAnimate);
    SCENE_PHONE.startAnimation();
    if (video.current) {
      video.current.play().then();
    }
    setIsLoad(false);
  };
  const endWaveAnimation = () => {
    SCENE_PHONE.functionAnimate = undefined;
  };
  const endStartAnimate = () => {
    SCENE_PHONE.functionAnimate = () => animateWave(SCENE_PHONE.objectAnimate, endWaveAnimation);
  };
  const createAndStartScene = () => {
    if (container.current && !SCENE_PHONE.isInit) {
      SCENE_PHONE.initScene({
        container: container.current,
        cameraPosition: { x: 150, y: 100, z: 150 },
        cameraLookAt: { x: -10, y: 74, z: 0 },
        clearSceneColor: '#ffffff',
      });

      getClipObject(SCENE_PHONE.scene).then(mesh => {
        createLightScene(SCENE_PHONE.scene);
        getScreenObject(video.current).then(group => {
          group.rotation.x = Math.PI / 2;
          group.scale.set(1.015, 1.007, 1);
          group.position.y += 0.5;
          mesh.add(group);
          SCENE_PHONE.objectAnimate = mesh;
          runAnimation();
        });
      });
    }
  };

  useEffect(() => {
    setIsLoad(true);
    createAndStartScene();
    return () => {
      stopAnimation();
    };
  }, []);

  return (
    <Box width="500px" height="100%">
      {isLoad && (
        <Box position="absolute">
          <LoadIndicator size={100} />
        </Box>
      )}
      <PhoneShowStyle ref={container} />
      <video
        ref={video}
        id="video"
        playsInline
        loop
        width="512"
        height="512"
        autoPlay
        muted
        style={{ position: 'absolute', display: 'none' }}
      >
        <source src={require('../../../../assets/video/video_app.mp4')} type="video/mp4" />
      </video>
    </Box>
  );
};
