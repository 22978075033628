import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StartTransform = `
            rotateY(0deg) 
            rotateX(0deg) 
            rotateZ(0deg) 
            translateX(0px) 
            translateZ(0px) 
            translateY(0px)`;
interface PropsImage {
  opacity: number;
}
const Image = styled('img').withConfig({
  shouldForwardProp: prop => !['opacity'].includes(prop),
})<PropsImage>`
  position: absolute;
  width: 100%;
  height: 100%;
  //transform: translateX(-200px);
  opacity: ${({ opacity }) => opacity};
`;
interface PanelPropsStyle {
  transform: CSSProperties['transform'];
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  cubeSize?: number;
}
type ContainerProps = {
  cubeSize: number;
  transform: CSSProperties['transform'];
};
const CubeStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['transform', 'cubeSize'].includes(prop),
})<ContainerProps>`
  position: absolute;
  width: ${({ cubeSize }) => cubeSize}px;
  height: ${({ cubeSize }) => cubeSize * 2}px;
  left: calc((100% / 2) - ${({ cubeSize }) => cubeSize / 2}px);
  top: calc((100% / 2) - ${({ cubeSize }) => cubeSize}px);
  transform-style: preserve-3d;
  transform: ${({ transform }) => transform};
  transition: transform 1.5s ease-in-out;
`;
interface PanelPropsStyle {
  transform: CSSProperties['transform'];
  width: CSSProperties['width'];
  height: CSSProperties['height'];
}
const PanelStyle = styled('div').withConfig({
  shouldForwardProp: prop => !['transform', 'width', 'height'].includes(prop),
})<PanelPropsStyle>`
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transform-style: preserve-3d;
  background-color: #bababb;
  border: 1px solid white;
  transform: ${({ transform }) => transform};
`;

interface Props extends ContainerProps {
  images: Array<string>;
  cubeSize: number;
}
export const DemoBlock: FC<Props> = ({ transform, images, cubeSize }) => {
  const [currentImg, setCurrentImg] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timer>();
  const imageRef = useRef<HTMLImageElement>(null);

  const changeImagePanel = () => {
    timer.current = setInterval(() => {
      if (imageRef.current) {
        imageRef.current.style.transition = 'opacity 2s ease';
        imageRef.current.style.opacity = '0';
        setTimeout(() => {
          setCurrentImg(prev => {
            let offset = prev + 1;
            if (offset >= images.length) {
              offset = 0;
            }
            return offset;
          });
        }, 2100);
      }
    }, 6000);
  };

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.transition = 'none';
      imageRef.current.style.opacity = '1';
    }
  }, [currentImg]);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.style.transform = transform || '';
      if (!timer.current) {
        changeImagePanel();
      }
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, []);

  return (
    <CubeStyle ref={divRef} transform={StartTransform} cubeSize={cubeSize}>
      <PanelStyle width="inherit" height="inherit" transform="translateZ(15px)">
        <Image
          ref={imageRef}
          src={currentImg + 1 < images.length ? images[currentImg + 1] : images[0]}
          opacity={1}
        />
        <Image ref={imageRef} src={images[currentImg]} opacity={1} />
      </PanelStyle>
      <PanelStyle width="inherit" height="inherit" transform="rotateY(180deg) translateZ(15px)" />
      <PanelStyle
        width="30px"
        height="inherit"
        transform={`rotateY(90deg) translateZ(${cubeSize - 15}px)`}
      />
      <PanelStyle width="30px" height="inherit" transform="rotateY(-90deg) translateZ(15px)" />
    </CubeStyle>
  );
};
