import React, { useState } from 'react';
import { Box } from '../../Box';
import { TabComponent } from './components/TabComponent';
import { ContentTabAnimate } from './components/ContentTabAnimate';
import styled from 'styled-components';
import { TextTitle } from '../../TextTitle';

export enum MODE {
  Products,
  Low,
  Pay,
  Delivery,
}
const BoxWrapper = styled(Box)`
  @media (max-height: 880px) {
    justify-content: flex-start;
    padding-top: 80px;
    padding-bottom: 30px;
  }
`;

export const BlockQuestions = () => {
  const [mode, setMode] = useState(MODE.Products);
  return (
    <BoxWrapper width="100%" height="100%" direction="column">
      <TextTitle
        text={['ответы на вопросы']}
        textAlign="center"
        textProps={{ marginBottom: window.innerHeight > 890 ? '90px' : '50px' }}
      />
      <TabComponent currentMode={mode} changeMode={setMode} />
      <ContentTabAnimate mode={mode} />
    </BoxWrapper>
  );
};
