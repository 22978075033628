import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { theme } from '../../../themes/theme';
import chairs from '../../../assets/img/partners/B2B.png';
import { StartTextComponent } from './components/StartTextComponent';
import { InformComponent } from './components/InformComponent';
import { TEXTS_INFO_BLOCKS, TEXTS_TITLE_BLOCKS } from './helpers/constans';
import { useDefineScrollForAnchor } from '../../../hooks/useDefineScrollForAnchor';

const WrapperBox = styled(Box)`
  position: relative;
  margin-bottom: 20px;
`;
const RightImage = styled.div`
  position: absolute;
  right: 50%;
  bottom: 0;
  width: 789px;
  height: 607px;
  background-image: url('${chairs}');
`;
const LeftImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 789px;
  height: 607px;
  background-image: url('${chairs}');
  transform: scale(-1, 1);
`;
const WrapperLeft = styled.div`
  position: absolute;
  width: 789px;
  left: calc(50% + 500px);
  bottom: 0;
  height: 460px;
  overflow-y: hidden;
  opacity: 0.1;
`;

export const BlockB2B: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  useDefineScrollForAnchor({
    childRef: ref,
    orderNumber: 2,
  });
  const [isInformBlock, setIsInformBlock] = useState(false);
  return (
    <WrapperBox ref={ref} width="100%" height="630px" background={theme.colors.lightGray}>
      <RightImage />
      <StartTextComponent
        isShow={isInformBlock}
        changeState={() => setIsInformBlock(true)}
        title="b2b"
        icon="partnerShop"
        text={TEXTS_TITLE_BLOCKS.b2b}
        isRight={true}
      />
      <InformComponent
        isShow={isInformBlock}
        changeState={() => setIsInformBlock(false)}
        texts={TEXTS_INFO_BLOCKS.b2b}
        height={420}
      />
      <WrapperLeft>
        <LeftImage />
      </WrapperLeft>
    </WrapperBox>
  );
};
