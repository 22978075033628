import React from 'react';
import { theme } from '../../../../themes/theme';

export const Glacier = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 60 60">
      <g>
        <path
          fill={theme.colors.white}
          fillRule="nonzero"
          d="M49 0H11C4.928.007.007 4.928 0 11v38c.007 6.072 4.928 10.993 11 11h38c6.072-.007 10.993-4.928 11-11V11C59.993 4.928 55.072.007 49 0zm7.617 6.228A129.446 129.446 0 0 0 49.5 9.647 6.965 6.965 0 0 0 45 8H31.953a87.807 87.807 0 0 1 14.153-6H49a9 9 0 0 1 7.617 4.228zM58 11v7.821a127.62 127.62 0 0 0-6 3.114V15a6.948 6.948 0 0 0-1.134-3.807 121.545 121.545 0 0 1 6.62-3.163c.338.954.512 1.958.514 2.97zm0 36.069a708.158 708.158 0 0 0-6.1 4l-.871.576c-3.027 2.012-6.2 4.158-9 6.353H25.907a53.441 53.441 0 0 1 5.687-6H45a7.008 7.008 0 0 0 7-7v-7.712a363.555 363.555 0 0 1 6-3.852zm-48-8.248A170.566 170.566 0 0 1 29.087 24.41a233.32 233.32 0 0 1 20-12.272c.592.837.911 1.837.913 2.862v8.065c-5.366 3.069-10.49 6.4-15.337 9.654-7.5 5.032-15.185 10.43-21.577 16.9A5.005 5.005 0 0 1 10 45zM2 15.349A102.051 102.051 0 0 1 18.453 2h21.761a90.807 90.807 0 0 0-12.377 6H15a7.008 7.008 0 0 0-7 7v8.533a90.059 90.059 0 0 0-6 6.858zM24.559 10A92.043 92.043 0 0 0 10 21.52V15a5.006 5.006 0 0 1 5-5zM2 33.678a87.469 87.469 0 0 1 6-7.241v11.5a90.74 90.74 0 0 0-6 6.026zm8-9.333A89.957 89.957 0 0 1 28.375 10H45c.89.005 1.762.25 2.524.709-6.892 3.721-13.41 7.917-19.551 12.041A180.7 180.7 0 0 0 10 36.125zM35.777 34.38c4.514-3.03 9.265-6.131 14.223-9.013v10.842c-1.522 1-3.048 2.006-4.542 3A124.964 124.964 0 0 0 30.817 50H15.528c6.064-5.9 13.235-10.917 20.249-15.62zm10.809 6.477c1.121-.743 2.275-1.5 3.414-2.252V45a5.006 5.006 0 0 1-5 5H33.878a138.362 138.362 0 0 1 12.708-9.143zM58 31.062a339.74 339.74 0 0 0-6 3.83V24.233c1.97-1.107 3.97-2.166 6-3.178zM11 2h3.873A103.9 103.9 0 0 0 2 12.546V11a9.011 9.011 0 0 1 9-9zM2 49v-2.048a82.038 82.038 0 0 1 6-6.313V45a7.005 7.005 0 0 0 3.63 6.132 60.612 60.612 0 0 0-4.81 5.831A9 9 0 0 1 2 49zm6.755 8.7a59.8 59.8 0 0 1 4.92-5.835c.436.087.88.132 1.325.135h13.679a51.54 51.54 0 0 0-5.294 6H11a8.943 8.943 0 0 1-2.245-.295zM49 58h-3.666c2.2-1.608 4.539-3.185 6.8-4.686l.866-.575a858.867 858.867 0 0 1 4.975-3.262A9 9 0 0 1 49 58z"
        ></path>
      </g>
    </svg>
  );
};
