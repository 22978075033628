import * as THREE from 'three';
import { KTX2_LOADER } from '../../../../../helpers/constans';
import { Load } from '../constructor/Load';
import imag11 from '../../../../../assets/img/wallTexture/t_1.ktx2';
import imag12 from '../../../../../assets/img/wallTexture/t_2.ktx2';
import imag13 from '../../../../../assets/img/wallTexture/t_3.ktx2';
import imag14 from '../../../../../assets/img/wallTexture/t_4.ktx2';
import imag15 from '../../../../../assets/img/wallTexture/t_5.ktx2';
import imag16 from '../../../../../assets/img/wallTexture/t_6.ktx2';
const Images = [imag11, imag12, imag13, imag14, imag15, imag16];

export const TEXTURES_KTX2: THREE.Texture[] = [];
export async function LoadTexturesKTX(): Promise<void> {
  // toktx --2d --lower_left_maps_to_s0t0 --assign_oetf linear --target_type RGBA --t2 --encode etc1s --clevel 5 --qlevel 255 texture_1.ktx2 texture_1.jpg
  try {
    KTX2_LOADER.detectSupport(Load.renderer);
    // const path = new URL('./rew.ktx2', import.meta.url);
    for (let i = 0; i < Images.length; i++) {
      const texture = await KTX2_LOADER.loadAsync(Images[i]);
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      TEXTURES_KTX2.push(texture);
    }
  } catch (err) {
    console.log('[ERROR]:', err);
  }
}
