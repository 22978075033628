/* eslint-disable no-var */
import * as THREE from 'three';
import { EDIT_CLASS_OBJECT } from '../../../../helpers/constans';
import { SelectedType } from '../types';

export default class BasicObject extends THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial> {
  constructor() {
    super();
  }
  buildGeometry() {
    if (this.geometry) {
      this.geometry.dispose();
    }
  }
  selectUnselectSelfObj() {
    /**/
  }
  removeSelf() {
    EDIT_CLASS_OBJECT.removeObjectsIntersected(this.uuid);
    if (this.children.length > 0) {
      while (this.children.length > 0) {
        const ch = this.children[0];
        BasicObject.removeMesh(ch as SelectedType);
        this.remove(ch);
      }
    }
    BasicObject.removeMesh(this);
    if (this.parent) {
      this.parent.remove(this);
    }
  }
  static removeMesh(
    mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial | THREE.MeshBasicMaterial[]>
  ) {
    if (mesh.geometry) {
      mesh.geometry.dispose();
    }
    if (mesh.material instanceof Array) {
      mesh.material.map(mat => {
        var map = mat.map;
        if (map) {
          map.dispose();
          map = null;
        }
      });
      mesh.material.length = 0;
    } //
    else {
      if (mesh.material && mesh.material?.map) {
        mesh.material.map.dispose();
        mesh.material.map = null;
        mesh.material.dispose();
      }
    }
  }
}
