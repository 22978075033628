import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../Box';
import { theme } from '../../../themes/theme';
import sofaImg from '../../../assets/img/partners/B2C.png';
import { StartTextComponent } from './components/StartTextComponent';
import { InformComponent } from './components/InformComponent';
import { TEXTS_INFO_BLOCKS, TEXTS_TITLE_BLOCKS } from './helpers/constans';
import { useDefineScrollForAnchor } from '../../../hooks/useDefineScrollForAnchor';

const WrapperBox = styled(Box)`
  position: relative;
  margin-bottom: 20px;
`;
const RightImage = styled.div`
  position: absolute;
  left: 44%;
  top: 0;
  width: 1070px;
  height: 801px;
  background-image: url('${sofaImg}');
  z-index: 2;
`;
const LeftImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 1070px;
  height: 801px;
  background-image: url('${sofaImg}');
  transform: scale(-1, 1);
`;
const WrapperLeft = styled.div`
  position: absolute;
  width: 50%;
  right: 60%;
  bottom: 0;
  height: 440px;
  overflow-y: hidden;
  opacity: 0.1;
`;

export const BlockB2C: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  useDefineScrollForAnchor({
    childRef: ref,
    orderNumber: 1,
  });
  const [isInformBlock, setIsInformBlock] = useState(false);
  return (
    <WrapperBox ref={ref} width="100%" height="630px" background={theme.colors.lightGray}>
      <WrapperLeft>
        <LeftImage />
      </WrapperLeft>
      <StartTextComponent
        isShow={isInformBlock}
        changeState={() => setIsInformBlock(true)}
        title="b2c"
        icon="partnerHome"
        text={TEXTS_TITLE_BLOCKS.b2c}
      />
      <InformComponent
        isShow={isInformBlock}
        changeState={() => setIsInformBlock(false)}
        texts={TEXTS_INFO_BLOCKS.b2c}
      />
      <RightImage />
    </WrapperBox>
  );
};
