/* eslint-disable no-var */
import * as THREE from 'three';
import { Wall } from './Wall';
import {
  DOOR_HEIGHT,
  DOOR_WIDTH,
  EDIT_CLASS_OBJECT,
  KTX2_LOADER,
} from '../../../../helpers/constans';
import { setIsLoading } from '../../../../api/store/functionsWitingCache';

interface PropsConstructor {
  wall: Wall;
  textureUrl: string;
  textureWidth: number;
  textureHeight: number;
}
interface PropsUpdateUV {
  geometry: THREE.BufferGeometry;
  start: THREE.Vector3;
  x: number;
  y: number;
  cursor: number;
  width: number;
  height: number;
}
interface PropsAddPanel {
  start: THREE.Vector3;
  position: THREE.Vector3;
  height: number;
  width: number;
  heightTexture: number;
  widthTexture: number;
  offX: number;
  offY: number;
}
export class PanelsGroup extends THREE.Group {
  wall: Wall;
  materialPanels: THREE.MeshStandardMaterial;
  materialDoor: THREE.MeshBasicMaterial;
  textureWidth: number;
  textureHeight: number;
  countPanels = 0;
  readonly offsetPanel = 1;
  readonly gap = 0.002;
  readonly minScaleTexture = 0.6;
  readonly maxScaleTexture = 1;

  private startOffset = new THREE.Vector2();
  private limitOffset = new THREE.Vector2();
  private scaleTexture = 1;
  constructor({ wall, textureUrl, textureWidth, textureHeight }: PropsConstructor) {
    super();
    this.name = 'panels';
    this.wall = wall;
    this.textureWidth = textureWidth;
    this.textureHeight = textureHeight;
    wall.panels = this;
    this.materialPanels = new THREE.MeshStandardMaterial({
      color: '#cbcbcb',
      roughness: 0.3,
      metalness: 0.2,
      transparent: true,
    });
    this.materialDoor = new THREE.MeshBasicMaterial({
      color: '#ffffff',
      transparent: true,
      opacity: 0,
    });
    setIsLoading({
      dataWrite: {
        isLoading: true,
      },
    });
    // KTX2_LOADER.detectSupport(LOAD_CLASS_OBJECT.renderer);
    KTX2_LOADER.loadAsync(textureUrl).then(texture => {
      texture.minFilter = THREE.LinearFilter;
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

      this.materialPanels.map = texture;
      this.buildPanelsObject();
    });
  }
  buildPanelsObject() {
    var startPosition = this.wall.getParamsWall();
    var newPosition = startPosition.clone();
    var distanceY = 0;
    var distanceX = 0;

    this.addHoleDoorsPanels();
    var { width: widthTexture, height: heightTexture } = this.getSizeTexture(1);
    this.limitOffset.x = Math.abs(widthTexture - this.wall.width) / widthTexture;
    this.limitOffset.y = Math.abs(heightTexture - this.wall.height) / heightTexture;

    while (distanceX <= this.wall.width) {
      while (distanceY <= this.wall.height) {
        var remainderDistanceY = this.wall.height - distanceY;
        var remainderDistanceX = this.wall.width - distanceX;
        var h = this.offsetPanel;
        var w = this.offsetPanel;
        if (remainderDistanceY < this.offsetPanel) {
          h = remainderDistanceY;
        }
        if (remainderDistanceX < this.offsetPanel) {
          w = remainderDistanceX;
        }
        if (w > 0.05 && h > 0.05) {
          this.addPanel({
            start: new THREE.Vector3(),
            position: newPosition,
            width: w,
            height: h,
            widthTexture,
            heightTexture,
            offX: distanceX,
            offY: distanceY,
          });
        }

        newPosition.add(new THREE.Vector3(0, this.offsetPanel, 0));
        distanceY += this.offsetPanel;
      }
      distanceX += this.offsetPanel;
      newPosition.copy(startPosition.clone().add(new THREE.Vector3(distanceX, 0, 0)));
      distanceY = 0;
    }

    setIsLoading({
      dataWrite: {
        isLoading: false,
      },
    });
  }
  addPanel(props: PropsAddPanel) {
    var { width, height, heightTexture, widthTexture, position, start, offX, offY } = props;
    var geometry = EDIT_CLASS_OBJECT.getPanelGeometry().clone();
    geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(new THREE.Vector3(0.5, 0.5, 0)));

    var positions = geometry.attributes['position'].array;
    var offsetY = 1 - height;
    var offsetX = 1 - width;
    var length = positions.length / 3;
    for (let i = 0; i < length; i++) {
      var x = positions[i * 3];
      var y = positions[i * 3 + 1];
      var z = positions[i * 3 + 2];

      if (height < 1 && y > 0.5) {
        y -= offsetY;
      }
      if (width < 1 && x > 0.5) {
        x -= offsetX;
      }

      geometry.attributes.position.setXYZ(i, x, y, z);
      this.updateUV({
        geometry,
        start,
        x: x + offX,
        y: y + offY,
        cursor: i,
        width: widthTexture,
        height: heightTexture,
      });
    }
    geometry.attributes.position.needsUpdate = true;
    geometry.attributes.uv.needsUpdate = true;

    var panel = new THREE.Mesh(geometry, this.materialPanels);
    this.countPanels += 1;
    this.add(panel);
    // panel.renderOrder = 1000;
    panel.position.copy(position);
  }
  moveTextureStart() {
    if (this.materialPanels.map?.offset) {
      this.startOffset.copy(this.materialPanels.map.offset);
    }
  }
  moveTexture(value: number, cord: 'x' | 'y'): void {
    if (this.materialPanels.map?.offset) {
      var offset = this.startOffset[cord] + value;
      if (offset >= 0 && offset <= this.limitOffset[cord]) {
        this.materialPanels.map.offset[cord] = offset;
      }
    }
  }
  scalingTexture(value: number): void {
    if (this.materialPanels.map?.repeat) {
      var offset = this.scaleTexture - value;
      if (offset >= this.minScaleTexture && offset <= this.maxScaleTexture) {
        this.materialPanels.map.repeat.set(offset, offset);

        var { width, height } = this.getSizeTexture(1 / offset);
        this.limitOffset.x = Math.abs(width - this.wall.width) / width;
        this.limitOffset.y = Math.abs(height - this.wall.height) / height;
        if (this.materialPanels.map?.offset) {
          var { x, y } = this.materialPanels.map.offset;
          if (y > this.limitOffset.y) {
            this.materialPanels.map.offset.y = this.limitOffset.y;
          }
          if (x > this.limitOffset.x) {
            this.materialPanels.map.offset.x = this.limitOffset.x;
          }
        }
      }
    }
  }
  scalingTextureEnd(value: number) {
    var offset = this.scaleTexture - value;
    if (offset >= this.minScaleTexture && offset <= this.maxScaleTexture) {
      this.scaleTexture = this.scaleTexture - value;
    } else {
      var mid = (this.maxScaleTexture - this.minScaleTexture) / 2 + this.minScaleTexture;
      if (offset <= mid) {
        this.scaleTexture = this.minScaleTexture;
      } else {
        this.scaleTexture = this.maxScaleTexture;
      }
    }
    /*var { width, height } = this.getSizeTexture(1 / this.scaleTexture);
    this.limitOffset.x = Math.abs(width - this.wall.width) / width;
    this.limitOffset.y = Math.abs(height - this.wall.height) / height;
    if (this.materialPanels.map?.offset) {
      var _off = this.materialPanels.map.offset;
      if (_off.y > this.limitOffset.y) {
        console.log('pop');
        this.materialPanels.map.offset.y = this.limitOffset.y;
      }
    }*/
    // console.log(width, height, this.limitOffset)
  }
  changeTexturePanels(textureUrl: string, textureWidth: number, textureHeight: number) {
    this.textureWidth = textureWidth;
    this.textureHeight = textureHeight;
    this.scaleTexture = 1;
    if (this.materialPanels.map) {
      this.materialPanels.map.offset.set(0, 0);
      this.startOffset = new THREE.Vector2();
      this.materialPanels.map.dispose();
      KTX2_LOADER.loadAsync(textureUrl).then(texture => {
        texture.minFilter = THREE.LinearFilter;
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

        this.materialPanels.map = texture;
        this.materialPanels.needsUpdate = true;

        while (this.children.length) {
          var child = this.children[0] as THREE.Mesh;
          child.geometry.dispose();
          this.remove(child);
        }
        this.buildPanelsObject();
      });
    }
  }
  removePanelsGroup() {
    while (this.children.length) {
      var child = this.children[0] as THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>;
      child.geometry.dispose();
      child.material.map?.dispose();
      child.material.map = null;
      child.material.dispose();
      this.remove(child);
    }
    this.parent?.remove(this);
  }
  private addHoleDoorsPanels() {
    this.wall.doors.forEach(door => {
      var geometry = new THREE.PlaneGeometry(DOOR_WIDTH, DOOR_HEIGHT);
      var doorPanel = new THREE.Mesh(geometry, this.materialDoor);
      doorPanel.position.copy(door.position);
      doorPanel.position.z += 0.02;
      door.holeDoorsPanels = doorPanel;
      this.add(doorPanel);
    });
  }
  private updateUV(props: PropsUpdateUV) {
    const { x, start, y, geometry, cursor, width, height } = props;
    var uvAttribute = geometry.attributes.uv;
    var vCurrX = new THREE.Vector3(x, start.y, start.z);
    var vCurrY = new THREE.Vector3(start.x, y, start.z);
    var coifU = start.distanceTo(vCurrX) / width;
    var coifV = start.distanceTo(vCurrY) / height;
    uvAttribute.setXY(cursor, coifU, coifV);
  }
  private getSizeTexture(scale: number): { width: number; height: number } {
    var max = Math.max(this.textureWidth, this.textureHeight);
    var dec = max.toString().length - 1;
    var width = this.textureWidth / 10 ** dec;
    var height = this.textureHeight / 10 ** dec;
    var coif = Math.max(this.wall.width / width, this.wall.height / height);
    width *= coif;
    height *= coif;
    width *= scale;
    height *= scale;
    return { width, height };
  }
}
