import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  isBorder?: boolean;
  padding?: CSSProperties['padding'];
}
const BoxGapStyle = styled.div.withConfig({
  shouldForwardProp: prop => !['isBorder'].includes(prop),
})<Props>`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  pointer-events: none;
  padding: ${({ padding }) => padding || '0'};
  ${prop =>
    prop?.isBorder &&
    css<Props>`
      border-bottom: solid 1px ${({ theme }) => theme.colors.softBlueViolet};
    `};
`;
export const BoxGap: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return <BoxGapStyle {...props}>{children}</BoxGapStyle>;
};
